import { Component, OnInit } from '@angular/core'
import { AuthService } from '../../../services/auth.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-empty-dashboard',
  templateUrl: './empty-dashboard.component.html',
})
export class EmptyDashboardComponent implements OnInit {
  constructor(public authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    const user = this.authService.getUser()

    // Die Startseite für Herrn Temi soll bei /start bleiben.
    if (user && user.id !== 2) {
      this.router.navigate(['/'])

      return
    }
  }
}
