import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class PatientPriceService {
  constructor(private http: HttpClient) {}

  public store(patientId: any, data: {}): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/patient-price/${patientId}`, {
      ...data,
    })
  }

  public getHistory(patientId: any): Observable<[]> {
    return this.http.get<[]>(
      `${environment.api}/patient-price/${patientId}/history`
    )
  }

  // public update(id: any, comment: string): Observable<[]> {
  //   return this.http.put<[]>(`${environment.api}/approve-planning/${id}`, {
  //     comment,
  //   })
  // }
}
