import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { DialogService } from 'primeng/dynamicdialog'
import { DiffViewDialogComponent } from '../../../../components/dialogs/diff-view-dialog/diff-view-dialog.component'
import { HistoryListModel } from '../../../../models/history/history-list.model'
import { CarService } from '../../../../services/car.service'
import { HistoryManualDialogComponent } from '../../../../components/dialogs/history-manual-dialog/history-manual-dialog.component'
import { Subscription } from 'rxjs'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../services/eventbus.service'
import { TodoModel } from '../../../../models/todo/todo.model'
import { ShowTodoDialogComponent } from '../../../../components/dialogs/show-todo-dialog/show-todo-dialog.component'

@Component({
  selector: 'app-cars-detail-history',
  templateUrl: './cars-detail-history.component.html',
  providers: [DialogService],
})
export class CarsDetailHistoryComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null

  public loading = true
  public histories: HistoryListModel[] = []
  public carId: string | null = null
  public onlyImportant = false
  public globalFilterValue = ''

  private routeSubscription: any = null
  private eventBusSubscription: Subscription = new Subscription()

  constructor(
    private carService: CarService,
    private route: ActivatedRoute,
    private eventbus: EventBusService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.loadHistory()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.routeSubscription && this.routeSubscription.unsubscribe()
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public openDiffView(id: number): void {
    this.dialogService.open(DiffViewDialogComponent, {
      data: {
        id,
      },
      header: 'Änderungen ansehen',
      styleClass: 'dialog-diff-view',
      dismissableMask: true,
    })
  }

  /**
   * Hier reagieren wir auf Events im EventBusService, wie z.B. das Event,
   * dass die PhoneCall-Liste die Daten neu laden soll (wenn z.B. ein Telefonat
   * gelöscht oder bearbeitet wurde).
   */
  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.PhoneCallListReload:
          case GlobalEvent.HistoryListReload:
            this.loadHistory(false)
        }
      }
    )
  }

  public openShowTodoDialog(todo: TodoModel): void {
    this.dialogService.open(ShowTodoDialogComponent, {
      header: `Todo ansehen #${todo?.id}`,
      width: '820px',
      styleClass: 'dialog-container',
      data: {
        isEdit: true,
        todo,
        todo_id: todo.id,
        user_type_name: todo.car?.full_name,
      },
    })
  }

  public openManualHistoryDialog(
    edit: boolean,
    history?: HistoryListModel
  ): void {
    this.dialogService.open(HistoryManualDialogComponent, {
      data: {
        edit,
        history: history || new HistoryListModel(),
        type: 'car',
        type_id: this.carId,
      },
      header: history ? 'Eintrag bearbeiten' : 'Neuer Eintrag',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '520px',
    })
  }

  public loadHistory(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    if (this.route.parent) {
      this.routeSubscription = this.route.parent.paramMap.subscribe(
        (params: ParamMap) => {
          this.carId = params.get('id')

          this.carService
            .history(this.carId)
            .subscribe((data: HistoryListModel[]) => {
              this.histories = data

              if (withLoading) {
                this.loading = false
              }
            })
        }
      )
    }
  }
}
