<form #form="ngForm" class="dialog-form">
  <div
    class="dialog-sidebar secondary-dialog-sidebar middle-dialog-sidebar"
    *ngIf="values.status === 'PAUSED'"
  >
    <span class="content-sub-headline">Vorhandene Einsätze</span>

    <small class="color-gray" *ngIf="!values.pause_from_string"
      >Bitte Startdatum auswählen</small
    >

    <small class="color-gray" *ngIf="!values.pause_to_string"
      >Bitte Enddatum auswählen</small
    >

    <small
      class="color-gray"
      *ngIf="
        values.pause_from_string &&
        values.pause_to_string &&
        appointments.length === 0
      "
      >Keine Einsätze vorhanden</small
    >

    <p-table
      *ngIf="appointments.length > 0"
      #dt
      [value]="appointments"
      [rowHover]="true"
      [autoLayout]="true"
      [globalFilterFields]="[]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 150px" pSortableColumn="caregiver.last_name">
            Betreuungskraft
            <p-sortIcon field="caregiver.last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="real_from">
            Datum <p-sortIcon field="real_from"></p-sortIcon>
          </th>
          <th>Uhrzeit</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-appointment>
        <tr>
          <td>
            <a
              class="color-main1"
              target="_blank"
              href="/caregivers/{{ appointment.caregiver.id }}/planning"
            >
              {{ appointment.caregiver.last_name }},
              {{ appointment.caregiver.first_name }}
            </a>
          </td>
          <td>
            {{ appointment.real_from | date: "dd.MM.y" }} ({{
              appointment.weekday
            }})
          </td>
          <td>{{ appointment.real_from_h }} - {{ appointment.real_to_h }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Pausieren"
              name="type"
              [(ngModel)]="values.status"
              value="PAUSED"
              #status="ngModel"
              required
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Reaktivieren"
              name="type"
              [(ngModel)]="values.status"
              value="PAUSE_REACTIVATED"
              #status="ngModel"
              required
            ></p-radioButton>
          </div>
        </div>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border" *ngIf="!isEdit">
      <div class="p-field p-col-12">
        <label for="patients">Patient *</label>
        <p-multiSelect
          (onChange)="loadPatientsAppointments()"
          inputId="patients"
          [options]="patientOptions"
          optionLabel="full_name"
          [filter]="false"
          [showHeader]="false"
          name="patients"
          [(ngModel)]="values.patients"
          [required]="true"
          #patients="ngModel"
          [class.p-invalid]="
            patients.invalid && (patients.dirty || patients.touched)
          "
        ></p-multiSelect>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid without-border" *ngIf="isEdit">
      <div class="p-field p-col-12">
        <label>Patient</label>
        <span>{{ config.data.patient.full_name }}</span>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.status === 'PAUSED'"
    >
      <div class="p-field p-col-6">
        <label for="from">Pause von *</label>
        <p-calendar
          (onSelect)="loadPatientsAppointments()"
          (onBlur)="loadPatientsAppointments()"
          [firstDayOfWeek]="1"
          [showWeek]="true"
          [showIcon]="true"
          inputId="from"
          name="from"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.pause_from_string"
          dataType="string"
          [required]="true"
          #from="ngModel"
          [showButtonBar]="true"
          [class.p-invalid]="from.invalid && (from.dirty || from.touched)"
        ></p-calendar>
      </div>

      <div class="p-field p-col-6">
        <label for="to">Pause bis *</label>
        <p-calendar
          (onSelect)="loadPatientsAppointments()"
          (onBlur)="loadPatientsAppointments()"
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="to"
          [showIcon]="true"
          name="to"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.pause_to_string"
          dataType="string"
          [required]="true"
          #to="ngModel"
          [showButtonBar]="true"
          [class.p-invalid]="to.invalid && (to.dirty || to.touched)"
        ></p-calendar>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label for="comment">Bemerkung *</label>
        <textarea
          id="comment"
          name="comment"
          [(ngModel)]="values.comment"
          [rows]="3"
          [required]="true"
          #comment="ngModel"
          [class.p-invalid]="
            comment.invalid && (comment.dirty || comment.touched)
          "
          pInputTextarea
        ></textarea>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i></i>
    <button
      *ngIf="!isEdit"
      pButton
      label="Status ändern"
      type="submit"
      (click)="save()"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>

    <button
      *ngIf="isEdit"
      pButton
      label="Speichern"
      type="submit"
      (click)="saveEdit()"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
