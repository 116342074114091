import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { TitleService } from 'src/app/services/title.service'
import { PhoneCallService } from '../../services/phone-call.service'
import { HelperService } from '../../services/helper.service'
import { EventBusService, GlobalEvent } from '../../services/eventbus.service'
import { DialogService } from 'primeng/dynamicdialog'
import { Subject, Subscription } from 'rxjs'
import { ProofsService } from '../../services/proofs.service'
import { GenerateProofCoverDialogComponent } from '../../components/dialogs/generate-proof-cover-dialog/generate-proof-cover-dialog.component'
import { CaregiverDependenciesModel } from '../../models/caregiver/caregiver-dependencies.model'
import { CaregiverService } from '../../services/caregivers.service'
import { UploadProofsDialogComponent } from '../../components/dialogs/upload-proofs-dialog/upload-proofs-dialog.component'
import { ProofCheckDialogComponent } from '../../components/dialogs/proof-check-dialog/proof-check-dialog.component'
import { UploadProofsHistoryDialogComponent } from '../../components/dialogs/upload-proofs-history-dialog/upload-proofs-history-dialog.component'
import { switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-proofs',
  templateUrl: './proofs.component.html',
})
export class ProofsComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null

  public loading = false
  public contentLoading = true
  public globalFilterValue = ''
  public caregivers_active: any[] = []
  public caregiversActiveBackup: any[] = []
  public dependencies: CaregiverDependenciesModel = new CaregiverDependenciesModel()

  public historyLoaded = false
  public showHistory = false
  public histories: any = []

  public amountHours = 0
  public amountHoursFormatted = ''
  public data: any[] = []

  public month = ''
  public year = ''

  public plannerUsers: any[] = []
  public selectedPlanner: any = null
  public currentCaregiverId: any = null
  public selectedCities: any[] = []
  public unreleased = false
  public reworking = false
  public onlyGv = false
  public onlyInvoiceDraft = false
  public noProofs = false
  public multipleProofs = false

  private selectedYear = 0
  private selectedMonth = 0
  private eventBusSubscription: Subscription = new Subscription()

  public monthOptions: any = []
  public yearOptions = [
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
    { label: '2025', value: '2025' },
  ]

  private loadTrigger$ = new Subject<void>()
  private subscription: Subscription = new Subscription()

  constructor(
    public phoneCallService: PhoneCallService,
    private dialogService: DialogService,
    private eventbus: EventBusService,
    private caregiverService: CaregiverService,
    private proofsService: ProofsService,
    private helperService: HelperService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Leistungsnachweise')

    this.activateDataLoading()

    const date = new Date()
    this.selectedYear = date.getFullYear()
    this.selectedMonth = date.getMonth()

    if (this.selectedMonth === 0) {
      this.selectedYear--
      this.selectedMonth = 11
    } else {
      this.selectedMonth--
    }

    this.helperService.dependencies$.subscribe((data: any) => {
      this.monthOptions = data.months
      this.month = this.monthOptions[this.selectedMonth].label
      this.year = this.selectedYear.toString()

      this.caregiversActiveBackup = data['caregivers_active']
      this.caregivers_active = data['caregivers_active']

      for (const planner of data.planner_users) {
        this.plannerUsers.push({
          id: planner.id,
          full_name: `${planner.user_system.first_name} ${planner.user_system.last_name}`,
        })
      }
    })

    this.loadDependencies()
    this.loadData()
    this.listenForEventbus()
  }

  public loadHistory(): void {
    this.historyLoaded = false
    this.showHistory = true

    this.proofsService.loadHistory().subscribe((histories: []) => {
      this.histories = histories

      this.historyLoaded = true
    })
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.subscription.unsubscribe()
  }

  private loadDependencies(): void {
    this.caregiverService
      .dependencies()
      .subscribe((dependencies: CaregiverDependenciesModel) => {
        this.dependencies = dependencies
      })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.ProofsChanged:
            this.loadData(false)
        }
      }
    )
  }

  public openGenerateProofCoverDialog(): void {
    this.dialogService.open(GenerateProofCoverDialogComponent, {
      header: 'Deckblätter erstellen',
      width: '420px',
      dismissableMask: true,
      styleClass: 'dialog-container',
      data: {
        year: this.year,
        month: this.month,
      },
    })
  }

  public openProofHistoryDialog(history: any): void {
    this.dialogService.open(UploadProofsHistoryDialogComponent, {
      header: 'History',
      width: '680px',
      styleClass: 'dialog-container',
      data: {
        ...history,
        year: this.year,
        month: this.month,
      },
    })
  }

  public openProofCheckDialog(data: any): void {
    this.dialogService.open(ProofCheckDialogComponent, {
      header: 'Leistungsnachweis',
      width: '96%',
      height: '90%',
      styleClass: 'dialog-container',
      data: {
        ...data,
      },
    })
  }

  public openUploadProofsDialog(): void {
    this.dialogService.open(UploadProofsDialogComponent, {
      header: 'Leistungsnachweise hochladen',
      width: '680px',
      closeOnEscape: false,
      styleClass: 'dialog-container',
      data: {
        year: this.year,
        month: this.month,
      },
    })
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  /**
   * Springt zum letzten Monat.
   */
  public goToPreviousMonth(): void {
    if (this.selectedMonth === 0) {
      const lastYear = this.selectedYear - 1

      const hasYearOption = this.yearOptions.find(
        (data: any) => data.value == lastYear
      )

      if (hasYearOption) {
        this.selectedYear = lastYear
        this.selectedMonth = 11
      }
    } else {
      this.selectedMonth--
    }

    this.year = this.selectedYear.toString()
    this.month = this.monthOptions[this.selectedMonth].label

    this.loadData(false)
  }

  /**
   * Springt zum nächsten Monat.
   */
  public goToNextMonth(): void {
    if (this.selectedMonth === 11) {
      const nextYear = this.selectedYear + 1

      const hasYearOption = this.yearOptions.find(
        (data: any) => data.value == nextYear
      )

      if (hasYearOption) {
        this.selectedYear = nextYear
        this.selectedMonth = 0
      }
    } else {
      this.selectedMonth++
    }

    this.year = this.selectedYear.toString()
    this.month = this.monthOptions[this.selectedMonth].label

    this.loadData(false)
  }

  public filterChanged(type: string): void {
    if (type === 'noProofs' && this.noProofs) {
      this.multipleProofs = false
    } else if (type === 'multipleProofs' && this.multipleProofs) {
      this.noProofs = false
    }

    this.loadData(false)
  }

  public loadData(withLoading: boolean = true): void {
    const foundMonth = this.monthOptions.findIndex((month: any) => {
      return month.label === this.month
    })

    if (foundMonth != null) {
      this.selectedMonth = foundMonth
    }

    this.selectedYear = +this.year

    this.contentLoading = true

    if (withLoading) {
      this.loading = true
    }

    this.updateActiveCaregivers()

    this.loadTrigger$.next()
  }

  private getSelectedCities(): any[] {
    let selectedCities = []
    if (this.selectedCities) {
      selectedCities = this.selectedCities
        .filter((city: any) => city.type === 'CITY')
        .map((city: any) => city.id)
    }

    return selectedCities
  }

  private updateActiveCaregivers(): void {
    if (this.selectedPlanner) {
      this.caregivers_active = this.caregiversActiveBackup.filter(
        (caregiver: any) => caregiver.planner_id == this.selectedPlanner
      )
    } else {
      this.caregivers_active = this.caregiversActiveBackup
    }
  }

  private activateDataLoading(): void {
    this.subscription.add(
      this.loadTrigger$
        .pipe(
          switchMap(() =>
            this.proofsService.load(
              this.month,
              this.year,
              this.selectedPlanner,
              this.unreleased,
              this.noProofs,
              this.currentCaregiverId,
              this.getSelectedCities(),
              this.reworking,
              this.multipleProofs,
              this.onlyGv,
              this.onlyInvoiceDraft
            )
          )
        )
        .subscribe((data: any) => {
          this.amountHours = data.amount_hours
          this.amountHoursFormatted = data.amount_hours_formatted
          this.data = data.items
          this.dependencies.counties = data.counties

          this.contentLoading = false
          this.loading = false
        })
    )
  }
}
