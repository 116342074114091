import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { CarService } from '../../../../services/car.service'
import { AddDamageDialogComponent } from '../../../../components/dialogs/add-damage-dialog/add-damage-dialog.component'
import { CarDamageModel } from '../../../../models/car/car-damage.model'
import { ShowDamageDialogComponent } from '../../../../components/dialogs/show-damage-dialog/show-damage-dialog.component'
import { CarDamageService } from '../../../../services/car-damage.service'

@Component({
  selector: 'app-cars-detail-damage',
  templateUrl: './cars-detail-damage.component.html',
  providers: [DialogService],
})
export class CarsDetailDamageComponent implements OnInit, OnDestroy {
  public loading = true
  public damages: CarDamageModel[] = []
  public carId: string | null = null

  private routeSubscription: any = null
  private eventBusSubscription: Subscription = new Subscription()

  constructor(
    private carDamageService: CarDamageService,
    private route: ActivatedRoute,
    private eventbus: EventBusService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    if (this.route.parent) {
      this.routeSubscription = this.route.parent.paramMap.subscribe(
        (params: ParamMap) => {
          this.carId = params.get('id')

          this.loadDamage()
        }
      )
    }

    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.routeSubscription && this.routeSubscription.unsubscribe()
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.DamageChanged:
            this.loadDamage(false)
        }
      }
    )
  }

  public openDamageDialog(damage: CarDamageModel | null = null): void {
    this.dialogService.open(AddDamageDialogComponent, {
      header: damage ? 'Schaden bearbeiten' : 'Neuer Schaden',
      width: '450px',
      dismissableMask: true,
      styleClass: 'dialog-container',
      data: {
        isEdit: damage !== null,
        damage_id: damage?.id,
        car_id: this.carId,
      },
    })
  }

  public openShowDamageDialog(damage: CarDamageModel | null = null): void {
    this.dialogService.open(ShowDamageDialogComponent, {
      header: 'Schaden ansehen',
      width: '620px',
      dismissableMask: true,
      styleClass: 'dialog-container',
      data: {
        damage_id: damage?.id,
      },
    })
  }

  public loadDamage(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.carDamageService
      .list(this.carId)
      .subscribe((data: CarDamageModel[]) => {
        this.damages = data

        if (withLoading) {
          this.loading = false
        }
      })
  }
}
