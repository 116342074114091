import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { CarService } from '../../../services/car.service'
import { CarsDetailModel } from '../../../models/car/cars-detail.model'
import { TitleService } from 'src/app/services/title.service'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { NoticeDialogComponent } from '../../../components/dialogs/notice-dialog/notice-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import { HelperService } from '../../../services/helper.service'
import { AuthService } from '../../../services/auth.service'

@Component({
  selector: 'app-cars-detail',
  templateUrl: './cars-detail.component.html',
  styleUrls: ['./cars-detail.component.scss'],
})
export class CarsDetailComponent implements OnInit, OnDestroy {
  public currentCarId: number | null = null

  public cars: any[] = []

  public car: CarsDetailModel = new CarsDetailModel()
  private eventBusSubscription: Subscription = new Subscription()

  private keyListener: any = null

  constructor(
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router,
    private eventbus: EventBusService,
    private dialogService: DialogService,
    private carService: CarService,
    public authService: AuthService,
    private titleService: TitleService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.loadCarDetails()
    this.listenForEventbus()

    this.helperService.dependencies$.subscribe((data: any) => {
      this.cars = data['cars']
    })

    this.keyListener = this.renderer.listen('window', 'keydown', (event) => {
      if (event.key === 'ArrowRight' && event.altKey) {
        event.preventDefault()
        this.goToNextCar('next')
      }

      if (event.key === 'ArrowLeft' && event.altKey) {
        event.preventDefault()
        this.goToNextCar('previous')
      }
    })
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()

    // Unsubscribe vom Key Listener.
    this.keyListener()
  }

  public loadCarDetails(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const id = params.get('id')

      this.carService.details(id).subscribe((data: CarsDetailModel) => {
        this.currentCarId = id ? +id : null

        this.car = data
        this.titleService.setTitle(data.license_plate)
      })
    })
  }

  public navigateToCar(event: any): void {
    if (event.value) {
      this.router.navigateByUrl(this.getNextCarUrl(this.car.id, event.value))
    }
  }

  /**
   * Suchen uns den nächsten PKW in der Liste zu der wir springen wollen.
   */
  public goToNextCar(type: 'next' | 'previous'): void {
    if (!this.cars) {
      return
    }

    const currentIndex = this.cars.findIndex(
      (car: any) => car.id === this.car.id
    )

    if (currentIndex !== null) {
      const nextIndex = type === 'next' ? currentIndex + 1 : currentIndex - 1
      let nextCar = null

      if (this.cars[nextIndex]) {
        nextCar = this.cars[nextIndex]
      } else {
        // Falls es den nächsten Index nicht gibt, schauen wir, ob wir den ersten oder letzten Wert holen müssen.
        nextCar =
          type === 'next' ? this.cars[0] : this.cars[this.cars.length - 1]
      }

      this.router.navigateByUrl(this.getNextCarUrl(this.car.id, nextCar.id))
    }
  }

  /**
   * Der Name des nächsten PKWs für den Tooltip.
   */
  public getNextCarName(type: 'next' | 'previous'): string {
    if (!this.cars) {
      return ''
    }

    const currentIndex = this.cars.findIndex(
      (caregiver: any) => caregiver.id === this.car.id
    )

    if (currentIndex !== null) {
      const nextIndex = type === 'next' ? currentIndex + 1 : currentIndex - 1
      let nextCar = null

      if (this.cars[nextIndex]) {
        nextCar = this.cars[nextIndex]
      } else {
        // Falls es den nächsten Index nicht gibt, schauen wir, ob wir den ersten oder letzten Wert holen müssen.
        nextCar =
          type === 'next' ? this.cars[0] : this.cars[this.cars.length - 1]
      }

      return nextCar.full_name
    }

    return ''
  }

  public openNoticeDialog(): void {
    this.dialogService.open(NoticeDialogComponent, {
      header: 'Notiz bearbeiten',
      width: '450px',
      dismissableMask: true,
      styleClass: 'dialog-container',
      data: {
        type: 'car',
        type_id: this.car.id,
        notice: this.car.notice,
      },
    })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe((event) => {
      switch (event) {
        case GlobalEvent.NoticeChanged:
          this.loadCarDetails()
          break
      }
    })
  }

  private getNextCarUrl(currentId: any, nextId: any): string {
    const currentUrl = this.router.url
    let newUrl = ''

    // Auf der Startseite der PKW Details ist kein "/" am Ende vorhanden.
    if (currentUrl === `/cars/${this.car.id}`) {
      newUrl = currentUrl.replace(`/${currentId}`, `/${nextId}`)
    } else {
      newUrl = currentUrl.replace(`/${currentId}/`, `/${nextId}/`)
    }

    return newUrl
  }
}
