import { TodosComponent } from '../views/todos/todos.component'
import { AuthGuard } from '../guards/auth.guard'
import { OpenTodosComponent } from '../views/todos/open-todos/open-todos.component'
import { FinishedTodosComponent } from '../views/todos/finished-todos/finished-todos.component'
import { OwnTodosComponent } from '../views/todos/own-todos/own-todos.component'
import { TodoNotificationsComponent } from '../views/todos/todo-notifications/todo-notifications.component'

export const todosRoutes = [
  {
    path: 'todos',
    component: TodosComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: OpenTodosComponent,
      },
      {
        path: 'finished',
        component: FinishedTodosComponent,
      },
      {
        path: 'own',
        component: OwnTodosComponent,
      },
      {
        path: 'notifications',
        component: TodoNotificationsComponent,
      },
    ],
  },
]
