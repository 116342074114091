<div class="content-actions">
  <i class="tio icon-large">help_outlined</i>

  <h2>
    <i class="tio">help_outlined</i>
    Neukunden Calls
  </h2>
</div>

<header class="content-header-tabs">
  <a
    routerLink="/patients"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Qualifizierung
  </a>
  <a
    routerLink="/patients/consultings"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Calls
  </a>
</header>

<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>Alle Neukunden Calls</span>
  </header>
</div>

<div class="content" *ngIf="!loading">
  <div class="content-body" *ngIf="calls.length == 0">
    <span class="content-no-message">Keine Neukunden Calls vorhanden</span>
  </div>

  <div class="content-body without-padding" *ngIf="calls.length > 0">
    <p-table
      #dt
      [value]="calls"
      [globalFilterFields]="['id']"
      [rowHover]="true"
      [paginator]="true"
      [autoLayout]="true"
      [rows]="50"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Calls"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="width: 60px"></th>
          <th pSortableColumn="user_system.last_name">
            Erstellt von <p-sortIcon field="user_system.last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="created_at">
            Erstellt am <p-sortIcon field="created_at"></p-sortIcon>
          </th>
          <th pSortableColumn="number">
            Nummer <p-sortIcon field="number"></p-sortIcon>
          </th>
          <th pSortableColumn="duration">
            Dauer <p-sortIcon field="duration"></p-sortIcon>
          </th>
          <th pSortableColumn="attention_from_id">
            Aufmerksam durch <p-sortIcon field="attention_from_id"></p-sortIcon>
          </th>
          <th style="max-width: 300px" pSortableColumn="comment">
            Kommentar <p-sortIcon field="comment"></p-sortIcon>
          </th>
          <th style="width: 50px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-call>
        <tr>
          <td style="width: 60px">
            <i
              *ngIf="call.strong_interest"
              class="pi pi-info-circle color-blue"
              style="font-size: 14px"
              pTooltip="Starkes Interesse"
            ></i>
          </td>
          <td>
            {{ call.user_system?.first_name }} {{ call.user_system?.last_name }}
          </td>
          <td>{{ call.created_at | date: "dd.MM.y, H:mm" }} Uhr</td>

          <td *ngIf="call.number">
            <a
              href="tel:{{ call.number }}"
              (click)="phoneCallService.createCalling(call.number)"
              >{{ call.number }}</a
            >
          </td>
          <td *ngIf="!call.number">Unbekannt</td>

          <td>{{ call.duration || "N/A" }}</td>
          <td>{{ call.attention_from?.name || "-" }}</td>
          <td style="max-width: 300px">{{ call.comment || "-" }}</td>
          <td>
            <i
              pTooltip="Bearbeiten"
              tooltipPosition="left"
              class="pi pi-pencil"
              (click)="openPhonecallEditModal(call)"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
