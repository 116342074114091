<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <!--  <header class="content-header without-border">-->
  <!--    <span>-->
  <!--      <i class="pi pi-user"></i>-->
  <!--      Betreuungskraft-->
  <!--    </span>-->
  <!--  </header>-->
  <div class="content-body without-padding p-flex-row">
    <div class="detail-information-column border-right">
      <div class="detail-information-container">
        <div class="detail-information-header">
          <span><i class="pi pi-user"></i>Daten</span>
        </div>
        <!--        <div class="detail-information-body-container">-->
        <div class="detail-information-body">
          <div
            class="detail-information-banner"
            *ngIf="
              caregiver.caregiver.caregivers_with_same_email &&
              caregiver.caregiver.caregivers_with_same_email.length > 0
            "
          >
            <span class="pi pi-exclamation-triangle"></span>
            <span>Diese E-Mail Adresse wird bereits verwendet</span>
            <span
              *ngFor="let c of caregiver.caregiver.caregivers_with_same_email"
            >
              <a target="_blank" routerLink="/caregivers/{{ c.id }}"
                >ansehen (#{{ c.id }})</a
              >
            </span>
          </div>

          <div
            class="detail-information-banner"
            *ngIf="
              caregiver.caregiver.current_vacation ||
              caregiver.caregiver.current_ill ||
              caregiver.caregiver.current_free_time_request
            "
          >
            <span *ngIf="caregiver.caregiver.current_vacation"
              >Urlaub von
              {{ caregiver.caregiver.current_vacation.from | date: "dd.MM.y" }}
              bis
              {{
                caregiver.caregiver.current_vacation.to | date: "dd.MM.y"
              }}</span
            >

            <span *ngIf="caregiver.caregiver.current_ill"
              >AU von
              {{ caregiver.caregiver.current_ill.from | date: "dd.MM.y" }}
              bis
              {{ caregiver.caregiver.current_ill.to | date: "dd.MM.y" }}</span
            >

            <span *ngIf="caregiver.caregiver.current_free_time_request"
              >Wunschfrei von
              {{
                caregiver.caregiver.current_free_time_request.from
                  | date: "dd.MM.y, HH:mm"
              }}
              bis
              {{
                caregiver.caregiver.current_free_time_request.to
                  | date: "dd.MM.y, HH:mm"
              }}</span
            >
          </div>

          <div class="detail-information" *ngIf="caregiver.caregiver.is_mentor">
            <div class="detail-key">
              <span>{{
                caregiver.caregiver.is_male ? "Mentor" : "Mentorin"
              }}</span>
            </div>
            <div class="detail-value p-jc-between p-align-center">
              <i class="pi pi-check color-main1"></i>
            </div>
          </div>

          <div
            class="detail-information"
            [class.background-hightlight-alert-no-opacity]="
              !caregiver.caregiver.current_status.hospitation_confirmed
            "
          >
            <div class="detail-key">
              <span>Status</span>
            </div>
            <div class="detail-value p-jc-between p-align-center">
              <div class="text-center-container">
                <strong>{{
                  caregiver.caregiver.current_status.status_text
                }}</strong>
                <i
                  (click)="
                    openHospitationFeedbackDialog(
                      caregiver.caregiver.current_status
                    )
                  "
                  *ngIf="
                    caregiver.caregiver.current_status
                      .caregiver_hospitation_feedback
                  "
                  class="p-tag p-tag-rounded"
                  ><i class="pi pi-star"></i>Feedback ansehen</i
                >
              </div>

              <div class="detail-value-actions">
                <i
                  class="pi color-gray"
                  [class.pi-chevron-down]="!openedDetails.status"
                  [class.pi-chevron-up]="openedDetails.status"
                  [pTooltip]="openedDetails.status ? 'Schließen' : 'Anzeigen'"
                  (click)="openedDetails.status = !openedDetails.status"
                ></i>
              </div>
            </div>
          </div>

          <div
            class="detail-information without-padding"
            *ngIf="openedDetails.status"
          >
            <p-table
              styleClass="highlight-header"
              [value]="caregiver.caregiver.status_values"
              [rowHover]="true"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th style="min-width: 120px">Status</th>
                  <th style="min-width: 120px">Seit</th>
                  <th>Text</th>
                  <th style="width: 50px"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-status>
                <tr>
                  <td>{{ status.status_text }}</td>

                  <td *ngIf="status.status !== 'INACTIVE'">
                    {{ status.created_at | date: "dd.MM.y" }}
                  </td>
                  <td *ngIf="status.status === 'INACTIVE'">
                    {{ status.inactive_since_string }}
                  </td>

                  <td *ngIf="status.status === 'CANCELED'">
                    {{ status.canceled_reason }}
                  </td>
                  <td *ngIf="status.status === 'INACTIVE'">
                    {{ status.inactive_reason }}
                  </td>
                  <td *ngIf="status.status === 'EDIT'">
                    {{ status.edit_info || "-" }}
                  </td>
                  <td *ngIf="status.status === 'HOSPITATION'">
                    <i
                      (click)="openHospitationFeedbackDialog(status)"
                      *ngIf="status.caregiver_hospitation_feedback"
                      class="p-tag p-tag-rounded"
                      ><i class="pi pi-star"></i>Feedback ansehen</i
                    >

                    <div *ngIf="status.hospitation_feedback">
                      {{ status.hospitation_feedback }}
                    </div>
                  </td>
                  <td *ngIf="status.status === 'EMPLOYMENT'">
                    {{ status.hospitation_feedback || "-" }}
                  </td>

                  <td *ngIf="status.status === 'HOSPITATION_STARTED'">-</td>
                  <td *ngIf="status.status === 'APPLICANT'">-</td>
                  <td *ngIf="status.status === 'JOB_INTERVIEW'"></td>

                  <td>
                    <i
                      *ngIf="
                        [
                          'HOSPITATION',
                          'HOSPITATION_STARTED',
                          'INACTIVE',
                          'EDIT',
                          'CANCELED'
                        ].includes(status.status) &&
                        authService.can(
                          'Betreuungskraft.Betreuungskraft Status ändern'
                        )
                      "
                      class="pi pi-pencil show-always"
                      (click)="openChangeStatusDialog(status)"
                    ></i>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>

          <div class="detail-information">
            <div
              class="detail-key"
              [class.alert-key]="caregiver.caregiver.important_contact_person"
              style="align-items: center"
            >
              <span>Notfallkontakt </span
              ><i
                class="pi pi-info-circle color-gray"
                style="margin: 0 0 0 5px; font-size: 14px"
                pTooltip="Ist ein Ansprechpartner"
              ></i>
            </div>
            <div
              class="detail-value"
              *ngIf="!caregiver.caregiver.important_contact_person"
            >
              <strong>-</strong>
            </div>
            <div
              class="detail-value"
              *ngIf="caregiver.caregiver.important_contact_person"
            >
              <strong
                >{{ caregiver.caregiver.important_contact_person.full_name }},
                {{ caregiver.caregiver.important_contact_person.relation }}
                <br />
                Tel 1:
                <span
                  *ngIf="!caregiver.caregiver.important_contact_person.phone_1"
                  >-</span
                >
                <a
                  (click)="
                    phoneCallService.createCalling(
                      caregiver.caregiver.important_contact_person.phone_1
                    )
                  "
                  *ngIf="caregiver.caregiver.important_contact_person.phone_1"
                  href="tel:{{
                    caregiver.caregiver.important_contact_person.phone_1
                  }}"
                >
                  {{ caregiver.caregiver.important_contact_person.phone_1 }} </a
                >, Tel 2:
                <span
                  *ngIf="!caregiver.caregiver.important_contact_person.phone_2"
                  >-</span
                >
                <a
                  (click)="
                    phoneCallService.createCalling(
                      caregiver.caregiver.important_contact_person.phone_2
                    )
                  "
                  *ngIf="caregiver.caregiver.important_contact_person.phone_2"
                  href="tel:{{
                    caregiver.caregiver.important_contact_person.phone_2
                  }}"
                >
                  {{ caregiver.caregiver.important_contact_person.phone_2 }}
                </a>
              </strong>
            </div>
          </div>

          <div class="detail-information">
            <div
              class="detail-key"
              [class.alert-key]="caregiver.caregiver.vetos.length > 0"
            >
              <span>Veto</span>
            </div>
            <div
              class="detail-value veto-value"
              *ngIf="caregiver.caregiver.vetos.length > 0"
            >
              <div class="detail-value-search">
                <input
                  placeholder="Filter..."
                  class="smaller-input"
                  pInputText
                  name="veto-search"
                  [(ngModel)]="searchQuery"
                />
              </div>

              <strong
                [class.active]="
                  searchedForPatientInVeto(veto.patient.full_name)
                "
                [class.closed]="i > 5 && !vetoOpened && !searchQuery"
                *ngFor="let veto of caregiver.caregiver.vetos; let i = index"
                class="veto-item"
              >
                <i
                  class="pi pi-info-circle"
                  [pTooltip]="veto.comment || '-'"
                ></i>
                <a routerLink="/patients/{{ veto.patient_id }}">
                  {{ veto.patient.last_name }}
                  {{ veto.patient.first_name }}
                </a>
              </strong>

              <strong
                *ngIf="caregiver.caregiver.vetos.length > 6 && !searchQuery"
                (click)="vetoOpened = !vetoOpened"
                class="show-more-btn-light veto-item active"
              >
                {{ vetoOpened ? "WENIGER ANZEIGEN" : "ALLE ANZEIGEN" }}
              </strong>
            </div>

            <div
              class="detail-value"
              *ngIf="caregiver.caregiver.vetos.length === 0"
            >
              <strong>-</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    planner_name: caregiver.caregiver.planner_name
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    planner_name: caregiver.caregiver.planner_name
                  })
                "
                [pTooltip]="latestChanges.planner_name"
                >Betreut durch</span
              >
            </div>
            <div class="detail-value">
              <strong>{{
                caregiver.caregiver.planner?.user_system?.full_name || "-"
              }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    persplan_id: caregiver.caregiver.persplan_id
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    persplan_id: caregiver.caregiver.persplan_id
                  })
                "
                [pTooltip]="latestChanges.persplan_id"
                >Persplan ID</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ caregiver.caregiver.persplan_id }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span [title]="caregiver.caregiver.user_id">ID</span>
            </div>
            <div class="detail-value">
              <strong>{{ caregiver.caregiver.id }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    form_of_address: caregiver.caregiver.form_of_address,
                    first_name: caregiver.caregiver.first_name,
                    last_name: caregiver.caregiver.last_name
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    form_of_address: caregiver.caregiver.form_of_address,
                    first_name: caregiver.caregiver.first_name,
                    last_name: caregiver.caregiver.last_name
                  })
                "
                [pTooltip]="
                  latestChanges.form_of_address ||
                  latestChanges.first_name ||
                  latestChanges.last_name
                "
                >Name</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ caregiver.caregiver.full_name }}
                {{
                  caregiver.caregiver.birth_name
                    ? "(" + caregiver.caregiver.birth_name + ")"
                    : ""
                }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    birthday_date_string:
                      caregiver.caregiver.birthday_date_string
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    birthday_date_string:
                      caregiver.caregiver.birthday_date_string
                  })
                "
                [pTooltip]="latestChanges.birthday_date_string"
                >Geburtsdatum</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ caregiver.caregiver.birthday_date_string }}
                {{
                  caregiver.caregiver.birthplace
                    ? "(" + caregiver.caregiver.birthplace + ")"
                    : ""
                }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    phone_1: caregiver.caregiver.phone_1,
                    phone_1_info: caregiver.caregiver.phone_1_info
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    phone_1: caregiver.caregiver.phone_1,
                    phone_1_info: caregiver.caregiver.phone_1_info
                  })
                "
                [pTooltip]="latestChanges.phone_1 || latestChanges.phone_1_info"
                >Telefon 1</span
              >
            </div>
            <div class="detail-value">
              <strong *ngIf="!caregiver.caregiver.phone_1">-</strong>
              <strong class="p-d-flex p-align-center">
                <a
                  (click)="
                    phoneCallService.createCalling(caregiver.caregiver.phone_1)
                  "
                  *ngIf="caregiver.caregiver.phone_1"
                  href="tel:{{ caregiver.caregiver.phone_1 }}"
                >
                  {{ caregiver.caregiver.phone_1 }}
                </a>

                <i
                  style="margin: 0 0 0 10px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="caregiver.caregiver.phone_1_info"
                  [pTooltip]="caregiver.caregiver.phone_1_info"
                ></i>
              </strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    phone_2: caregiver.caregiver.phone_2,
                    phone_2_info: caregiver.caregiver.phone_2_info
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    phone_2: caregiver.caregiver.phone_2,
                    phone_2_info: caregiver.caregiver.phone_2_info
                  })
                "
                [pTooltip]="latestChanges.phone_2 || latestChanges.phone_2_info"
                >Telefon 2</span
              >
            </div>
            <div class="detail-value">
              <strong *ngIf="!caregiver.caregiver.phone_2">-</strong>
              <strong class="p-d-flex p-align-center">
                <a
                  *ngIf="caregiver.caregiver.phone_2"
                  (click)="
                    phoneCallService.createCalling(caregiver.caregiver.phone_2)
                  "
                  href="tel:{{ caregiver.caregiver.phone_2 }}"
                >
                  {{ caregiver.caregiver.phone_2 }}
                </a>

                <i
                  style="margin: 0 0 0 10px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="caregiver.caregiver.phone_2_info"
                  [pTooltip]="caregiver.caregiver.phone_2_info"
                ></i>
              </strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    phone_3: caregiver.caregiver.phone_3,
                    phone_3_info: caregiver.caregiver.phone_3_info
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    phone_3: caregiver.caregiver.phone_3,
                    phone_3_info: caregiver.caregiver.phone_3_info
                  })
                "
                [pTooltip]="latestChanges.phone_3 || latestChanges.phone_3_info"
                >Telefon 3</span
              >
            </div>
            <div class="detail-value">
              <strong *ngIf="!caregiver.caregiver.phone_3">-</strong>
              <strong class="p-d-flex p-align-center">
                <a
                  *ngIf="caregiver.caregiver.phone_3"
                  (click)="
                    phoneCallService.createCalling(caregiver.caregiver.phone_3)
                  "
                  href="tel:{{ caregiver.caregiver.phone_3 }}"
                >
                  {{ caregiver.caregiver.phone_3 }}
                </a>

                <i
                  style="margin: 0 0 0 10px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="caregiver.caregiver.phone_3_info"
                  [pTooltip]="caregiver.caregiver.phone_3_info"
                ></i>
              </strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    email: caregiver.caregiver.email,
                    email_info: caregiver.caregiver.email_info
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    email: caregiver.caregiver.email,
                    email_info: caregiver.caregiver.email_info
                  })
                "
                [pTooltip]="latestChanges.email || latestChanges.email_info"
                >E-Mail</span
              >
            </div>
            <div class="detail-value">
              <strong *ngIf="!caregiver.caregiver.email">-</strong>
              <strong class="p-d-flex p-align-center">
                <a
                  *ngIf="caregiver.caregiver.email"
                  href="mailto:{{ caregiver.caregiver.email }}"
                >
                  {{ caregiver.caregiver.email }}
                </a>

                <i
                  style="margin: 0 0 0 10px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="caregiver.caregiver.email_info"
                  [pTooltip]="caregiver.caregiver.email_info"
                ></i>
              </strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    street_house_number:
                      caregiver.caregiver.street_house_number,
                    city: caregiver.caregiver.city,
                    city_part: caregiver.caregiver.city_part,
                    zipcode: caregiver.caregiver.zipcode
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    street_house_number:
                      caregiver.caregiver.street_house_number,
                    city: caregiver.caregiver.city,
                    city_part: caregiver.caregiver.city_part,
                    zipcode: caregiver.caregiver.zipcode
                  })
                "
                [pTooltip]="
                  latestChanges.street_house_number ||
                  latestChanges.city ||
                  latestChanges.city_part ||
                  latestChanges.zipcode
                "
                >Adresse</span
              >
            </div>
            <div class="detail-value detail-value-with-actions">
              <strong
                >{{ caregiver.caregiver.street_house_number }}<br />{{
                  caregiver.caregiver.zipcode
                }}
                {{ caregiver.caregiver.city }}
                {{ caregiver.caregiver.city_part }}</strong
              >

              <i
                (click)="copyAddressToClipboard()"
                class="pi"
                [class.pi-check]="addressCopied"
                [class.pi-file]="!addressCopied"
                pTooltip="Adresse kopieren"
              ></i>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    state: caregiver.caregiver.state,
                    county: caregiver.caregiver.county
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    state: caregiver.caregiver.state,
                    county: caregiver.caregiver.county
                  })
                "
                [pTooltip]="latestChanges.state || latestChanges.county"
                >Landkreis / Bundesland</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ caregiver.caregiver.county || "-" }} /
                {{ caregiver.caregiver.state || "-" }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    glove_size: caregiver.caregiver.glove_size
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    glove_size: caregiver.caregiver.glove_size
                  })
                "
                [pTooltip]="latestChanges.glove_size"
                >Handschuhgröße</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ caregiver.caregiver.glove_size }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    clothes_size: caregiver.caregiver.clothes_size
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    clothes_size: caregiver.caregiver.clothes_size
                  })
                "
                [pTooltip]="latestChanges.clothes_size"
                >Kleidergröße</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ caregiver.caregiver.clothes_size }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    smoking: caregiver.caregiver.smoking
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    smoking: caregiver.caregiver.smoking
                  })
                "
                [pTooltip]="latestChanges.smoking"
                >Rauchen</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ caregiver.caregiver.smoking }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    driving_license_since_string:
                      caregiver.caregiver.driving_license_since_string
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    driving_license_since_string:
                      caregiver.caregiver.driving_license_since_string
                  })
                "
                [pTooltip]="latestChanges.driving_license_since_string"
                >Führerschein seit</span
              >
            </div>
            <div class="detail-value">
              <strong>{{
                caregiver.caregiver.driving_license_since_string || "-"
              }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    level_of_disability:
                      caregiver.caregiver.level_of_disability,
                    level_of_disability_since_string:
                      caregiver.caregiver.level_of_disability_since_string,
                    level_of_disability_until_string:
                      caregiver.caregiver.level_of_disability_until_string
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    level_of_disability:
                      caregiver.caregiver.level_of_disability,
                    level_of_disability_since_string:
                      caregiver.caregiver.level_of_disability_since_string,
                    level_of_disability_until_string:
                      caregiver.caregiver.level_of_disability_until_string
                  })
                "
                [pTooltip]="
                  latestChanges.level_of_disability ||
                  latestChanges.level_of_disability_since_string ||
                  latestChanges.level_of_disability_until_string
                "
                >Behindertengrad</span
              >
            </div>
            <div class="detail-value">
              <strong *ngIf="caregiver.caregiver.level_of_disability">
                {{ caregiver.caregiver.level_of_disability }} %
                <span
                  *ngIf="
                    caregiver.caregiver.level_of_disability_since &&
                    !caregiver.caregiver.level_of_disability_until
                  "
                >
                  ({{ caregiver.caregiver.level_of_disability_since_string }})
                </span>
                <span
                  *ngIf="
                    caregiver.caregiver.level_of_disability_since &&
                    caregiver.caregiver.level_of_disability_until
                  "
                >
                  ({{ caregiver.caregiver.level_of_disability_since_string }} -
                  {{ caregiver.caregiver.level_of_disability_until_string }})
                </span>
              </strong>
            </div>
          </div>
          <div
            class="detail-information"
            *ngIf="caregiver.caregiver.current_status.status !== 'EMPLOYMENT'"
          >
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    employment_possible_at_string:
                      caregiver.caregiver.employment_possible_at_string
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    employment_possible_at_string:
                      caregiver.caregiver.employment_possible_at_string
                  })
                "
                [pTooltip]="latestChanges.employment_possible_at_string"
                >Beginn möglich am</span
              >
            </div>
            <div class="detail-value">
              <strong>{{
                caregiver.caregiver.employment_possible_at_string || "-"
              }}</strong>
            </div>
          </div>
          <div
            class="detail-information"
            *ngIf="
              caregiver.caregiver.current_status.status !== 'EMPLOYMENT' &&
              caregiver.caregiver.jobcenter_number
            "
          >
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    jobcenter_number: caregiver.caregiver.jobcenter_number
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    jobcenter_number: caregiver.caregiver.jobcenter_number
                  })
                "
                [pTooltip]="latestChanges.jobcenter_number"
                >KD Bundesagentur für Arbeit</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ caregiver.caregiver.jobcenter_number || "-" }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    current_job_situation_string:
                      caregiver.caregiver.current_job_situation_string,
                    old_employment_unlimited:
                      caregiver.caregiver.old_employment_unlimited
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    current_job_situation_string:
                      caregiver.caregiver.current_job_situation_string,
                    old_employment_unlimited:
                      caregiver.caregiver.old_employment_unlimited
                  })
                "
                [pTooltip]="
                  latestChanges.current_job_situation_string ||
                  latestChanges.old_employment_unlimited
                "
                >Aktuelle berufliche Situation</span
              >
            </div>
            <div class="detail-value">
              <strong class="p-d-flex p-align-center"
                >{{ caregiver.caregiver.current_job_situation_string || "-" }}
                {{
                  caregiver.caregiver.old_employment_until_string
                    ? " (bis " +
                      caregiver.caregiver.old_employment_until_string +
                      ")"
                    : ""
                }}
                {{
                  caregiver.caregiver.old_employment_unlimited
                    ? " (Unbefristet)"
                    : ""
                }}

                <i
                  style="margin: 0 0 0 10px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="caregiver.caregiver.current_job_situation_notice"
                  [pTooltip]="caregiver.caregiver.current_job_situation_notice"
                ></i>
              </strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    vaccinated_covid_19:
                      caregiver.caregiver.vaccinated_covid_19,
                    vaccinated_covid_19_info:
                      caregiver.caregiver.vaccinated_covid_19_info
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    vaccinated_covid_19:
                      caregiver.caregiver.vaccinated_covid_19,
                    vaccinated_covid_19_info:
                      caregiver.caregiver.vaccinated_covid_19_info
                  })
                "
                [pTooltip]="
                  latestChanges.vaccinated_covid_19 ||
                  latestChanges.vaccinated_covid_19_info
                "
                >Geimpft gegen COVID-19</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ caregiver.caregiver.vaccinated_covid_19 || "-" }}
                {{
                  caregiver.caregiver.vaccinated_covid_19_info
                    ? " - " + caregiver.caregiver.vaccinated_covid_19_info
                    : ""
                }}</strong
              >
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    allergy_text: caregiver.caregiver.allergy_text,
                    allergies: caregiver.caregiver.allergies
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    allergy_text: caregiver.caregiver.allergy_text,
                    allergies: caregiver.caregiver.allergies
                  })
                "
                [pTooltip]="
                  latestChanges.allergy_text || latestChanges.allergies
                "
                >Allergien</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ caregiver.caregiver.allergies || "-" }}
                {{
                  caregiver.caregiver.allergy_text
                    ? " - " + caregiver.caregiver.allergy_text
                    : ""
                }}</strong
              >
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    phobia_text: caregiver.caregiver.phobia_text,
                    phobias: caregiver.caregiver.phobias
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    phobia_text: caregiver.caregiver.phobia_text,
                    phobias: caregiver.caregiver.phobias
                  })
                "
                [pTooltip]="latestChanges.phobia_text || latestChanges.phobias"
                >Phobien</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ caregiver.caregiver.phobias || "-" }}
                {{
                  caregiver.caregiver.phobia_text
                    ? " - " + caregiver.caregiver.phobia_text
                    : ""
                }}</strong
              >
            </div>
          </div>

          <div
            class="detail-information"
            *ngIf="caregiver.caregiver.current_status.status === 'EMPLOYMENT'"
          >
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    consider_capacity: caregiver.caregiver.consider_capacity
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    consider_capacity: caregiver.caregiver.consider_capacity
                  })
                "
                [pTooltip]="latestChanges.consider_capacity"
                >Berücksichtigung Freie Kapazität</span
              >
            </div>
            <div class="detail-value">
              <strong>{{
                caregiver.caregiver.consider_capacity ? "Ja" : "Nein"
              }}</strong>
            </div>
          </div>

          <!--          <div class="detail-information">-->
          <!--            <div class="detail-key clickable">-->
          <!--              <span-->
          <!--                tooltipPosition="left"-->
          <!--                (mouseenter)="-->
          <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
          <!--                    salary: caregiver.caregiver.salary-->
          <!--                  })-->
          <!--                "-->
          <!--                (click)="-->
          <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
          <!--                    salary: caregiver.caregiver.salary-->
          <!--                  })-->
          <!--                "-->
          <!--                [pTooltip]="latestChanges.salary"-->
          <!--                >Gehalt</span-->
          <!--              >-->
          <!--            </div>-->
          <!--            <div class="detail-value">-->
          <!--              <strong *ngIf="!caregiver.caregiver.salary">-</strong>-->
          <!--              <i-->
          <!--                class="pi pi-info-circle"-->
          <!--                [pTooltip]="caregiver.caregiver.salary"-->
          <!--                *ngIf="caregiver.caregiver.salary"-->
          <!--              ></i>-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    about: caregiver.caregiver.about
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    about: caregiver.caregiver.about
                  })
                "
                [pTooltip]="latestChanges.about"
                >Über</span
              >
            </div>
            <div class="detail-value" style="white-space: pre-wrap">
              <strong>{{ caregiver.caregiver.about || "-" }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    current_family_situation:
                      caregiver.caregiver.current_family_situation
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    current_family_situation:
                      caregiver.caregiver.current_family_situation
                  })
                "
                [pTooltip]="latestChanges.current_family_situation"
                >Aktuelle familiäre Situation</span
              >
            </div>
            <div class="detail-value">
              <strong>{{
                caregiver.caregiver.current_family_situation || "-"
              }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="detail-information-column">
      <!-- Wichtige Ereignisse -->
      <div class="detail-information-container">
        <div class="detail-information-header">
          <span><i class="pi pi-star"></i> Wichtige Ereignisse</span>
        </div>

        <div
          class="detail-information-body"
          *ngIf="caregiver.important_histories.length === 0"
        >
          <span class="content-no-message"
            >Keine wichtigen Ereignisse vorhanden</span
          >
        </div>

        <div
          class="detail-information-body without-padding"
          style="max-height: 300px; height: 100%"
          *ngIf="caregiver.important_histories.length > 0"
        >
          <p-table
            [value]="caregiver.important_histories"
            [rowHover]="true"
            [scrollable]="true"
            scrollHeight="100%"
          >
            <ng-template pTemplate="header">
              <tr>
                <th>Informationen</th>
                <th pSortableColumn="created_at">
                  am <p-sortIcon field="created_at"></p-sortIcon>
                </th>
                <th pSortableColumn="created_from_name">
                  von <p-sortIcon field="created_from_name"></p-sortIcon>
                </th>
                <th style="max-width: 50px"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-history>
              <tr>
                <td>
                  <span
                    class="history-information"
                    *ngIf="history.type === 'PHONE_CALL'"
                  >
                    <i
                      ><strong>Telefonat Dauer:</strong>
                      {{ history.phone_call.duration }}</i
                    >
                  </span>

                  <span class="history-information">
                    <i
                      ><b>{{ history.manual_title }}</b></i
                    >
                  </span>
                </td>

                <td>{{ history.occurred_at_string }} Uhr</td>

                <td>{{ history.created_from_name || "??" }}</td>

                <td style="max-width: 50px">
                  <i
                    tooltipPosition="left"
                    [pTooltip]="history.manual_description"
                    class="pi pi-info-circle"
                    *ngIf="history.manual_description"
                  ></i>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>

      <!-- Todos -->
      <div class="detail-information-container">
        <div class="detail-information-header">
          <span> <i class="pi pi-check"></i> Todos </span>
        </div>

        <div
          class="detail-information-body"
          *ngIf="caregiver.caregiver.todos.length === 0"
        >
          <span class="content-no-message">Keine Todos vorhanden</span>
        </div>

        <div
          class="detail-information-body without-padding"
          style="max-height: 230px; height: 100%"
          *ngIf="caregiver.caregiver.todos.length > 0"
        >
          <p-table
            [value]="caregiver.caregiver.todos"
            [rowHover]="true"
            [scrollable]="true"
            scrollHeight="100%"
          >
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 120px" pSortableColumn="status">
                  Status <p-sortIcon field="status"></p-sortIcon>
                </th>
                <th pSortableColumn="title">
                  Titel <p-sortIcon field="title"></p-sortIcon>
                </th>
                <th style="max-width: 50px"></th>
                <th pSortableColumn="for_when">
                  Zu erledigen <p-sortIcon field="for_when"></p-sortIcon>
                </th>
                <th>Kommentare</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-todo>
              <tr class="clickable" (click)="openShowTodoDialog(todo)">
                <td style="width: 120px">
                  <span class="p-tag p-tag-rounded p-tag-{{ todo.status }}">{{
                    todo.status_string
                  }}</span>
                </td>
                <td>{{ todo.title }}</td>
                <td style="max-width: 50px">
                  <i
                    tooltipPosition="left"
                    [pTooltip]="todo.comment"
                    class="pi pi-info-circle"
                    *ngIf="todo.comment"
                  ></i>
                </td>
                <td>
                  {{ todo.for_when_type === "FINISH_AT" ? "am" : "bis" }}
                  {{ todo.for_when_string }}
                </td>
                <td>
                  {{ todo.comments_count }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>

      <!-- Checkliste Bewerber -->
      <div
        class="detail-information-container"
        *ngIf="caregiver.caregiver.current_status.status !== 'EMPLOYMENT'"
      >
        <div class="detail-information-header">
          <span> <i class="pi pi-check-circle"></i> Checkliste Bewerber </span>
        </div>

        <div class="detail-information-body">
          <div class="checklist-items">
            <div class="checklist-item">
              <i
                class="pi pi-check-circle"
                [class.color-gray]="
                  !caregiver.caregiver.current_status.job_interview_at_string
                "
                [class.color-main1]="
                  caregiver.caregiver.current_status.job_interview_at_string
                "
              ></i>
              <span
                *ngIf="
                  caregiver.caregiver.current_status.job_interview_at_string
                "
                class="color-main1"
                >Bewerbungsgespräch vereinbart ({{
                  caregiver.caregiver.current_status.job_interview_at_string
                }}
                Uhr) mit
                {{
                  caregiver.caregiver.current_status.job_interview_with || "?"
                }}
              </span>
              <span
                *ngIf="
                  !caregiver.caregiver.current_status.job_interview_at_string
                "
                class="color-gray"
                >Bewerbungsgespräch vereinbaren</span
              >
            </div>
            <div class="checklist-item">
              <i
                class="pi pi-check-circle"
                [class.color-gray]="
                  !caregiver.caregiver.application_documents_complete
                "
                [class.color-main1]="
                  caregiver.caregiver.application_documents_complete === 'Ja'
                "
                [class.color-alert]="
                  caregiver.caregiver.application_documents_complete === 'Nein'
                "
              ></i>
              <span
                class="color-gray"
                *ngIf="!caregiver.caregiver.application_documents_complete"
                >Bewerbungsunterlagen anfragen</span
              >
              <span
                class="color-main1"
                *ngIf="
                  caregiver.caregiver.application_documents_complete === 'Ja'
                "
                >Bewerbungsunterlagen sind vollständig</span
              >
              <span
                class="color-alert"
                *ngIf="
                  caregiver.caregiver.application_documents_complete === 'Nein'
                "
                >Es fehlen Bewerbungsunterlagen</span
              >
            </div>

            <div
              class="checklist-item color-gray"
              style="margin: -15px 0 0 34px; white-space: pre-line"
              *ngIf="
                caregiver.caregiver.missed_application_documents &&
                caregiver.caregiver.application_documents_complete === 'Nein'
              "
            >
              {{ caregiver.caregiver.missed_application_documents }}
            </div>

            <div
              style="margin: 0 0 0 34px"
              class="checklist-item color-gray"
              *ngIf="caregiver.caregiver.application_contact_via"
            >
              Kontaktiert per {{ caregiver.caregiver.application_contact_via }}
              {{ caregiver.caregiver.application_ne ? " (n.E.)" : "" }}
              {{ caregiver.caregiver.application_ne_ab ? " (n.E. + AB)" : "" }}
            </div>

            <div
              class="checklist-item color-gray"
              style="margin: 0 0 0 34px; white-space: pre-line"
              *ngIf="caregiver.caregiver.application_information"
            >
              {{ caregiver.caregiver.application_information }}
            </div>
          </div>
        </div>
      </div>

      <!--      <div class="detail-information-container">-->
      <!--        <div class="detail-information-header">Kontakt</div>-->
      <!--        <div class="detail-information-body">-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    phone_1: caregiver.caregiver.phone_1-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    phone_1: caregiver.caregiver.phone_1-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="latestChanges.phone_1"-->
      <!--                >Telefon 1</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong *ngIf="!caregiver.caregiver.phone_1">-</strong>-->
      <!--              <strong>-->
      <!--                <a-->
      <!--                  (click)="-->
      <!--                    phoneCallService.createCalling(caregiver.caregiver.phone_1)-->
      <!--                  "-->
      <!--                  *ngIf="caregiver.caregiver.phone_1"-->
      <!--                  href="tel:{{ caregiver.caregiver.phone_1 }}"-->
      <!--                >-->
      <!--                  {{ caregiver.caregiver.phone_1 }}-->
      <!--                </a>-->
      <!--              </strong>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    phone_2: caregiver.caregiver.phone_2-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    phone_2: caregiver.caregiver.phone_2-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="latestChanges.phone_2"-->
      <!--                >Telefon 2</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong *ngIf="!caregiver.caregiver.phone_2">-</strong>-->
      <!--              <strong>-->
      <!--                <a-->
      <!--                  *ngIf="caregiver.caregiver.phone_2"-->
      <!--                  (click)="-->
      <!--                    phoneCallService.createCalling(caregiver.caregiver.phone_2)-->
      <!--                  "-->
      <!--                  href="tel:{{ caregiver.caregiver.phone_2 }}"-->
      <!--                >-->
      <!--                  {{ caregiver.caregiver.phone_2 }}-->
      <!--                </a>-->
      <!--              </strong>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    phone_3: caregiver.caregiver.phone_3-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    phone_3: caregiver.caregiver.phone_3-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="latestChanges.phone_3"-->
      <!--                >Telefon 3</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong *ngIf="!caregiver.caregiver.phone_3">-</strong>-->
      <!--              <strong>-->
      <!--                <a-->
      <!--                  *ngIf="caregiver.caregiver.phone_3"-->
      <!--                  (click)="-->
      <!--                    phoneCallService.createCalling(caregiver.caregiver.phone_3)-->
      <!--                  "-->
      <!--                  href="tel:{{ caregiver.caregiver.phone_3 }}"-->
      <!--                >-->
      <!--                  {{ caregiver.caregiver.phone_3 }}-->
      <!--                </a>-->
      <!--              </strong>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    email: caregiver.caregiver.email-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    email: caregiver.caregiver.email-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="latestChanges.email"-->
      <!--                >E-Mail</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong *ngIf="!caregiver.caregiver.email">-</strong>-->
      <!--              <strong>-->
      <!--                <a-->
      <!--                  *ngIf="caregiver.caregiver.email"-->
      <!--                  href="mailto:{{ caregiver.caregiver.email }}"-->
      <!--                >-->
      <!--                  {{ caregiver.caregiver.email }}-->
      <!--                </a>-->
      <!--              </strong>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="detail-information-container">-->
      <!--        <div class="detail-information-header">Details</div>-->
      <!--        <div class="detail-information-body">-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    experiences_string: caregiver.caregiver.experiences_string-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    experiences_string: caregiver.caregiver.experiences_string-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="latestChanges.experiences_string"-->
      <!--                >Erfahrungen</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong>{{-->
      <!--                caregiver.caregiver.experiences_string || "-"-->
      <!--              }}</strong>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    experience_info: caregiver.caregiver.experience_info-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    experience_info: caregiver.caregiver.experience_info-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="latestChanges.experience_info"-->
      <!--                >Zusatzinfo</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong>{{ caregiver.caregiver.experience_info || "-" }}</strong>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    education_as: caregiver.caregiver.education_as-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    education_as: caregiver.caregiver.education_as-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="latestChanges.education_as"-->
      <!--                >Ausbildung als</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong>{{ caregiver.caregiver.education_as || "-" }}</strong>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    education_dementia: caregiver.caregiver.education_dementia-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    education_dementia: caregiver.caregiver.education_dementia-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="latestChanges.education_dementia"-->
      <!--                >DemenzBK</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong>{{-->
      <!--                caregiver.caregiver.education_dementia ? "Ja" : "Nein"-->
      <!--              }}</strong>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    education_assistant: caregiver.caregiver.education_assistant-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    education_assistant: caregiver.caregiver.education_assistant-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="latestChanges.education_assistant"-->
      <!--                >Pflegehilfskraft</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong>{{-->
      <!--                caregiver.caregiver.education_assistant ? "Ja" : "Nein"-->
      <!--              }}</strong>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    initial_training: caregiver.caregiver.initial_training,-->
      <!--                    last_training_date_string:-->
      <!--                      caregiver.caregiver.last_training_date_string-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    initial_training: caregiver.caregiver.initial_training,-->
      <!--                    last_training_date_string:-->
      <!--                      caregiver.caregiver.last_training_date_string-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="-->
      <!--                  latestChanges.last_training_date_string ||-->
      <!--                  latestChanges.initial_training-->
      <!--                "-->
      <!--                >Erstschulung vollständig?</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong-->
      <!--                >{{ caregiver.caregiver.initial_training }}-->
      <!--                {{-->
      <!--                  caregiver.caregiver.last_training_date_string-->
      <!--                    ? "(" + caregiver.caregiver.last_training_date_string + ")"-->
      <!--                    : ""-->
      <!--                }}</strong-->
      <!--              >-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    first_aid: caregiver.caregiver.first_aid,-->
      <!--                    first_aid_date_string:-->
      <!--                      caregiver.caregiver.first_aid_date_string-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    first_aid: caregiver.caregiver.first_aid,-->
      <!--                    first_aid_date_string:-->
      <!--                      caregiver.caregiver.first_aid_date_string-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="-->
      <!--                  latestChanges.first_aid_date_string || latestChanges.first_aid-->
      <!--                "-->
      <!--                >Erste Hilfe-Kurs?</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong-->
      <!--                >{{ caregiver.caregiver.first_aid }}-->
      <!--                {{-->
      <!--                  caregiver.caregiver.first_aid_date_string-->
      <!--                    ? "(" + caregiver.caregiver.first_aid_date_string + ")"-->
      <!--                    : ""-->
      <!--                }}</strong-->
      <!--              >-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    training_administration_date_string:-->
      <!--                      caregiver.caregiver.training_administration_date_string,-->
      <!--                    training_administration:-->
      <!--                      caregiver.caregiver.training_administration,-->
      <!--                    training_administration_from:-->
      <!--                      caregiver.caregiver.training_administration_from-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    training_administration_date_string:-->
      <!--                      caregiver.caregiver.training_administration_date_string,-->
      <!--                    training_administration:-->
      <!--                      caregiver.caregiver.training_administration,-->
      <!--                    training_administration_from:-->
      <!--                      caregiver.caregiver.training_administration_from-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="-->
      <!--                  latestChanges.training_administration_date_string ||-->
      <!--                  latestChanges.training_administration ||-->
      <!--                  latestChanges.training_administration_from-->
      <!--                "-->
      <!--                >Einarbeitung Administration?</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong-->
      <!--                >{{ caregiver.caregiver.training_administration }}-->
      <!--                {{-->
      <!--                  caregiver.caregiver.training_administration_date_string-->
      <!--                    ? "(" +-->
      <!--                      caregiver.caregiver.training_administration_date_string +-->
      <!--                      " von " +-->
      <!--                      (caregiver.caregiver.training_administration_from ||-->
      <!--                        "??") +-->
      <!--                      ")"-->
      <!--                    : ""-->
      <!--                }}</strong-->
      <!--              >-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    hospitation_dates: caregiver.caregiver.hospitation_dates,-->
      <!--                    hospitation: caregiver.caregiver.hospitation,-->
      <!--                    hospitation_from: caregiver.caregiver.hospitation_from-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    hospitation_dates: caregiver.caregiver.hospitation_dates,-->
      <!--                    hospitation: caregiver.caregiver.hospitation,-->
      <!--                    hospitation_from: caregiver.caregiver.hospitation_from-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="-->
      <!--                  latestChanges.hospitation_dates ||-->
      <!--                  latestChanges.hospitation_from ||-->
      <!--                  latestChanges.hospitation-->
      <!--                "-->
      <!--                >Hospitation?</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong-->
      <!--                >{{ caregiver.caregiver.hospitation }}-->
      <!--                {{-->
      <!--                  caregiver.caregiver.hospitation_dates-->
      <!--                    ? "(" +-->
      <!--                      caregiver.caregiver.hospitation_dates +-->
      <!--                      " von " +-->
      <!--                      (caregiver.caregiver.hospitation_from || "??") +-->
      <!--                      ")"-->
      <!--                    : ""-->
      <!--                }}</strong-->
      <!--              >-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    hospitation_feedback:-->
      <!--                      caregiver.caregiver.hospitation_feedback-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    hospitation_feedback:-->
      <!--                      caregiver.caregiver.hospitation_feedback-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="latestChanges.hospitation_feedback"-->
      <!--                >Hospitation Feedback</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong-->
      <!--                >{{ caregiver.caregiver.hospitation_feedback || "-" }}-->
      <!--              </strong>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div-->
      <!--        class="detail-information-container detail-information-current-time-table"-->
      <!--      >-->
      <!--        <div class="detail-information-header">-->
      <!--          <span>-->
      <!--            Arbeitszeiten-->
      <!--            <i-->
      <!--              *ngIf="caregiver.caregiver.current_time?.comment"-->
      <!--              [pTooltip]="caregiver.caregiver.current_time?.comment || ''"-->
      <!--              class="pi pi-info-circle"-->
      <!--            ></i>-->
      <!--          </span>-->
      <!--          <a-->
      <!--            routerLink="/caregivers/{{ caregiver.caregiver.id }}/overview/times"-->
      <!--            ><i class="pi pi-pencil" pTooltip="Bearbeiten"></i-->
      <!--          ></a>-->
      <!--        </div>-->
      <!--        <div class="detail-information-body without-padding">-->
      <!--          <p-table-->
      <!--            [value]="[caregiver.caregiver.current_time]"-->
      <!--            [rowHover]="true"-->
      <!--          >-->
      <!--            <ng-template pTemplate="header">-->
      <!--              <tr>-->
      <!--                <th>Montag</th>-->
      <!--                <th>Dienstag</th>-->
      <!--                <th>Mittwoch</th>-->
      <!--                <th>Donnerstag</th>-->
      <!--                <th>Freitag</th>-->
      <!--                <th>Samstag</th>-->
      <!--              </tr>-->
      <!--            </ng-template>-->
      <!--            <ng-template pTemplate="body" let-time>-->
      <!--              <tr>-->
      <!--                <td>-->
      <!--                  <span *ngIf="!time.days_with_key.monday.checked">-</span>-->
      <!--                  <span *ngIf="time.days_with_key.monday.checked">-->
      <!--                    {{ time.days_with_key.monday.start_first || "??" }} - -->
      <!--                    {{ time.days_with_key.monday.end_first || "??" }}-->
      <!--                  </span>-->
      <!--                  <span *ngIf="time.days_with_key.monday.has_second_block">-->
      <!--                    <br />-->
      <!--                    {{ time.days_with_key.monday.start_second || "??" }} - -->
      <!--                    {{ time.days_with_key.monday.end_second || "??" }}-->
      <!--                  </span>-->
      <!--                </td>-->

      <!--                <td>-->
      <!--                  <span *ngIf="!time.days_with_key.tuesday.checked">-</span>-->
      <!--                  <span *ngIf="time.days_with_key.tuesday.checked">-->
      <!--                    {{ time.days_with_key.tuesday.start_first || "??" }} - -->
      <!--                    {{ time.days_with_key.tuesday.end_first || "??" }}-->
      <!--                  </span>-->
      <!--                  <span *ngIf="time.days_with_key.tuesday.has_second_block">-->
      <!--                    <br />-->
      <!--                    {{ time.days_with_key.tuesday.start_second || "??" }} - -->
      <!--                    {{ time.days_with_key.tuesday.end_second || "??" }}-->
      <!--                  </span>-->
      <!--                </td>-->

      <!--                <td>-->
      <!--                  <span *ngIf="!time.days_with_key.wednesday.checked">-</span>-->
      <!--                  <span *ngIf="time.days_with_key.wednesday.checked">-->
      <!--                    {{ time.days_with_key.wednesday.start_first || "??" }} - -->
      <!--                    {{ time.days_with_key.wednesday.end_first || "??" }}-->
      <!--                  </span>-->
      <!--                  <span *ngIf="time.days_with_key.wednesday.has_second_block">-->
      <!--                    <br />-->
      <!--                    {{ time.days_with_key.wednesday.start_second || "??" }} - -->
      <!--                    {{ time.days_with_key.wednesday.end_second || "??" }}-->
      <!--                  </span>-->
      <!--                </td>-->

      <!--                <td>-->
      <!--                  <span *ngIf="!time.days_with_key.thursday.checked">-</span>-->
      <!--                  <span *ngIf="time.days_with_key.thursday.checked">-->
      <!--                    {{ time.days_with_key.thursday.start_first || "??" }} - -->
      <!--                    {{ time.days_with_key.thursday.end_first || "??" }}-->
      <!--                  </span>-->
      <!--                  <span *ngIf="time.days_with_key.thursday.has_second_block">-->
      <!--                    <br />-->
      <!--                    {{ time.days_with_key.thursday.start_second || "??" }} - -->
      <!--                    {{ time.days_with_key.thursday.end_second || "??" }}-->
      <!--                  </span>-->
      <!--                </td>-->

      <!--                <td>-->
      <!--                  <span *ngIf="!time.days_with_key.friday.checked">-</span>-->
      <!--                  <span *ngIf="time.days_with_key.friday.checked">-->
      <!--                    {{ time.days_with_key.friday.start_first || "??" }} - -->
      <!--                    {{ time.days_with_key.friday.end_first || "??" }}-->
      <!--                  </span>-->
      <!--                  <span *ngIf="time.days_with_key.friday.has_second_block">-->
      <!--                    <br />-->
      <!--                    {{ time.days_with_key.friday.start_second || "??" }} - -->
      <!--                    {{ time.days_with_key.friday.end_second || "??" }}-->
      <!--                  </span>-->
      <!--                </td>-->

      <!--                <td>-->
      <!--                  <span *ngIf="!time.days_with_key.saturday.checked">-</span>-->
      <!--                  <span *ngIf="time.days_with_key.saturday.checked">-->
      <!--                    {{ time.days_with_key.saturday.start_first || "??" }} - -->
      <!--                    {{ time.days_with_key.saturday.end_first || "??" }}-->
      <!--                  </span>-->
      <!--                  <span *ngIf="time.days_with_key.saturday.has_second_block">-->
      <!--                    <br />-->
      <!--                    {{ time.days_with_key.saturday.start_second || "??" }} - -->
      <!--                    {{ time.days_with_key.saturday.end_second || "??" }}-->
      <!--                  </span>-->
      <!--                </td>-->
      <!--              </tr>-->
      <!--            </ng-template>-->
      <!--          </p-table>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="detail-information-container">-->
      <!--        <div class="detail-information-header">-->
      <!--          PKW-->
      <!--          <i-->
      <!--            class="pi pi-plus"-->
      <!--            (click)="openAddCaregiverCarDialog()"-->
      <!--            pTooltip="Hinzufügen"-->
      <!--          ></i>-->
      <!--        </div>-->
      <!--        <div-->
      <!--          class="detail-information-body"-->
      <!--          *ngIf="caregiver.caregiver.cars.length === 0"-->
      <!--        >-->
      <!--          <span class="content-no-message">Kein PKW vorhanden</span>-->
      <!--        </div>-->
      <!--        <div-->
      <!--          class="detail-information-body without-padding"-->
      <!--          *ngIf="caregiver.caregiver.cars.length > 0"-->
      <!--        >-->
      <!--          <p-table [value]="caregiver.caregiver.cars" [rowHover]="true">-->
      <!--            <ng-template pTemplate="header">-->
      <!--              <tr>-->
      <!--                <th>Name</th>-->
      <!--                <th style="width: 120px">seit</th>-->
      <!--                <th style="width: 120px">bis</th>-->
      <!--                <th style="width: 90px">KM</th>-->
      <!--                <th style="width: 100px">Kommentar</th>-->
      <!--                <th style="width: 50px"></th>-->
      <!--              </tr>-->
      <!--            </ng-template>-->
      <!--            <ng-template pTemplate="body" let-car>-->
      <!--              <tr>-->
      <!--                <td>-->
      <!--                  <a routerLink="/cars/{{ car.car_id }}">{{-->
      <!--                    car.car.full_name-->
      <!--                  }}</a>-->
      <!--                </td>-->
      <!--                <td>{{ car.since | date: "dd.MM.y" }}</td>-->
      <!--                <td *ngIf="car.until">{{ car.until | date: "dd.MM.y" }}</td>-->
      <!--                <td *ngIf="!car.until">-</td>-->
      <!--                <td>{{ car.mileage || "-" }}</td>-->
      <!--                <td>-->
      <!--                  <i-->
      <!--                    *ngIf="car.comment"-->
      <!--                    class="pi pi-info-circle"-->
      <!--                    [pTooltip]="car.comment"-->
      <!--                  ></i>-->
      <!--                </td>-->
      <!--                <td>-->
      <!--                  <i-->
      <!--                    class="pi pi-pencil"-->
      <!--                    (click)="openAddCaregiverCarDialog(car)"-->
      <!--                  ></i>-->
      <!--                </td>-->
      <!--              </tr>-->
      <!--            </ng-template>-->
      <!--          </p-table>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</div>

<div class="content new-content-container" *ngIf="!loading">
  <header class="content-header">
    <span>
      <i class="pi pi-phone"></i>
      Ansprechpartner
    </span>
    <button
      pButton
      (click)="openContactPersonDialog()"
      label="Neuer Ansprechpartner"
      type="button"
      class="p-button-text button-without-padding"
      icon="pi pi-plus"
      iconPos="right"
    ></button>
  </header>
  <div
    class="content-body contact-person-content-body"
    *ngIf="caregiver.caregiver.contact_persons.length === 0"
  >
    <span class="content-no-message"> Kein Ansprechpartner vorhanden </span>
  </div>

  <div
    class="content-body contact-person-content-body without-padding p-flex-row p-flex-wrap"
    *ngIf="caregiver.caregiver.contact_persons.length > 0"
  >
    <div
      *ngFor="
        let contactPerson of caregiver.caregiver.contact_persons;
        let i = index
      "
      class="detail-information-column contact-person-column border-right"
    >
      <div class="detail-information-container">
        <div class="detail-information-header for-contact-person">
          <span>
            <i class="pi pi-user"></i>
            Ansprechpartner {{ i + 1 }}
          </span>

          <div style="display: flex; align-items: center">
            <i
              pTooltip="Kann drucken"
              class="pi pi-print"
              *ngIf="contactPerson.can_print"
            ></i>
            <i
              class="pi pi-pencil"
              (click)="openContactPersonDialog(contactPerson)"
            ></i>
          </div>
        </div>
        <div class="detail-information-body">
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(contactPerson.id, 'contact_person', {
                    informations_string: contactPerson.informations_string
                  })
                "
                (click)="
                  openDiffViewHistory(contactPerson.id, 'contact_person', {
                    informations_string: contactPerson.informations_string
                  })
                "
                [pTooltip]="latestChanges.informations_string"
                >Infos</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ contactPerson.informations_string || "-" }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(contactPerson.id, 'contact_person', {
                    form_of_address: contactPerson.form_of_address,
                    first_name: contactPerson.first_name,
                    last_name: contactPerson.last_name
                  })
                "
                (click)="
                  openDiffViewHistory(contactPerson.id, 'contact_person', {
                    form_of_address: contactPerson.form_of_address,
                    first_name: contactPerson.first_name,
                    last_name: contactPerson.last_name
                  })
                "
                [pTooltip]="
                  latestChanges.form_of_address ||
                  latestChanges.first_name ||
                  latestChanges.last_name
                "
                >Name</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ contactPerson.form_of_address }}
                {{ contactPerson.first_name }}
                {{ contactPerson.last_name }}</strong
              >
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(contactPerson.id, 'contact_person', {
                    relation: contactPerson.relation
                  })
                "
                (click)="
                  openDiffViewHistory(contactPerson.id, 'contact_person', {
                    relation: contactPerson.relation
                  })
                "
                [pTooltip]="latestChanges.relation"
                >Verhältnis</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ contactPerson.relation }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(contactPerson.id, 'contact_person', {
                    phone_1: contactPerson.phone_1,
                    phone_1_info: contactPerson.phone_1_info
                  })
                "
                (click)="
                  openDiffViewHistory(contactPerson.id, 'contact_person', {
                    phone_1: contactPerson.phone_1,
                    phone_1_info: contactPerson.phone_1_info
                  })
                "
                [pTooltip]="latestChanges.phone_1 || latestChanges.phone_1_info"
                >Telefon 1</span
              >
            </div>
            <div class="detail-value">
              <strong class="p-d-flex p-align-center">
                <a
                  href="tel:{{ contactPerson.phone_1 }}"
                  (click)="
                    phoneCallService.createCalling(contactPerson.phone_1)
                  "
                >
                  {{ contactPerson.phone_1 }}
                </a>

                <i
                  style="margin: 0 0 0 10px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="contactPerson.phone_1_info"
                  [pTooltip]="contactPerson.phone_1_info"
                ></i>
              </strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(contactPerson.id, 'contact_person', {
                    phone_2: contactPerson.phone_2,
                    phone_2_info: contactPerson.phone_2_info
                  })
                "
                (click)="
                  openDiffViewHistory(contactPerson.id, 'contact_person', {
                    phone_2: contactPerson.phone_2,
                    phone_2_info: contactPerson.phone_2_info
                  })
                "
                [pTooltip]="latestChanges.phone_2 || latestChanges.phone_2_info"
                >Telefon 2</span
              >
            </div>
            <div class="detail-value">
              <strong class="p-d-flex p-align-center">
                <a
                  href="tel:{{ contactPerson.phone_2 }}"
                  (click)="
                    phoneCallService.createCalling(contactPerson.phone_2)
                  "
                >
                  {{ contactPerson.phone_2 }}
                </a>

                <i
                  style="margin: 0 0 0 10px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="contactPerson.phone_2_info"
                  [pTooltip]="contactPerson.phone_2_info"
                ></i>
              </strong>
            </div>
          </div>
          <div class="detail-information" *ngIf="openedContactPersons[i]">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(contactPerson.id, 'contact_person', {
                    email_private: contactPerson.email_private,
                    email_private_info: contactPerson.email_private_info
                  })
                "
                (click)="
                  openDiffViewHistory(contactPerson.id, 'contact_person', {
                    email_private: contactPerson.email_private,
                    email_private_info: contactPerson.email_private_info
                  })
                "
                [pTooltip]="
                  latestChanges.email_private ||
                  latestChanges.email_private_info
                "
                >E-Mail Privat</span
              >
            </div>
            <div class="detail-value">
              <strong class="p-d-flex p-align-center">
                <a href="mailto:{{ contactPerson.email_private }}">
                  {{ contactPerson.email_private }}
                </a>

                <i
                  style="margin: 0 0 0 10px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="contactPerson.email_private_info"
                  [pTooltip]="contactPerson.email_private_info"
                ></i>
              </strong>
            </div>
          </div>
          <div class="detail-information" *ngIf="openedContactPersons[i]">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(contactPerson.id, 'contact_person', {
                    email_work: contactPerson.email_work,
                    email_work_info: contactPerson.email_work_info
                  })
                "
                (click)="
                  openDiffViewHistory(contactPerson.id, 'contact_person', {
                    email_work: contactPerson.email_work,
                    email_work_info: contactPerson.email_work_info
                  })
                "
                [pTooltip]="
                  latestChanges.email_work || latestChanges.email_work_info
                "
                >E-Mail Geschäftlich</span
              >
            </div>
            <div class="detail-value">
              <strong class="p-d-flex p-align-center">
                <a href="mailto:{{ contactPerson.email_work }}">
                  {{ contactPerson.email_work }}
                </a>

                <i
                  style="margin: 0 0 0 10px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="contactPerson.email_work_info"
                  [pTooltip]="contactPerson.email_work_info"
                ></i>
              </strong>
            </div>
          </div>
          <div class="detail-information" *ngIf="openedContactPersons[i]">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(contactPerson.id, 'contact_person', {
                    zipcode: contactPerson.zipcode,
                    city: contactPerson.city,
                    city_part: contactPerson.city_part
                  })
                "
                (click)="
                  openDiffViewHistory(contactPerson.id, 'contact_person', {
                    zipcode: contactPerson.zipcode,
                    city: contactPerson.city,
                    city_part: contactPerson.city_part
                  })
                "
                [pTooltip]="
                  latestChanges.zipcode ||
                  latestChanges.city ||
                  latestChanges.city_part
                "
                >PLZ / Ort</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ contactPerson.zipcode }} {{ contactPerson.city }}
                {{ contactPerson.city_part }}</strong
              >
            </div>
          </div>
          <div class="detail-information" *ngIf="openedContactPersons[i]">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(contactPerson.id, 'contact_person', {
                    street_house_number: contactPerson.street_house_number
                  })
                "
                (click)="
                  openDiffViewHistory(contactPerson.id, 'contact_person', {
                    street_house_number: contactPerson.street_house_number
                  })
                "
                [pTooltip]="latestChanges.street_house_number"
                >Adresse</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ contactPerson.street_house_number }}</strong>
            </div>
          </div>
          <div class="detail-information" *ngIf="openedContactPersons[i]">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(contactPerson.id, 'contact_person', {
                    comment: contactPerson.comment
                  })
                "
                (click)="
                  openDiffViewHistory(contactPerson.id, 'contact_person', {
                    comment: contactPerson.comment
                  })
                "
                [pTooltip]="latestChanges.comment"
                >Kommentar</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ contactPerson.comment }}</strong>
            </div>
          </div>
        </div>

        <span
          class="show-more-btn"
          (click)="openedContactPersons[i] = !openedContactPersons[i]"
        >
          {{ openedContactPersons[i] ? "Weniger anzeigen" : "Mehr anzeigen" }}
        </span>
      </div>
    </div>
  </div>
</div>

<div class="content new-content-container" *ngIf="!loading">
  <header class="content-header">
    <span>
      <i class="pi pi-clock"></i>
      Letzte Historie
    </span>
    <button
      pButton
      label="Neuer Eintrag"
      (click)="openManualHistoryDialog(false)"
      type="button"
      class="p-button-text button-without-padding"
      icon="pi pi-plus"
      iconPos="right"
    ></button>
  </header>
  <div
    class="content-body content-base-height"
    *ngIf="caregiver.last_histories.length === 0"
  >
    <span class="content-no-message">Keine History vorhanden</span>
  </div>
  <div
    class="content-body without-padding"
    *ngIf="caregiver.last_histories.length > 0"
  >
    <p-table
      [value]="caregiver.last_histories"
      [rowHover]="true"
      [scrollable]="true"
      scrollHeight="500px"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th pSortableColumn="type_text">
            Typ <p-sortIcon field="type_text"></p-sortIcon>
          </th>
          <th pSortableColumn="created_at">
            am <p-sortIcon field="created_at"></p-sortIcon>
          </th>
          <th pSortableColumn="created_from_name">
            von <p-sortIcon field="created_from_name"></p-sortIcon>
          </th>
          <th style="max-width: 50px"></th>
          <th>Informationen</th>
          <th style="width: 100px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-history>
        <tr [class.background-hightlight-yellow]="history.important">
          <td>
            <strong>
              <!--                <i-->
              <!--                  class="pi pi-star color-orange"-->
              <!--                  *ngIf="history.important"-->
              <!--                  pTooltip="Wichtiges Ereignis"-->
              <!--                ></i>-->
              {{ history.type_text }}
            </strong>
          </td>

          <td>{{ history.occurred_at_string }} Uhr</td>

          <td>{{ history.created_from_name || "??" }}</td>

          <td style="max-width: 50px">
            <i
              tooltipPosition="left"
              [pTooltip]="history.phone_call.comment"
              class="pi pi-info-circle"
              *ngIf="history.phone_call?.comment"
            ></i>

            <i
              tooltipPosition="left"
              [pTooltip]="history.manual_description"
              class="pi pi-info-circle"
              *ngIf="history.manual_description"
            ></i>
          </td>
          <td>
            <span
              class="history-information"
              *ngIf="history.type === 'PHONE_CALL'"
            >
              <i><strong>Dauer:</strong> {{ history.phone_call.duration }}</i>
            </span>

            <span
              class="history-information"
              *ngIf="
                history.type === 'CONTACT_PERSON_CREATED' ||
                history.type === 'CONTACT_PERSON_UPDATED' ||
                history.type === 'CONTACT_PERSON_DEACTIVATED'
              "
            >
              <i><strong>Name:</strong> {{ history.manual_description }}</i>
            </span>

            <span
              class="history-information"
              *ngIf="
                history.type === 'MANUAL' ||
                history.type === 'CAREGIVER_ACCULUMATED_UNRELEASED' ||
                history.type === 'CAREGIVER_ACCULUMATED_RELEASED' ||
                history.type === 'TODO_CREATED' ||
                history.type === 'TODO_FINISHED' ||
                history.type === 'TODO_DELETED' ||
                history.type === 'TODO_UPDATED' ||
                history.type === 'VETO_CREATED' ||
                history.type === 'VETO_DELETED' ||
                history.type === 'DOCUMENT_CHANGED' ||
                history.type === 'DOCUMENT_REMOVED' ||
                history.type === 'DOCUMENT_ARCHIVED' ||
                history.type === 'DOCUMENT_UNARCHIVED' ||
                history.type === 'VETO_UPDATED' ||
                history.type === 'APPROVED_PLANNING_CREATED' ||
                history.type === 'APPROVED_PLANNING_DELETED' ||
                history.type === 'APPROVED_PLANNING_UPDATED' ||
                history.type === 'VACATION_CREATED' ||
                history.type === 'VACATION_DELETED' ||
                history.type === 'VACATION_UPDATED' ||
                history.type === 'TRAINING_CREATED' ||
                history.type === 'TRAINING_DELETED' ||
                history.type === 'TRAINING_UPDATED' ||
                history.type === 'ILL_CREATED' ||
                history.type === 'ILL_DELETED' ||
                history.type === 'CAREGIVER_EMAIL_SENT' ||
                history.type === 'ILL_UPDATED' ||
                history.type === 'FREE_TIME_REQUEST_CREATED' ||
                history.type === 'FREE_TIME_REQUEST_DELETED' ||
                history.type === 'FREE_TIME_REQUEST_UPDATED' ||
                history.type === 'INTERN_DATE_CREATED' ||
                history.type === 'INTERN_DATE_DELETED' ||
                history.type === 'INTERN_DATE_UPDATED' ||
                history.type === 'PLANNING_FOLLOWS_CREATED' ||
                history.type === 'PLANNING_FOLLOWS_DELETED' ||
                history.type === 'PLANNING_FOLLOWS_UPDATED' ||
                history.type === 'OTHER_DATE_CREATED' ||
                history.type === 'OTHER_DATE_DELETED' ||
                history.type === 'OTHER_DATE_UPDATED' ||
                history.type === 'CAR_ASSIGNED_TO_CAREGIVER' ||
                history.type === 'DRIVE_TIME_MANUALLY_CHANGED' ||
                history.type === 'CAREGIVER_TIME_CORRECTION' ||
                history.type === 'CAREGIVER_PERSPLAN_TIME_CORRECTION' ||
                history.type === 'CAR_ASSIGNMENT_TO_CAREGIVER_CHANGED' ||
                history.type === 'JOB_SCOPE_ADDED' ||
                history.type === 'EMPLOYMENT_ADDED' ||
                history.type === 'STATUS_CHANGED' ||
                history.type === 'EMPLOYMENT_UPDATED' ||
                history.type === 'ADMIN_OVERVIEW_ACTIVITY' ||
                history.type === 'EMPLOYMENT_DELETED' ||
                history.type === 'JOB_DAYS_ADDED' ||
                history.type === 'CREATED' ||
                history.type === 'CAREGIVER_PROOF_CREATED' ||
                history.type === 'USER_CAREGIVER_ACTIVITY' ||
                history.type === 'CAR_ASSIGNMENT_DELETED' ||
                history.type === 'SYSTEM_MESSAGE_SENT' ||
                history.type === 'CAREGIVER_TIMES_CHANGED' ||
                history.type === 'APPOINTMENT_FEEDBACK_FINISHED_WITH_HISTORY' ||
                history.type === 'SYSTEM_MESSAGE_SENT'
              "
            >
              <i
                ><b>{{ history.manual_title }}</b></i
              >
            </span>
          </td>
          <td class="table-action" style="width: 100px">
            <i
              (click)="openDiffView(history.id)"
              pTooltip="Änderungen ansehen"
              tooltipPosition="left"
              *ngIf="
                history.type === 'CHANGED' ||
                history.type === 'NOTICE_CHANGED' ||
                history.type === 'CONTACT_PERSON_UPDATED' ||
                history.type === 'CAR_ASSIGNMENT_TO_CAREGIVER_CHANGED'
              "
              class="pi pi-eye"
            ></i>
            <i
              *ngIf="history.type === 'PHONE_CALL'"
              class="pi pi-pencil"
              (click)="openPhonecallEditModal(history.phone_call)"
            ></i>
            <i
              *ngIf="
                history.type === 'TODO_CREATED' ||
                history.type === 'TODO_FINISHED' ||
                history.type === 'TODO_UPDATED'
              "
              class="pi pi-eye"
              pTooltip="Todo ansehen"
              (click)="openShowTodoDialog(history.todo)"
            ></i>
            <i
              *ngIf="history.type === 'MANUAL'"
              (click)="openManualHistoryDialog(true, history)"
              class="pi pi-pencil"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
