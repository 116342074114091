<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div
      class="p-fluid p-formgrid p-grid"
      style="width: 100%; margin: 0 0 20px 0"
    >
      <div class="p-field p-col-4">
        <label>Notiz Buchhaltung</label>
        <strong
          *ngIf="data.customerDetail.customer.notice_accounting"
          style="display: flex; align-items: center; gap: 10px"
          >Eintrag
          <i
            class="pi pi-info-circle"
            [pTooltip]="data.customerDetail.customer.notice_accounting"
          ></i
        ></strong>

        <strong *ngIf="!data.customerDetail.customer.notice_accounting"
          >Keine Angabe</strong
        >
      </div>

      <div class="p-field p-col-4">
        <label>Rechnungsversand</label>
        <strong style="display: flex; align-items: center; gap: 10px">
          {{
            data.customerDetail.first_patient.invoice_types_string ||
              "Keine Angabe"
          }}
          <i
            class="pi pi-info-circle"
            [pTooltip]="data.customerDetail.first_patient.invoice_type_tooltip"
            *ngIf="data.customerDetail.first_patient.invoice_type_tooltip"
          ></i>
        </strong>
      </div>

      <div class="p-field p-col-4">
        <label>AP für Rechnung</label>
        <strong style="display: flex; align-items: center; gap: 10px">
          {{ contactPersonsForInvoice || "Keine Angabe" }}
        </strong>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid"
      style="width: 100%; margin: 0; padding: 0"
    >
      <!-- Empfänger -->
      <div class="p-field p-col-6">
        <label for="receiver">Empfänger *</label>
        <p-dropdown
          inputId="receiver"
          name="receiver"
          [options]="receiverOptions"
          [(ngModel)]="values.receiver"
          appendTo="body"
          scrollHeight="400px"
          [required]="true"
          #receiver="ngModel"
          [class.p-invalid]="
            receiver.invalid && (receiver.dirty || receiver.touched)
          "
        >
          <ng-template let-receiver pTemplate="item">
            <div>{{ receiver.label }}</div>
            <span *ngIf="values.type === 'email'">{{ receiver.email }}</span>
          </ng-template>
        </p-dropdown>
      </div>

      <!-- CC an -->
      <div class="p-field p-col-6">
        <label for="cc">CC an</label>
        <p-multiSelect
          inputId="cc"
          placeholder="Optional"
          [options]="ccOptions"
          [filter]="false"
          [showHeader]="false"
          name="cc"
          appendTo="body"
          scrollHeight="400px"
          [(ngModel)]="values.cc"
        ></p-multiSelect>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer" *ngIf="!documentsCreated">
    <a
      *ngIf="values.type === 'email'"
      target="_blank"
      (click)="clickedOnPreview = true"
      [href]="
        invoiceService.getJustReminderMailPreviewLink(
          data.invoice.id,
          this.values
        )
      "
    >
      <button
        pButton
        label="Mail Vorschau"
        type="button"
        icon="pi pi-eye"
        iconPos="left"
        class="p-button p-button-info"
      ></button>
    </a>

    <button
      *ngIf="!documentLink"
      pButton
      [label]="values.type === 'email' ? 'Versenden' : 'Erstellen'"
      type="submit"
      (click)="save()"
      [pTooltip]="!clickedOnPreview ? 'Bitte Vorschau ansehen' : ''"
      [disabled]="submitted || !clickedOnPreview"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-send'"
    ></button>
  </div>
</form>
