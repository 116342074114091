import { Injectable } from '@angular/core'
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { AuthService } from '../services/auth.service'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { AuthModel } from '../models/user/auth.model'

@Injectable({
  providedIn: 'root',
})
export class LoginRouteGuard implements CanActivate {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  /**
   * Ist der Benutzer eingeloggt, soll er nicht
   * mehr auf die 'Login' Seite zugreifen können.
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean {
    // Wir prüfen, ob der Login Check auf der Loginseite schon gemacht wurde.
    // Sonst kommen wir in eine schleife, weil dieser Guard immer wieder aktiviert wird.
    if (this.authService.loginAlreadyChecked()) {
      return !this.authService.isAuthenticated()
    }

    return this.authService.authenticate().pipe(
      map((user: AuthModel) => {
        let backupUrl = '/'

        // Für Herrn Temi soll ein anderer Bereich angezeigt werden.
        if (user.id === 2) {
          backupUrl = '/start'
        }

        this.router.navigate([route.queryParams.returnUrl || backupUrl])

        return false
      }),
      catchError(() => {
        this.authService.setIsLoggedIn(false)
        this.authService.setUser(null)

        this.router.navigateByUrl(state.url)

        return of(true)
      })
    )
  }
}
