<div class="content-actions">
  <i class="tio icon-large">euro_outlined</i>

  <h2>
    <i class="tio">euro_outlined</i>
    Rechnungen
  </h2>
</div>

<header class="content-header-tabs">
  <a routerLink="/invoices/appointments" routerLinkActive="active">
    Einsätze
  </a>
  <a routerLink="/invoices/km" routerLinkActive="active"> KM-Fahrt </a>
  <a routerLink="/invoices/consultings" routerLinkActive="active"
    >Beratungseinsätze</a
  >
</header>

<router-outlet></router-outlet>
