<div class="content-actions">
  <i class="tio icon-large">calendar_note</i>

  <h2>
    <i class="tio">calendar_note</i>
    Dienstpläne
  </h2>
</div>

<header class="content-header-tabs">
  <a
    routerLink="/plans/feedback"
    *ngIf="authService.can('Betreuungskraft.Feedback verwalten')"
    routerLinkActive="active"
  >
    Feedback
  </a>
  <a
    routerLink="/plans/approvals"
    *ngIf="authService.can('Betreuungskraft.Dienstplan Freigaben verwalten')"
    routerLinkActive="active"
  >
    Freigaben
  </a>
</header>

<router-outlet></router-outlet>
