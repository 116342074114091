<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="content-no-message simple-text" *ngIf="data.length === 0">
      Keine Änderung
    </div>

    <div *ngFor="let price of data" class="content-no-message">
      {{ price.price_formatted }} (ab {{ price.since_formatted
      }}{{ price.until ? " bis " + price.until_formatted : "" }})
    </div>
  </div>
</form>
