import { Component, OnInit } from '@angular/core'
import { ToastService } from '../../../services/toast.service'
import { TitleService } from '../../../services/title.service'
import { UserCaregiverService } from '../../../services/user-caregiver.service'

@Component({
  selector: 'app-user-caregiver-prices',
  templateUrl: './user-caregiver-prices.component.html',
})
export class UserCaregiverPricesComponent implements OnInit {
  public loading = true

  public data: any[] = []

  constructor(
    public userCaregiverService: UserCaregiverService,
    private toastService: ToastService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    document.body.classList.remove('mobile-nav-visible')

    this.titleService.setTitle(`Preise`)

    this.loadData()
  }

  public loadData(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.userCaregiverService.loadPrices().subscribe((data: any[]) => {
      this.data = data

      if (withLoading) {
        this.loading = false
      }
    })
  }
}
