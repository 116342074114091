<form #form="ngForm" class="dialog-form">
  <div class="dialog-sidebar secondary-dialog-sidebar">
    <span class="content-sub-headline"
      >Einsätze des Mitarbeiters an diesem Tag</span
    >

    <small
      class="color-gray"
      *ngIf="!values.home_visit_date_string || !values.home_visit_from"
      >Bitte Datum und Mitarbeiter auswählen</small
    >

    <small
      class="color-gray"
      *ngIf="
        values.home_visit_date_string &&
        values.home_visit_from &&
        appointmentsForUser.length === 0
      "
      >Keine Einsätze vorhanden</small
    >

    <div class="p-field p-col-12" style="margin: 0">
      <small
        style="display: flex; flex-direction: column; margin: 0"
        *ngFor="let appointment of appointmentsForUser; let i = index"
      >
        {{ appointment.date }}
        {{ appointment.name }} ({{ appointment.type }})</small
      >
    </div>
  </div>

  <div class="dialog-form-body">
    <div
      class="p-field"
      *ngIf="!config.data.isNew && values.type === 'patient'"
    >
      <a
        style="color: currentColor; border-bottom: 1px dashed"
        href="/patients/{{ config.data.patient_id }}"
        target="_blank"
      >
        <strong style="white-space: pre-wrap">{{ config.data.title }}</strong>
      </a>
    </div>

    <div class="p-field" *ngIf="config.data.isNew">
      <div class="p-formgroup-inline">
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Patient"
            name="type"
            [(ngModel)]="values.type"
            value="patient"
          ></p-radioButton>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Sonstiges"
            name="type"
            [(ngModel)]="values.type"
            value="other"
          ></p-radioButton>
        </div>
      </div>
    </div>

    <div class="p-field" *ngIf="values.type === 'other'">
      <label for="title">Titel *</label>
      <input
        id="title"
        name="title"
        type="text"
        [(ngModel)]="values.title"
        pInputText
        #title="ngModel"
        [required]="true"
        [class.p-invalid]="title.invalid && (title.dirty || title.touched)"
      />
      <div
        *ngIf="title.invalid && (title.dirty || title.touched)"
        class="p-invalid"
      >
        <div *ngIf="title.errors?.required">
          <small>Bitte geben Sie einen Titel ein</small>
        </div>
      </div>
    </div>

    <div class="p-field" *ngIf="config.data.isNew && values.type === 'patient'">
      <label for="patient">Patient * </label>
      <p-autoComplete
        field="full_name"
        inputId="patient"
        name="patient"
        appendTo="body"
        [forceSelection]="true"
        [(ngModel)]="values.patient"
        [suggestions]="patients"
        (completeMethod)="searchPatients($event)"
        (onSelect)="patientSelected($event)"
        [required]="true"
        #patient="ngModel"
        [class.p-invalid]="
          patient.invalid && (patient.dirty || patient.touched)
        "
      >
        <ng-template let-patient pTemplate="item">
          <div class="search-result-data">
            <span class="search-result-name">
              <b>{{ patient.full_name }}</b>
              <span class="search-result-id">#{{ patient.id }}</span>
              <i
                *ngIf="patient.status"
                class="p-tag p-tag-rounded p-tag-{{ patient.status }}"
                >{{ patient.status_string }}</i
              >
            </span>
            <span class="search-result-address">
              {{ patient.zipcode }} {{ patient.city }}
            </span>
          </div>
        </ng-template>
      </p-autoComplete>
      <div
        *ngIf="patient.invalid && (patient.dirty || patient.touched)"
        class="p-invalid"
      >
        <div *ngIf="patient.errors?.required">
          <small>Bitte wählen Sie einen Patienten aus</small>
        </div>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border" style="padding: 0">
      <div class="p-field p-col-8">
        <label for="qm-home-visit-date">Datum *</label>
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          [showIcon]="true"
          (onSelect)="loadUserAppointments()"
          (onBlur)="loadUserAppointments()"
          inputId="qm-home-visit-date"
          name="qm-home-visit-date"
          [showButtonBar]="true"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.home_visit_date_string"
          dataType="string"
          #date="ngModel"
          [required]="true"
          [class.p-invalid]="date.invalid && (date.dirty || date.touched)"
        ></p-calendar>
      </div>

      <div class="p-field p-col-4">
        <label for="from_time">Uhrzeit *</label>
        <p-calendar
          (onBlur)="checkForTimes($event)"
          inputId="from_time"
          [showIcon]="true"
          icon="pi pi-clock"
          name="from_time"
          [timeOnly]="true"
          appendTo="body"
          [(ngModel)]="values.home_visit_time"
          dataType="string"
          [required]="true"
          [showButtonBar]="false"
          #from_time="ngModel"
          [class.p-invalid]="
            from_time.invalid && (from_time.dirty || from_time.touched)
          "
        ></p-calendar>
      </div>
    </div>

    <div class="p-field">
      <label for="home_visit_from">Mitarbeiter *</label>
      <p-dropdown
        name="home_visit_from"
        inputId="home_visit_from"
        placeholder="Bitte auswählen"
        optionLabel="full_name"
        optionValue="full_name"
        (onChange)="loadUserAppointments()"
        [(ngModel)]="values.home_visit_from"
        [options]="homeVisitFrom"
        [required]="true"
        #home_visit_from="ngModel"
        [class.p-invalid]="
          home_visit_from.invalid &&
          (home_visit_from.dirty || home_visit_from.touched)
        "
      ></p-dropdown>
    </div>

    <div class="p-field">
      <label for="comment">Kommentar</label>
      <textarea
        id="comment"
        name="comment"
        [rows]="2"
        [(ngModel)]="values.comment"
        pInputTextarea
      ></textarea>
    </div>

    <div class="p-field" *ngIf="values.type === 'patient'">
      <div class="p-formgroup-inline">
        <div class="p-field-checkbox">
          <p-checkbox
            label="E-Mail senden"
            name="send_email"
            [binary]="true"
            [(ngModel)]="values.send_email"
          ></p-checkbox>
        </div>
      </div>
    </div>

    <!-- Empfänger -->
    <div
      class="p-fluid p-formgrid p-grid without-border"
      style="padding: 0"
      *ngIf="values.send_email"
    >
      <div class="p-field p-col-6">
        <label for="receiver">Empfänger *</label>
        <p-dropdown
          inputId="receiver"
          name="receiver"
          [options]="receiverOptions"
          [(ngModel)]="values.receiver"
          appendTo="body"
          scrollHeight="400px"
          [required]="true"
          #receiver="ngModel"
          [class.p-invalid]="
            receiver.invalid && (receiver.dirty || receiver.touched)
          "
        >
          <ng-template let-receiver pTemplate="item">
            <div>{{ receiver.label }}</div>
            <span>{{ receiver.email }}</span>
          </ng-template>
        </p-dropdown>
      </div>

      <!-- CC an -->
      <div class="p-field p-col-6">
        <label for="cc">CC an</label>
        <p-multiSelect
          inputId="cc"
          placeholder="Optional"
          [options]="ccOptions"
          [filter]="false"
          [showHeader]="false"
          name="cc"
          appendTo="body"
          scrollHeight="400px"
          [(ngModel)]="values.cc"
        ></p-multiSelect>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i
      *ngIf="!config.data.isNew"
      class="pi pi-trash"
      [class.pi-trash]="!submittedDelete"
      [class.pi-spin]="submittedDelete"
      [class.pi-spinner]="submittedDelete"
      tooltipPosition="left"
      tooltipZIndex="20000"
      [pTooltip]="submittedDelete ? 'Wird gelöscht' : 'THC löschen'"
      (click)="remove($event)"
    ></i>

    <i *ngIf="config.data.isNew && !values.send_email"></i>

    <a
      *ngIf="!config.data.isNew && values.type === 'patient'"
      target="_blank"
      [href]="customerService.getThcQmPreviewLink(config.data.id)"
    >
      <button
        pButton
        label="Druckvorschau"
        type="button"
        icon="pi pi-print"
        iconPos="left"
        class="p-button p-button-outlined"
      ></button>
    </a>

    <a
      *ngIf="values.send_email"
      target="_blank"
      (click)="clickedOnPreview = true"
      [href]="customerService.getThcMailPreviewLink(values)"
    >
      <button
        pButton
        label="Mail Vorschau"
        type="button"
        icon="pi pi-eye"
        iconPos="left"
        class="p-button p-button-info"
      ></button>
    </a>

    <button
      pButton
      [label]="values.send_email ? 'Speichern und versenden' : 'Speichern'"
      type="submit"
      (click)="save()"
      [disabled]="submitted || (!clickedOnPreview && values.send_email)"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
<p-confirmPopup></p-confirmPopup>
