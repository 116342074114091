import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { Router } from '@angular/router'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { Subject, Subscription } from 'rxjs'
import { TodoService } from '../../../services/todo.service'
import { TodoModel } from '../../../models/todo/todo.model'
import { TodoFormDialogComponent } from '../../../components/dialogs/todo-form-dialog/todo-form-dialog.component'
import { ShowTodoDialogComponent } from '../../../components/dialogs/show-todo-dialog/show-todo-dialog.component'
import { switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-finished-todos',
  templateUrl: './finished-todos.component.html',
})
export class FinishedTodosComponent implements OnInit, OnDestroy {
  @ViewChild('dtFinished') table: ElementRef | any = null

  public globalFilterValueFinished = ''
  private eventBusSubscription: Subscription = new Subscription()

  public loading = false
  public todos: TodoModel[] = []

  public settings = {
    only_assigned: false,
  }
  public firstPage = 0

  private loadTrigger$ = new Subject<void>()
  private subscription: Subscription = new Subscription()

  constructor(
    public todoService: TodoService,
    private dialogService: DialogService,
    private router: Router,
    private eventbus: EventBusService
  ) {}

  ngOnInit(): void {
    this.activateDataLoading()

    this.loadTodos()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.subscription.unsubscribe()
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.TodoChanged:
            this.loadTodos(false)
        }
      }
    )
  }

  private activateDataLoading(): void {
    this.subscription.add(
      this.loadTrigger$
        .pipe(switchMap(() => this.todoService.loadFinished()))
        .subscribe((data: TodoModel[]) => {
          this.todos = data

          this.loading = false

          setTimeout(() => {
            if (this.table) {
              const totalItems = this.table._totalRecords

              this.globalFilterValueFinished =
                this.table.filters.global?.value ?? ''

              if (
                this.table._first > totalItems ||
                this.table._rows > totalItems
              ) {
                this.firstPage = 0
              }
            }
          })
        })
    )
  }

  public loadTodos(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.loadTrigger$.next()
  }

  public openShowTodoDialog(todo: TodoModel | null = null): void {
    this.dialogService.open(ShowTodoDialogComponent, {
      header: `Todo ansehen #${todo?.id}`,
      width: '820px',
      styleClass: 'dialog-container',
      data: {
        todo,
        todo_id: todo?.id,
        user_type_name: todo?.caregiver?.full_name || todo?.patient?.full_name,
      },
    })
  }

  public openTodoFormDialog(todo: TodoModel | null = null): void {
    this.dialogService.open(TodoFormDialogComponent, {
      header: todo ? 'Todo bearbeiten' : 'Neues Todo',
      width: '520px',
      styleClass: 'dialog-container',
      data: {
        isEdit: !!todo,
        todo,
        user_type_name: todo?.caregiver?.full_name || todo?.patient?.full_name,
      },
    })
  }
}
