<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>
      <i (click)="goToPreviousMonth()" class="pi pi-chevron-left"></i>

      <p-dropdown
        inputId="month"
        name="month"
        scrollHeight="500px"
        (onChange)="loadData(false)"
        [options]="monthOptions"
        [(ngModel)]="month"
        [style]="{ width: '150px', zIndex: '10' }"
      ></p-dropdown>

      <p-dropdown
        inputId="year"
        name="year"
        (onChange)="loadData(false)"
        [options]="yearOptions"
        [(ngModel)]="year"
        [style]="{ margin: ' 0 0 0 -1px' }"
      ></p-dropdown>

      <i
        (click)="goToNextMonth()"
        class="pi pi-chevron-right"
        style="margin: 0 10px 0 10px"
      ></i>
    </span>

    <div class="content-header-buttons">
      <button
        pButton
        [label]="checkIsActivated ? 'Prüfung beenden' : 'Prüfung aktivieren'"
        (click)="toggleCheckMode()"
        type="button"
        class="p-button-outlined"
        icon="pi pi-check-circle"
        iconPos="left"
      ></button>

      <button
        pButton
        (click)="generateDriveTimeDocument()"
        label="PDF generieren"
        type="button"
        class="p-button-outlined"
        [disabled]="pdfIsGenerating"
        [icon]="pdfIsGenerating ? 'pi pi-spin pi-spinner' : 'pi pi-file'"
        iconPos="left"
      ></button>

      <button
        pButton
        (click)="generateDriveTimeDocument(true)"
        label="PDF mit AU generieren"
        type="button"
        class="p-button-outlined"
        [disabled]="pdfIllIsGenerating"
        [icon]="pdfIllIsGenerating ? 'pi pi-spin pi-spinner' : 'pi pi-file'"
        iconPos="left"
      ></button>

      <div class="table-search-container" *ngIf="listData.length > 0">
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [value]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>

  <div
    class="content-body without-padding smaller-size-table table-no-overflow"
    [class.content-loading]="contentLoading"
  >
    <p-table
      #dt
      [value]="listData"
      [autoLayout]="true"
      [rowHover]="true"
      [globalFilterFields]="[
        'date',
        'data.real_from_h',
        'data.real_to_h',
        'data.patient.first_name',
        'data.patient.last_name',
        'address_full',
        'data.persplan_function_complete',
        'data.task',
      ]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 140px"></th>
          <th
            pTooltip="Terminsperre"
            style="width: 60px"
            pSortableColumn="data.patient.appointment_lock"
          >
            <i class="pi pi-lock"></i>
            <p-sortIcon field="appointment_lock"></p-sortIcon>
          </th>
          <th style="min-width: 140px" pSortableColumn="date">
            Datum <p-sortIcon field="date"></p-sortIcon>
          </th>
          <th style="min-width: 120px" pSortableColumn="data.real_from_h">
            Uhrzeit <p-sortIcon field="data.real_from_h"></p-sortIcon>
          </th>
          <th style="min-width: 110px" pSortableColumn="data.real_hours">
            Dauer <p-sortIcon field="data.real_hours"></p-sortIcon>
          </th>
          <th
            style="min-width: 140px"
            pSortableColumn="google_drive_time_seconds_for_pdf"
          >
            Fahrzeit
            <p-sortIcon field="google_drive_time_seconds_for_pdf"></p-sortIcon>
          </th>
          <th style="min-width: 140px">KM</th>
          <th
            style="min-width: 200px"
            pSortableColumn="data.patient.first_name"
          >
            Patient <p-sortIcon field="data.patient.first_name"></p-sortIcon>
          </th>
          <th style="min-width: 200px" pSortableColumn="address_full">
            Ort <p-sortIcon field="address_full"></p-sortIcon>
          </th>

          <!-- Felder wenn Prüfung nicht aktiv ist -->
          <th *ngIf="!checkIsActivated"></th>
          <th *ngIf="!checkIsActivated"></th>

          <!-- Felder wenn Prüfung aktiv ist -->
          <th style="width: 80px; text-align: center" *ngIf="checkIsActivated">
            Fahrzeit
          </th>
          <th style="width: 80px; text-align: center" *ngIf="checkIsActivated">
            Pause
          </th>
          <th style="width: 80px; text-align: center" *ngIf="checkIsActivated">
            Plausibel
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
        <tr
          [attr.data-persplan-budget]="data.data?.id"
          [class.background-hightlight-yellow]="data.type === 'TYPE_HOLIDAY'"
          [class.background-hightlight-orange]="data.data?.has_storno_options"
          [class.background-hightlight-blue]="data.type === 'TYPE_VACATION'"
          [class.background-hightlight-main2]="
            data.type === 'TYPE_FREE_TIME_REQUEST' ||
            data.type === 'TYPE_OTHER' ||
            data.type === 'TYPE_TRAINING'
          "
          [class.background-hightlight-paused]="
            data.type === 'TYPE_PLANNING_FOLLOWS'
          "
          [class.background-hightlight-alert]="data.type === 'TYPE_ILL'"
          [class.background-hightlight-gray]="
            data.is_weekend || data.type === 'TYPE_INTERN'
          "
          [class.background-hightlight-hover]="currentHover === data.date"
          [class.background-hightlight-blue]="activeRow === data.date"
          (mouseleave)="setHover('')"
          (mouseenter)="setHover(data.date)"
          (click)="setActiveRow(data.date)"
        >
          <td style="position: relative">
            <i class="table-current-date" *ngIf="currentDay === data.date"></i>

            <div style="display: flex; gap: 30px">
              <i
                (click)="openTripsDialog(data)"
                pTooltip="KM ansehen"
                *ngIf="
                  data.data &&
                  data.data.caregiver_appointment_trip?.comments.length > 0
                "
                class="pi pi-car color-gray caregiver-appointment-icon-container"
                style="font-size: 17px"
              >
                <p-badge
                  [value]="data.data.caregiver_appointment_trip.comments.length"
                ></p-badge>
              </i>

              <i
                (click)="openFeedbackDialog(data)"
                pTooltip="Feedback ansehen"
                *ngIf="
                  data.data &&
                  data.data.caregiver_appointment_feedback.length > 0
                "
                class="pi pi-envelope color-gray caregiver-appointment-icon-container"
                style="font-size: 17px"
              >
                <p-badge
                  [value]="data.data.caregiver_appointment_feedback.length"
                ></p-badge>
              </i>
            </div>

            <!-- Feiertag oder sonstige Termine -->
            <span
              style="display: flex; align-items: center"
              *ngIf="
                data.type === 'TYPE_HOLIDAY' ||
                data.type === 'TYPE_VACATION' ||
                data.type === 'TYPE_INTERN' ||
                data.type === 'TYPE_FREE_TIME_REQUEST' ||
                data.type === 'TYPE_OTHER' ||
                data.type === 'TYPE_PLANNING_FOLLOWS' ||
                data.type === 'TYPE_TRAINING' ||
                data.type === 'TYPE_ILL'
              "
              >{{ data.value }}
              <i
                style="font-size: 18px; margin: 0 0 0 5px"
                class="pi pi-info-circle"
                *ngIf="data.tooltip"
                [pTooltip]="data.tooltip"
              ></i
            ></span>

            <div
              style="color: #7a7c82; float: left"
              tooltipStyleClass="tooltip-wider"
              pTooltip="Fahrt nach Hause: {{
                data.google_drive_time_home_from_last_appointment
              }} ({{ data.google_drive_km_for_home }} KM)"
              *ngIf="data.check_is_last_appointment"
            >
              <i class="pi pi-home"></i>
              <i
                style="margin: 0 0 0 1px; font-size: 11px"
                class="pi pi-arrow-left"
              ></i>
            </div>

            <!-- Keine Planung -->
            <span style="display: flex; align-items: center" *ngIf="!data.type"
              >Keine Planung</span
            >

            <!-- Stornierung -->
            <span
              style="display: flex; align-items: center"
              *ngIf="data.type && data.data && data.data.has_storno_options"
            >
              Stornierung

              <i
                style="font-size: 18px; margin: 0 0 0 5px"
                class="pi pi-info-circle"
                [escape]="false"
                [pTooltip]="
                  data.data.storno_options_details +
                  '<br>' +
                  data.data.storno_details
                "
              ></i>
            </span>
          </td>
          <td>
            <i
              *ngIf="data.data?.patient?.appointment_lock"
              pTooltip="Terminsperre"
              class="pi pi-lock color-alert"
            ></i>
          </td>
          <!-- Datum -->
          <td style="vertical-align: center">
            {{ data.date }} ({{ data.day }})
            <span
              style="width: 100px"
              class="color-blue"
              *ngIf="data.data?.doctor_appointment"
              ><br />Arzttermin</span
            >

            <span
              style="width: 100px"
              class="color-orange"
              *ngIf="data.data?.is_last_appointment_of_month"
              ><br />Letzter Einsatz</span
            >

            <span
              style="width: 100px"
              class="color-blue"
              *ngIf="data.data?.keep_appointment"
              ><br />Termin beibehalten</span
            >
          </td>

          <!-- Uhrzeit wenn der Einsatz NICHT gelöscht wurde -->
          <td
            style="vertical-align: center; min-width: 120px"
            *ngIf="data.data && !data.data.deleted_at"
          >
            <span style="width: 100px" class="p-column-title">Uhrzeit</span>
            {{ data.data.real_from_h }} - {{ data.data.real_to_h }}
          </td>
          <!-- Uhrzeit wenn der Einsatz gelöscht wurde, aber keine Arbeitszeit angerechnet wurde -->
          <td
            style="vertical-align: center; min-width: 120px"
            *ngIf="
              data.data &&
              data.data.deleted_at &&
              !data.data.consider_working_time
            "
          >
            <span style="width: 100px" class="p-column-title">Uhrzeit</span>
            {{ data.data.real_from_h }} - {{ data.data.real_to_h }}
          </td>
          <!-- Uhrzeit wenn der Einsatz gelöscht wurde, ABER Arbeitszeit angerechnet wurde -->
          <td
            style="vertical-align: center; min-width: 120px"
            *ngIf="
              data.data &&
              data.data.deleted_at &&
              data.data.consider_working_time
            "
          >
            <span style="width: 100px" class="p-column-title">Uhrzeit</span>
            {{ data.data.consider_working_time_from }} -
            {{ data.data.consider_working_time_to }}
          </td>

          <!-- Uhrzeit für intern/fortbildung -->
          <td
            style="vertical-align: center"
            *ngIf="
              !data.data &&
              (data.type === 'TYPE_TRAINING' ||
                data.type === 'TYPE_INTERN' ||
                data.type === 'TYPE_PLANNING_FOLLOWS')
            "
          >
            {{ data.from_time }} - {{ data.to_time }}
          </td>
          <td
            *ngIf="
              !data.data &&
              data.type !== 'TYPE_TRAINING' &&
              data.type !== 'TYPE_INTERN' &&
              data.type !== 'TYPE_PLANNING_FOLLOWS'
            "
          ></td>

          <!-- Dauer für intern/fortbildung -->
          <td
            style="vertical-align: center"
            *ngIf="
              !data.data &&
              (data.type === 'TYPE_TRAINING' ||
                data.type === 'TYPE_INTERN' ||
                data.type === 'TYPE_PLANNING_FOLLOWS')
            "
          >
            {{ data.hours }} Std
          </td>

          <!-- Dauer -->
          <td
            style="vertical-align: center"
            *ngIf="
              data.data &&
              data.type !== 'TYPE_TRAINING' &&
              data.type !== 'TYPE_INTERN' &&
              data.type !== 'TYPE_PLANNING_FOLLOWS'
            "
          >
            <div style="display: flex; align-items: center">
              <!-- Die Dauer, wenn der Einsatz NICHT gelöscht ist -->
              <div *ngIf="!data.data.deleted_at">
                {{ data.data.real_hours_as_float }} Std
                <!-- Angepasste Dauer der BK selbst -->
                <span
                  class="color-blue"
                  tooltipStyleClass="tooltip-wider"
                  pTooltip="Geänderte Dauer durch BK"
                  *ngIf="
                    data.data.caregiver_appointment_change.time &&
                    data.data.caregiver_appointment_change.time != 0
                  "
                >
                  <br />
                  <span *ngIf="data.data.caregiver_appointment_change.time > 0">
                    +</span
                  >{{
                    data.data.caregiver_appointment_change.time
                      .toString()
                      .replace(".", ",")
                  }}
                  Std</span
                >
              </div>
              <!-- Die Dauer, wenn der Einsatz gelöscht ist UND Arbeitszeit angerechnet wird -->
              <div
                *ngIf="data.data.deleted_at && data.data.consider_working_time"
              >
                {{ data.data.consider_working_time_hours_as_float }} Std
              </div>

              <!-- Die Dauer, wenn der Einsatz gelöscht ist UND KEINE Arbeitszeit angerechnet wird -->
              <div
                *ngIf="data.data.deleted_at && !data.data.consider_working_time"
              >
                0 Std
              </div>

              <i
                (click)="openChangeFeedbackDialog(data, 'time')"
                pTooltip="Kommentar"
                class="pi pi-envelope color-gray caregiver-appointment-icon-container"
                style="font-size: 17px; margin: 0 0 0 10px"
                *ngIf="data.data.caregiver_appointment_change.time_feedback"
              >
                <p-badge value="1"></p-badge>
              </i>
            </div>
          </td>
          <td
            *ngIf="
              !data.data &&
              data.type !== 'TYPE_TRAINING' &&
              data.type !== 'TYPE_INTERN'
            "
          ></td>
          <!-- Fahrzeit -->
          <td style="vertical-align: center" *ngIf="data.show_drive_time">
            <div style="display: flex; align-items: center">
              <div>
                <span
                  pTooltip="Fahrzeit von Google"
                  *ngIf="data.calculate_google_drive_time_for_pdf"
                  >{{ data.google_drive_time_formatted_for_pdf }}</span
                >
                <!-- Fahrzeit angepasst durch BK selbst -->
                <span
                  class="color-blue"
                  tooltipStyleClass="tooltip-wider"
                  pTooltip="Anpassung Fahrzeit durch BK"
                  *ngIf="data.data?.caregiver_appointment_change.drive_time"
                >
                  <br />
                  <span
                    *ngIf="
                      data.data.caregiver_appointment_change.drive_time > 0
                    "
                  >
                    +</span
                  >{{
                    data.data.caregiver_appointment_change.drive_time
                  }}
                  min</span
                >
              </div>

              <i
                (click)="openChangeFeedbackDialog(data, 'drive_time')"
                pTooltip="Kommentar"
                class="pi pi-envelope color-gray caregiver-appointment-icon-container"
                style="font-size: 17px; margin: 0 0 0 10px"
                *ngIf="
                  data.data?.caregiver_appointment_change.drive_time_feedback
                "
              >
                <p-badge value="1"></p-badge>
              </i>
            </div>
          </td>
          <td *ngIf="!data.show_drive_time"></td>

          <!-- KM -->
          <td style="vertical-align: center" *ngIf="data.show_drive_time_km">
            <div class="color-gray" style="display: flex; align-items: center">
              <div>
                <span *ngIf="data.google_drive_km"
                  >{{ data.google_drive_km }} KM</span
                >

                <span
                  pTooltip="Fahrt nach Hause"
                  *ngIf="data.check_is_last_appointment"
                >
                  <br />
                  {{ data.google_drive_km_for_home }} KM
                  <i
                    style="margin: 0 0 0 1px; font-size: 11px"
                    class="pi pi-arrow-left"
                  ></i>
                </span>
              </div>
            </div>
          </td>
          <td *ngIf="!data.show_drive_time_km"></td>

          <!-- Patient -->
          <td style="vertical-align: center" *ngIf="data.data">
            <div style="display: flex; flex-direction: column">
              <a routerLink="/patients/{{ data.data.patient_id }}"
                ><span class="table-data-highlight"
                  >{{ data.data.patient.first_name }}
                  {{ data.data.patient.last_name }}</span
                ></a
              >
              <small class="color-gray">{{
                data.data.persplan_function_complete
              }}</small>
            </div>
          </td>
          <td *ngIf="!data.data"></td>
          <!-- Ort -->
          <td style="vertical-align: center" *ngIf="data.data">
            {{ data.address_full }}
          </td>
          <td
            *ngIf="
              !data.data &&
              data.type !== 'TYPE_TRAINING' &&
              data.type !== 'TYPE_INTERN'
            "
          ></td>
          <!-- Ort für Intern/Fortbildung NICHT online -->
          <td
            style="vertical-align: center"
            *ngIf="
              !data.data &&
              (data.type === 'TYPE_TRAINING' || data.type === 'TYPE_INTERN') &&
              data.address_type !== 'ONLINE'
            "
          >
            {{ data.address_full }}
          </td>
          <!-- Ort für Intern/Fortbildung IST online -->
          <td
            style="vertical-align: center"
            *ngIf="
              !data.data &&
              (data.type === 'TYPE_TRAINING' || data.type === 'TYPE_INTERN') &&
              data.address_type === 'ONLINE'
            "
          >
            Online
          </td>

          <!-- Details -->
          <td class="table-action" *ngIf="data.data && !checkIsActivated">
            <i
              class="pi pi-info-circle"
              tooltipPosition="left"
              tooltipStyleClass="tooltip-wider tooltip-wider-light"
              [pTooltip]="data.details"
            ></i>
          </td>
          <td *ngIf="!data.data && !checkIsActivated"></td>

          <!-- Freigabe der Betreuungskraft -->
          <td *ngIf="data.data && !checkIsActivated">
            <i
              *ngIf="data.data.caregiver_appointment_release"
              class="pi pi-check-circle"
              [class.color-main1]="
                !data.data.caregiver_appointment_release.from_admin
              "
              [class.color-blue]="
                data.data.caregiver_appointment_release.from_admin
              "
              tooltipPosition="left"
              [pTooltip]="
                data.data.caregiver_appointment_release.from_admin
                  ? 'Durch Admin freigegeben'
                  : 'Durch BK freigegeben'
              "
            ></i>
          </td>
          <td *ngIf="!data.data && !checkIsActivated"></td>

          <!-- Fahrzeiten -->
          <td
            class="overview-list-check"
            *ngIf="data.checks && checkIsActivated"
          >
            <i
              (click)="
                openCheckCommentDialog(
                  data,
                  listData[rowIndex + 1],
                  data.checks.drive_time_comments,
                  'DRIVE_TIME'
                )
              "
              *ngIf="data.checks.drive_time"
              class="pi pi-check-circle color-main1"
              pTooltip="Fahrzeit möglich"
              tooltipPosition="left"
            >
              <p-badge
                *ngIf="data.checks.drive_time_comments.length > 0"
                [value]="data.checks.drive_time_comments.length"
              ></p-badge>
            </i>

            <i
              (click)="
                openCheckCommentDialog(
                  data,
                  listData[rowIndex + 1],
                  data.checks.drive_time_comments,
                  'DRIVE_TIME'
                )
              "
              *ngIf="!data.checks.drive_time"
              class="pi pi-times-circle color-alert"
              pTooltip="Fahrzeit zum nächsten Einsatz nicht möglich"
              tooltipPosition="left"
            >
              <p-badge
                *ngIf="data.checks.drive_time_comments.length > 0"
                [value]="data.checks.drive_time_comments.length"
              ></p-badge>
            </i>
          </td>
          <td *ngIf="!data.checks && checkIsActivated"></td>
          <!-- Pause -->
          <td
            class="overview-list-check"
            *ngIf="data.checks && checkIsActivated"
          >
            <!-- Wenn Pause für diesen Tag möglich ist, zeigen wir ein graues Häkchen an.
            Außer die Pause ist explizit an diesem Einsatz möglich, dann zeigen wir ein grünes an. -->
            <i
              (click)="
                openCheckCommentDialog(
                  data,
                  listData[rowIndex + 1],
                  data.checks.pause_time_comments,
                  'PAUSE_TIME'
                )
              "
              *ngIf="
                data.pause_is_possible_for_this_day && data.checks.pause_time
              "
              pTooltip="Pause möglich"
              tooltipPosition="left"
              class="pi pi-check-circle color-main1"
            >
              <p-badge
                *ngIf="data.checks.pause_time_comments.length > 0"
                [value]="data.checks.pause_time_comments.length"
              ></p-badge>
            </i>

            <!-- Wenn Pause für diesen Tag möglich ist, zeigen wir ein graues Häkchen an.  -->
            <i
              (click)="
                openCheckCommentDialog(
                  data,
                  listData[rowIndex + 1],
                  data.checks.pause_time_comments,
                  'PAUSE_TIME'
                )
              "
              *ngIf="
                data.pause_is_possible_for_this_day && !data.checks.pause_time
              "
              pTooltip="Pause an diesem Tag möglich"
              tooltipPosition="left"
              class="pi pi-check-circle color-gray"
            >
              <p-badge
                *ngIf="data.checks.pause_time_comments.length > 0"
                [value]="data.checks.pause_time_comments.length"
              ></p-badge>
            </i>

            <!-- Falls garkeine Pause für den Tag möglich ist, dann zeigen wir ein rotes X. -->
            <i
              (click)="
                openCheckCommentDialog(
                  data,
                  listData[rowIndex + 1],
                  data.checks.pause_time_comments,
                  'PAUSE_TIME'
                )
              "
              *ngIf="!data.pause_is_possible_for_this_day"
              class="pi pi-times-circle color-alert"
              pTooltip="Keine Pause möglich"
              tooltipPosition="left"
            >
              <p-badge
                *ngIf="data.checks.pause_time_comments.length > 0"
                [value]="data.checks.pause_time_comments.length"
              ></p-badge>
            </i>
          </td>
          <td *ngIf="!data.checks && checkIsActivated"></td>
          <!-- Plausibel -->
          <td
            class="overview-list-check"
            *ngIf="data.checks && checkIsActivated"
          >
            <i
              (click)="
                openCheckCommentDialog(
                  data,
                  listData[rowIndex + 1],
                  data.checks.no_idle_comments,
                  'NO_IDLE'
                )
              "
              *ngIf="data.checks.no_idle"
              class="pi pi-check-circle color-main1"
              pTooltip="Kein Leerlauf vorhanden"
              tooltipPosition="left"
            >
              <p-badge
                *ngIf="data.checks.no_idle_comments.length > 0"
                [value]="data.checks.no_idle_comments.length"
              ></p-badge>
            </i>

            <i
              (click)="
                openCheckCommentDialog(
                  data,
                  listData[rowIndex + 1],
                  data.checks.no_idle_comments,
                  'NO_IDLE'
                )
              "
              *ngIf="!data.checks.no_idle"
              class="pi pi-times-circle color-alert"
              pTooltip="Leerlauf vorhanden"
              tooltipPosition="left"
            >
              <p-badge
                *ngIf="data.checks.no_idle_comments.length > 0"
                [value]="data.checks.no_idle_comments.length"
              ></p-badge>
            </i>
          </td>
          <td *ngIf="!data.checks && checkIsActivated"></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div class="table-footer">
          <div>
            <span class="table-footer-number"
              ><b>{{ hoursTotal }} Std</b></span
            >
            <span class="table-footer-info"
              ><b>Gesamt ({{ totalPlannings }} Schichten)</b></span
            >
          </div>
          <div>
            <span class="table-footer-number color-gray"
              >{{ hoursGoogle }} Std</span
            >
            <span class="table-footer-info color-gray"
              >Fahrzeiten von Google</span
            >
          </div>
          <div>
            <span class="table-footer-number color-gray"
              >{{ driveMetersFull }} KM</span
            >
            <span class="table-footer-info color-gray"
              >Gefahrene KM Gesamt</span
            >
          </div>
        </div>
      </ng-template>
    </p-table>
  </div>
</div>
