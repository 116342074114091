<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="detail-information-banner" *ngIf="hasCurrentCar">
        <span>Bitte ein Enddatum im vorherigen PKW eintragen!</span>
      </div>

      <div class="p-field p-col-12" *ngIf="data.from_type === 'caregiver'">
        <label for="car_id">PKW *</label>
        <p-dropdown
          inputId="car_id"
          name="car_id"
          placeholder="Auswählen"
          optionLabel="full_name"
          optionValue="id"
          [(ngModel)]="values.car_id"
          [options]="cars"
          [required]="true"
          [filter]="true"
          #car="ngModel"
          [class.p-invalid]="car.invalid && (car.dirty || car.touched)"
        ></p-dropdown>
      </div>

      <div class="p-field p-col-12" *ngIf="data.from_type === 'car'">
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Betreuungskraft"
              name="type"
              [(ngModel)]="values.assignable_type"
              value="App\Models\Caregiver"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Systembenutzer"
              name="type"
              [(ngModel)]="values.assignable_type"
              value="App\Models\UserSystem"
            ></p-radioButton>
          </div>
        </div>
      </div>

      <div
        class="p-field p-col-12"
        *ngIf="
          data.from_type === 'car' &&
          values.assignable_type === 'App\\Models\\Caregiver'
        "
      >
        <label for="caregiver">Betreuungskraft *</label>
        <p-dropdown
          inputId="caregiver"
          name="caregiver"
          placeholder="Auswählen"
          optionLabel="full_name"
          optionValue="id"
          [(ngModel)]="values.assignable_id"
          [options]="caregivers"
          [required]="true"
          [filter]="true"
          #caregiver="ngModel"
          [class.p-invalid]="
            caregiver.invalid && (caregiver.dirty || caregiver.touched)
          "
        ></p-dropdown>
      </div>

      <div
        class="p-field p-col-12"
        *ngIf="
          data.from_type === 'car' &&
          values.assignable_type === 'App\\Models\\UserSystem'
        "
      >
        <label for="user_system">Systembenutzer *</label>
        <p-dropdown
          inputId="user_system"
          name="user_system"
          placeholder="Auswählen"
          optionLabel="full_name"
          optionValue="id"
          [(ngModel)]="values.assignable_id"
          [options]="userSystemOptions"
          [required]="true"
          [filter]="true"
          #user_system="ngModel"
          [class.p-invalid]="
            user_system.invalid && (user_system.dirty || user_system.touched)
          "
        ></p-dropdown>
      </div>

      <div class="p-field p-col-6">
        <label for="since">seit *</label>
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="since"
          name="since"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.since"
          dataType="string"
          [required]="true"
          #from="ngModel"
          [showIcon]="true"
          [showButtonBar]="true"
          [class.p-invalid]="from.invalid && (from.dirty || from.touched)"
        ></p-calendar>
      </div>

      <div class="p-field p-col-6">
        <label for="until">bis</label>
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="until"
          name="until"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [showIcon]="true"
          [(ngModel)]="values.until"
          dataType="string"
          [showButtonBar]="true"
        ></p-calendar>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label for="mileage"
          >Kilometerstand {{ values.until ? "*" : "" }}</label
        >
        <p-inputNumber
          inputId="mileage"
          name="mileage"
          locale="de-DE"
          [(ngModel)]="values.mileage"
          [required]="!!values.until"
          #mileage="ngModel"
          [class.p-invalid]="
            mileage.invalid && (mileage.dirty || mileage.touched)
          "
        ></p-inputNumber>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label for="comment">Bemerkung</label>
        <textarea
          id="comment"
          name="comment"
          [(ngModel)]="values.comment"
          [rows]="3"
          pInputTextarea
        ></textarea>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer" *ngIf="!hasCurrentCar">
    <i *ngIf="!data.isEdit"></i>
    <i
      class="pi pi-trash"
      *ngIf="data.isEdit"
      [class.pi-trash]="!submittedDelete"
      [class.pi-spin]="submittedDelete"
      [class.pi-spinner]="submittedDelete"
      tooltipPosition="left"
      tooltipZIndex="20000"
      [pTooltip]="submittedDelete ? 'Wird gelöscht' : 'Verknüpfung löschen'"
      (click)="remove($event)"
    ></i>

    <button
      (click)="store()"
      pButton
      [label]="data.isEdit ? 'Speichern' : 'Hinzufügen'"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
