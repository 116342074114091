<app-loader [loading]="loading"></app-loader>

<div class="content-actions">
  <h2>
    <i class="tio">euro_outlined</i>
    Dokumente für Preisänderung
  </h2>
</div>

<div class="content new-content-container" *ngIf="!loading">
  <header class="content-header" *ngIf="data.length > 0">
    <span> Übergebene Dokumente </span>
  </header>

  <ng-container>
    <div class="content-body without-padding auto-height">
      <span class="content-no-message with-padding" *ngIf="data.length === 0">
        Keine Patienten vorhanden
      </span>

      <p-table
        [style]="{ minWidth: '800px' }"
        responsiveLayout="scroll"
        [value]="data"
        [rowHover]="true"
        *ngIf="data.length > 0"
      >
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <!--            <th style="width: 160px" pSortableColumn="status_string">-->
            <!--              Status-->
            <!--              <p-sortIcon field="status_string"></p-sortIcon>-->
            <!--            </th>-->
            <th pSortableColumn="document.patient.last_name">
              Name <p-sortIcon field="document.patient.last_name"></p-sortIcon>
            </th>
            <th pSortableColumn="document.patient.city">
              Wohnort <p-sortIcon field="document.patient.city"></p-sortIcon>
            </th>
            <!--            <th pSortableColumn="customer.created_at">-->
            <!--              Erstellt am-->
            <!--              <p-sortIcon field="customer.created_at"></p-sortIcon>-->
            <!--            </th>-->
            <!--            <th pSortableColumn="caregiver_paid_recommendation.created_at">-->
            <!--              <div style="display: flex; align-items: center; gap: 5px">-->
            <!--                <i style="font-size: 12px" class="pi pi-euro"></i>-->
            <!--                Bezahlt-->
            <!--                <p-sortIcon-->
            <!--                  field="caregiver_paid_recommendation.created_at"-->
            <!--                ></p-sortIcon>-->
            <!--              </div>-->
            <!--            </th>-->
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-document>
          <tr>
            <!--            <td>-->
            <!--              &lt;!&ndash; Wenn Quali noch offen ist, soll der Workflow Status angezeigt werden &ndash;&gt;-->
            <!--              <span-->
            <!--                *ngIf="!patient.status && !patient.is_paused"-->
            <!--                class="p-tag p-tag-rounded p-tag-{{ patient.workflow_status }}"-->
            <!--                >{{ patient.workflow_status_string }}</span-->
            <!--              >-->

            <!--              &lt;!&ndash; Falls der Patient pausiert ist &ndash;&gt;-->
            <!--              <span-->
            <!--                *ngIf="patient.is_paused"-->
            <!--                class="p-tag p-tag-rounded p-tag-PAUSED"-->
            <!--                >Pausiert</span-->
            <!--              >-->

            <!--              &lt;!&ndash; Ansonsten soll der richtige Status angezeigt werden &ndash;&gt;-->
            <!--              <span-->
            <!--                *ngIf="patient.status && !patient.is_paused"-->
            <!--                class="p-tag p-tag-rounded p-tag-{{ patient.status }}"-->
            <!--                >{{ patient.status_string }}-->
            <!--              </span>-->
            <!--            </td>-->
            <td>
              {{ document.patient.last_name }},
              {{ document.patient.first_name }}
            </td>
            <td>
              {{ document.patient.city || "-" }}
            </td>
            <!--            <td>-->
            <!--              {{ patient.customer.created_at | date: "dd.MM.y, H:mm" }}-->
            <!--            </td>-->
            <!--            <td>-->
            <!--              <span *ngIf="!patient.caregiver_paid_recommendation">-</span>-->

            <!--              <div-->
            <!--                class="text-center-container"-->
            <!--                *ngIf="patient.caregiver_paid_recommendation"-->
            <!--              >-->
            <!--                <i class="pi pi-check"></i>-->
            <!--                {{-->
            <!--                  patient.caregiver_paid_recommendation.paid_at-->
            <!--                    | date: "dd.MM.y, H:mm"-->
            <!--                }}-->
            <!--              </div>-->
            <!--            </td>-->
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-container>
</div>
