<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header without-border">
    <div class="content-header-patients-tabs">
      <a
        href="/patients/{{ customer.first_patient.id }}"
        [class.active]="selectedPatient === 'first_patient'"
      >
        <i
          pTooltip="Terminversand gesperrt"
          class="pi pi-lock color-alert"
          *ngIf="customer.first_patient.appointment_lock"
        ></i>
        <i
          class="light-symbol light-symbol-blue"
          *ngIf="customer.first_patient.from_campaign"
          pTooltip="Aus Kampagne"
        ></i>
        <i class="pi pi-user"></i>
        {{ customer.first_patient.full_name }}
        <i
          *ngIf="customer.first_patient.status"
          class="p-tag p-tag-rounded p-tag-{{ customer.first_patient.status }}"
          >{{ customer.first_patient.status_string }}</i
        >
        <i
          *ngIf="customer.first_patient.is_paused"
          class="p-tag p-tag-rounded p-tag-PAUSED"
          >Pausiert</i
        >
      </a>
      <a
        href="/patients/{{ customer.second_patient.id }}"
        [class.active]="selectedPatient === 'second_patient'"
        *ngIf="customer.second_patient"
      >
        <i
          pTooltip="Terminversand gesperrt"
          class="pi pi-lock color-alert"
          *ngIf="customer.second_patient.appointment_lock"
        ></i>
        <i class="pi pi-users"></i>
        {{ customer.second_patient.full_name }}

        <i
          *ngIf="customer.second_patient.status"
          class="p-tag p-tag-rounded p-tag-{{ customer.second_patient.status }}"
          >{{ customer.second_patient.status_string }}</i
        >
        <i
          *ngIf="customer.second_patient.is_paused"
          class="p-tag p-tag-rounded p-tag-PAUSED"
          >Pausiert</i
        >
      </a>
    </div>

    <i
      class="pflegeherzen-logo"
      *ngIf="customer[selectedPatient]?.is_pflegeherzen_customer"
      pTooltip="Kunde bei Pflegeherzen"
    ></i>

    <div class="content-header-text-action-container">
      <span
        class="content-header-text-action"
        [pTooltip]="customer[selectedPatient]?.appointment_type_tooltip || ''"
        (click)="openChangePatientAppointmentTypeDialog()"
      >
        Terminversand:
        {{
          customer[selectedPatient]?.appointment_types_string || "Keine Angabe"
        }}
        <i
          class="pi pi-info-circle"
          *ngIf="customer[selectedPatient]?.appointment_type_tooltip"
        ></i>
        <i class="pi pi-pencil"></i
      ></span>

      <span
        class="content-header-text-action"
        [pTooltip]="customer[selectedPatient].invoice_type_tooltip"
        (click)="openChangePatientInvoiceTypeDialog()"
      >
        Rechnungsversand:
        {{ customer[selectedPatient].invoice_types_string || "Keine Angabe" }}
        <i
          class="pi pi-info-circle"
          *ngIf="customer[selectedPatient].invoice_type_tooltip"
        ></i>
        <i class="pi pi-pencil"></i
      ></span>
    </div>

    <div class="setting-menu-container" #settingContainer>
      <button
        pButton
        label="Einstellungen"
        type="button"
        class="p-button-outlined"
        icon="pi pi-chevron-down"
        iconPos="right"
        (click)="menu.toggle($event)"
      ></button>
      <p-menu #menu [popup]="true" [model]="settingMenuItems"></p-menu>
    </div>
  </header>
</div>

<div class="content" *ngIf="!loading">
  <div
    class="content-body without-padding p-flex-row"
    *ngIf="customer[selectedPatient]"
  >
    <div class="detail-information-column border-right">
      <div class="detail-information-container">
        <div class="detail-information-header">Stammdaten</div>
        <div class="detail-information-body">
          <!-- Offene Rechnungen -->
          <a
            routerLink="/patients/{{ customer[selectedPatient].id }}/accounting"
            class="detail-information-banner clickable"
            *ngIf="customer[selectedPatient].opened_invoices.total > 0"
          >
            <span>
              {{ customer[selectedPatient].opened_invoices.total }} offene
              {{
                customer[selectedPatient].opened_invoices.total == 1
                  ? "Rechnung"
                  : "Rechnungen"
              }}
              vorhanden
              <i style="font-size: 11px" class="pi pi-arrow-right"></i>
            </span>
            <span style="font-weight: normal; padding-top: 0">
              <small
                *ngIf="customer[selectedPatient].opened_invoices.km_count > 0"
                >{{
                  customer[selectedPatient].opened_invoices.km_count
                }}
                KM-Rechnung</small
              >
              <small
                *ngIf="
                  customer[selectedPatient].opened_invoices.consulting_count > 0
                "
                >{{
                  customer[selectedPatient].opened_invoices.consulting_count
                }}
                BE-Rechnung</small
              >
              <small
                *ngIf="
                  customer[selectedPatient].opened_invoices.appointment_count >
                  0
                "
                >{{
                  customer[selectedPatient].opened_invoices.appointment_count
                }}
                Einsatz-Rechnung</small
              >
              <small
                *ngIf="
                  customer[selectedPatient].opened_invoices.returns_count > 0
                "
                >{{
                  customer[selectedPatient].opened_invoices.returns_count
                }}
                Rückläufer</small
              >
            </span>
          </a>

          <!-- Pausiert -->
          <div
            class="detail-information-banner clickable background-orange"
            *ngIf="customer[selectedPatient].pause_in_current_month_active"
            (click)="
              openPauseDialog(
                customer[selectedPatient].pause_in_current_month_active
              )
            "
          >
            <span
              >Pause von
              {{
                customer[selectedPatient].pause_in_current_month_active
                  ?.pause_from_string
              }}
              bis
              {{
                customer[selectedPatient].pause_in_current_month_active
                  ?.pause_to_string
              }}
              <i class="pi pi-pencil"></i>
            </span>
          </div>

          <p-overlayPanel
            #op
            styleClass="with-border"
            [showCloseIcon]="true"
            [style]="{
              maxWidth: '800px',
              width: 'max-content',
              maxHeight: '420px',
              overflowY: 'auto'
            }"
          >
            <ng-template pTemplate>
              <div [innerHTML]="currentBudgetTable"></div>
            </ng-template>
          </p-overlayPanel>

          <p-overlayPanel
            #opNearPatients
            styleClass="with-border"
            [showCloseIcon]="true"
            [style]="{
              maxWidth: '800px',
              width: 'max-content',
              maxHeight: '420px',
              overflowY: 'auto'
            }"
          >
            <ng-template pTemplate>
              <div *ngIf="nearPatients.length === 0">
                Keine Patienten in der Nähe
              </div>
              <div style="display: flex; padding: 0 0 5px 0">
                <div class="p-inputgroup" style="width: 100px">
                  <input
                    [(ngModel)]="area"
                    id="area"
                    name="area"
                    type="text"
                    pInputText
                    (blur)="openNearPatients(null, false)"
                  />
                  <span class="p-inputgroup-addon">KM</span>
                </div>
              </div>
              <p-table [value]="nearPatients" *ngIf="nearPatients.length > 0">
                <ng-template pTemplate="header">
                  <tr>
                    <th>Patient</th>
                    <th>Adresse</th>
                    <th
                      style="width: 100px"
                      pSortableColumn="distance_sortable"
                    >
                      Umkreis
                      <p-sortIcon field="distance_sortable"></p-sortIcon>
                    </th>
                    <th pSortableColumn="next_appointment.from_SOLL">
                      Nächster Einsatz
                      <p-sortIcon
                        field="next_appointment.from_SOLL"
                      ></p-sortIcon>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                  <tr>
                    <td>
                      <a
                        target="_blank"
                        routerLink="/patients/{{ item.patient_id }}"
                        >{{ item.patient }}</a
                      >
                    </td>
                    <td>
                      {{ item.street }}
                      <span class="table-small-info"
                        ><br />{{ item.city }}</span
                      >
                    </td>
                    <td>{{ item.distance }} KM</td>

                    <td *ngIf="!item.next_appointment">-</td>
                    <td *ngIf="item.next_appointment">
                      {{ item.next_appointment.from_SOLL | date: "dd.MM.y" }},
                      {{ item.next_appointment.from_SOLL_h }} -
                      {{ item.next_appointment.to_SOLL_h }} Uhr
                      <span class="table-small-info"
                        ><br />
                        <a
                          target="_blank"
                          routerLink="/caregivers/{{
                            item.next_appointment.caregiver.id
                          }}/overview"
                          >{{ item.next_appointment.caregiver.first_name }}
                          {{ item.next_appointment.caregiver.last_name }}</a
                        >
                      </span>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </ng-template>
          </p-overlayPanel>

          <div class="detail-information" style="align-items: center">
            <div class="detail-key">
              <span>Budgetplanung</span>
            </div>
            <div class="detail-value">
              <i
                *ngIf="customer[selectedPatient].budget_for_current_month === 3"
                class="p-tag p-tag-rounded p-tag-danger"
                pTooltip="Keine Persplan Planung"
                >Aktueller Monat</i
              >

              <i
                (click)="
                  openBudgetOverlay(
                    $event,
                    'SOLL vorhanden <br><br>' +
                      persplanData['current' + customer[selectedPatient].id]
                  )
                "
                *ngIf="customer[selectedPatient].budget_for_current_month === 2"
                class="p-tag p-tag-rounded p-tag-PAUSED p-tag-clickable"
                pTooltip="Planung ansehen"
                (mouseenter)="
                  getPersplanBudget(customer[selectedPatient].id, 'current')
                "
                >Aktueller Monat</i
              >

              <i
                (click)="
                  openBudgetOverlay(
                    $event,
                    'Termine übergeben <br><br>' +
                      persplanData['current' + customer[selectedPatient].id]
                  )
                "
                *ngIf="customer[selectedPatient].budget_for_current_month === 1"
                class="p-tag p-tag-rounded p-tag-REGULAR p-tag-clickable"
                pTooltip="Termine ansehen"
                (mouseenter)="
                  getPersplanBudget(customer[selectedPatient].id, 'current')
                "
                >Aktueller Monat</i
              >

              <i
                *ngIf="customer[selectedPatient].budget_for_next_month === 3"
                class="p-tag p-tag-rounded p-tag-danger"
                style="margin: 0 0 0 10px"
                pTooltip="Keine Persplan Planung"
                >Nächster Monat</i
              >

              <i
                (click)="
                  openBudgetOverlay(
                    $event,
                    'SOLL vorhanden <br><br>' +
                      persplanData['next' + customer[selectedPatient].id]
                  )
                "
                *ngIf="customer[selectedPatient].budget_for_next_month === 2"
                class="p-tag p-tag-rounded p-tag-PAUSED p-tag-clickable"
                style="margin: 0 0 0 10px"
                pTooltip="Planung ansehen"
                (mouseenter)="
                  getPersplanBudget(customer[selectedPatient].id, 'next')
                "
                >Nächster Monat</i
              >

              <i
                (click)="
                  openBudgetOverlay(
                    $event,
                    'Termine übergeben <br><br>' +
                      persplanData['next' + customer[selectedPatient].id]
                  )
                "
                *ngIf="customer[selectedPatient].budget_for_next_month === 1"
                class="p-tag p-tag-rounded p-tag-REGULAR p-tag-clickable"
                style="margin: 0 0 0 10px"
                pTooltip="Termine ansehen"
                (mouseenter)="
                  getPersplanBudget(customer[selectedPatient].id, 'next')
                "
                >Nächster Monat</i
              >
            </div>
          </div>

          <div class="detail-information">
            <div
              class="detail-key"
              [class.alert-key]="customer[selectedPatient].vetos.length > 0"
            >
              <span>Veto</span>
            </div>
            <div
              class="detail-value veto-value"
              *ngIf="customer[selectedPatient].vetos.length > 0"
            >
              <strong *ngFor="let veto of customer[selectedPatient].vetos">
                <i
                  class="pi pi-info-circle"
                  [pTooltip]="veto.comment"
                  *ngIf="veto.comment"
                ></i>
                <a routerLink="/caregivers/{{ veto.caregiver_id }}">
                  {{ veto.caregiver.first_name }} {{ veto.caregiver.last_name }}
                </a>
              </strong>
            </div>

            <div
              class="detail-value"
              *ngIf="customer[selectedPatient].vetos.length === 0"
            >
              <strong>-</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Kundenbetreuer</span>
            </div>
            <div class="detail-value">
              <strong>{{ customer.customer.customer_service || "-" }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Patienten ID / Kunden ID</span>
            </div>
            <div class="detail-value">
              <strong
                >{{ customer[selectedPatient].id }} /
                {{ customer.customer.id }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    persplan_id: customer[selectedPatient].persplan_id
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    persplan_id: customer[selectedPatient].persplan_id
                  })
                "
                [pTooltip]="latestChanges.persplan_id"
                >Persplan ID</span
              >
            </div>
            <div class="detail-value">
              <strong>{{
                customer[selectedPatient].persplan_id || "-"
              }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    hours_per_month: customer[selectedPatient].hours_per_month
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    hours_per_month: customer[selectedPatient].hours_per_month
                  })
                "
                [pTooltip]="latestChanges.hours_per_month"
                >Stunden pro Monat</span
              >
            </div>
            <div class="detail-value">
              <strong>{{
                customer[selectedPatient].hours_per_month || "-"
              }}</strong>
            </div>
          </div>

          <!-- Sobald der Patient mindestens Neukunde ist, werden die Stunden aus Persplan genommen (SOLL oder IST) -->
          <div
            class="detail-information"
            style="align-items: center"
            *ngIf="customer[this.selectedPatient].status"
          >
            <div class="detail-key">
              <span>Stunden</span>
            </div>
            <div class="detail-value">
              <i
                (click)="
                  openBudgetOverlay(
                    $event,
                    persplanData['last' + customer[selectedPatient].id]
                  )
                "
                class="p-tag p-tag-rounded p-tag-outline p-tag-clickable"
                pTooltip="Stunden ansehen"
                (mouseenter)="
                  getPersplanBudget(customer[selectedPatient].id, 'last')
                "
                style="margin: 0 10px 0 0"
                >Letzter:
                {{
                  customer[selectedPatient].persplan_hours_real_for_last_month
                }}</i
              >
              <i
                (click)="
                  openBudgetOverlay(
                    $event,
                    persplanData['current' + customer[selectedPatient].id]
                  )
                "
                class="p-tag p-tag-rounded p-tag-outline p-tag-clickable"
                style="margin: 0 10px 0 0"
                pTooltip="Stunden ansehen"
                (mouseenter)="
                  getPersplanBudget(customer[selectedPatient].id, 'current')
                "
                >Aktueller:
                {{
                  customer[selectedPatient]
                    .persplan_hours_real_for_current_month
                }}</i
              >
              <i
                (click)="
                  openBudgetOverlay(
                    $event,
                    persplanData['next' + customer[selectedPatient].id]
                  )
                "
                class="p-tag p-tag-rounded p-tag-outline p-tag-clickable"
                pTooltip="Stunden ansehen"
                (mouseenter)="
                  getPersplanBudget(customer[selectedPatient].id, 'next')
                "
                >Nächster:
                {{
                  customer[selectedPatient].persplan_hours_real_for_next_month
                }}</i
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer.customer.id, 'customer', {
                    start_of_operation: customer.customer.start_of_operation,
                    start_of_operation_date_string:
                      customer.customer.start_of_operation_date_string
                  })
                "
                (click)="
                  openDiffViewHistory(customer.customer.id, 'customer', {
                    start_of_operation: customer.customer.start_of_operation,
                    start_of_operation_date_string:
                      customer.customer.start_of_operation_date_string
                  })
                "
                [pTooltip]="
                  latestChanges.start_of_operation ||
                  latestChanges.start_of_operation_date_string
                "
                >Leistungsbeginn</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ customer.customer.start_of_operation || "-" }}
                {{
                  customer.customer.start_of_operation_date_string
                    ? "(" +
                      customer.customer.start_of_operation_date_string +
                      ")"
                    : ""
                }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer.customer.id, 'customer', {
                    service_scope: customer.customer.service_scope
                  })
                "
                (click)="
                  openDiffViewHistory(customer.customer.id, 'customer', {
                    service_scope: customer.customer.service_scope
                  })
                "
                [pTooltip]="latestChanges.service_scope"
                >Leistungsumfang</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ customer.customer.service_scope || "-" }}</strong>
            </div>
          </div>

          <div class="detail-information background-hightlight-main1">
            <div class="detail-key">
              <span>Leistungstage</span>
            </div>
            <div class="detail-value p-flex-column">
              <strong *ngIf="customer[selectedPatient].appointment_interval">
                Intervall: {{ customer[selectedPatient].appointment_interval
                }}<br />
              </strong>
              <strong>{{
                customer[selectedPatient].times?.days_as_string || "-"
              }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    form_of_address: customer[selectedPatient].form_of_address
                  })
                "
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    form_of_address: customer[selectedPatient].form_of_address
                  })
                "
                [pTooltip]="latestChanges.form_of_address"
                >Anrede</span
              >
            </div>
            <div class="detail-value">
              <strong>{{
                customer[selectedPatient].form_of_address || "-"
              }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    first_name: customer[selectedPatient].first_name
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    first_name: customer[selectedPatient].first_name
                  })
                "
                [pTooltip]="latestChanges.first_name"
                >Vorname</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ customer[selectedPatient].first_name }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    last_name: customer[selectedPatient].last_name
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    last_name: customer[selectedPatient].last_name
                  })
                "
                [pTooltip]="latestChanges.last_name"
                >Nachname</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ customer[selectedPatient].last_name }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    birthday_date_string:
                      customer[selectedPatient].birthday_date_string
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    birthday_date_string:
                      customer[selectedPatient].birthday_date_string
                  })
                "
                [pTooltip]="latestChanges.birthday_date_string"
                >Geburtstag</span
              >
            </div>
            <div class="detail-value">
              <strong>{{
                customer[selectedPatient].birthday_date_string || "-"
              }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    street_house_number:
                      customer[selectedPatient].street_house_number,
                    zipcode: customer[selectedPatient].zipcode,
                    city: customer[selectedPatient].city,
                    city_part: customer[selectedPatient].city_part
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    street_house_number:
                      customer[selectedPatient].street_house_number,
                    zipcode: customer[selectedPatient].zipcode,
                    city: customer[selectedPatient].city,
                    city_part: customer[selectedPatient].city_part
                  })
                "
                [pTooltip]="
                  latestChanges.street_house_number ||
                  latestChanges.zipcode ||
                  latestChanges.city ||
                  latestChanges.city_part
                "
                >Adresse</span
              >
            </div>
            <div class="detail-value detail-value-with-actions">
              <strong
                >{{ customer[selectedPatient].street_house_number || "-"
                }}<br />{{ customer[selectedPatient].zipcode }}
                {{ customer[selectedPatient].city }}
                {{ customer[selectedPatient].city_part }}</strong
              >

              <div>
                <i
                  (click)="copyAddressToClipboard()"
                  class="pi"
                  [class.pi-check]="addressCopied"
                  [class.pi-file]="!addressCopied"
                  pTooltip="Adresse kopieren"
                ></i>

                <i
                  *ngIf="
                    customer.customer.lng_location &&
                    customer.customer.lat_location
                  "
                  (click)="openNearPatients($event)"
                  pTooltip="Patienten in der Nähe"
                  class="pi"
                  [class.pi-map-marker]="!nearPatientsLoading"
                  [class.pi-spin]="nearPatientsLoading"
                  [class.pi-spinner]="nearPatientsLoading"
                ></i>
              </div>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    county: customer[selectedPatient].county,
                    state: customer[selectedPatient].state
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    county: customer[selectedPatient].county,
                    state: customer[selectedPatient].state
                  })
                "
                [pTooltip]="latestChanges.county || latestChanges.state"
                >Landkreis / Bundesland</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ customer[selectedPatient].county || "-" }} /
                {{ customer[selectedPatient].state || "-" }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    local_specialties:
                      customer[selectedPatient].local_specialties
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    local_specialties:
                      customer[selectedPatient].local_specialties
                  })
                "
                [pTooltip]="latestChanges.local_specialties"
                >Örtliche Besonderheiten</span
              >
            </div>
            <div class="detail-value">
              <strong>{{
                customer[selectedPatient].local_specialties || "-"
              }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    parking_possible:
                      customer[selectedPatient].parking_possible,
                    parking_possible_info:
                      customer[selectedPatient].parking_possible_info
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    parking_possible:
                      customer[selectedPatient].parking_possible,
                    parking_possible_info:
                      customer[selectedPatient].parking_possible_info
                  })
                "
                [pTooltip]="
                  latestChanges.parking_possible ||
                  latestChanges.parking_possible_info
                "
                >Parken problemlos möglich?</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ customer[selectedPatient].parking_possible || "-" }}
                {{
                  customer[selectedPatient].parking_possible_info
                    ? "(" +
                      customer[selectedPatient].parking_possible_info +
                      ")"
                    : ""
                }}
              </strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    weight: customer[selectedPatient].weight,
                    size: customer[selectedPatient].size
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    weight: customer[selectedPatient].weight,
                    size: customer[selectedPatient].size
                  })
                "
                [pTooltip]="latestChanges.weight || latestChanges.size"
                >Gewicht / Größe</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{
                  customer[selectedPatient].weight
                    ? customer[selectedPatient].weight + " kg"
                    : "-"
                }}
                /
                {{
                  customer[selectedPatient].size
                    ? customer[selectedPatient].size + " cm"
                    : "-"
                }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    character: customer[selectedPatient].character,
                    no_character_text:
                      customer[selectedPatient].no_character_text
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    character: customer[selectedPatient].character,
                    no_character_text:
                      customer[selectedPatient].no_character_text
                  })
                "
                [pTooltip]="
                  latestChanges.character || latestChanges.no_character_text
                "
                >Charakter</span
              >
            </div>
            <div class="detail-value">
              <strong *ngIf="customer[selectedPatient].character">{{
                customer[selectedPatient].character
              }}</strong>
              <strong *ngIf="customer[selectedPatient].no_character_text"
                >Keine Angaben getätigt</strong
              >
              <strong
                *ngIf="
                  !customer[selectedPatient].no_character_text &&
                  !customer[selectedPatient].character
                "
                >-</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'qm', {
                    comment: customer.customer.qm.comment
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'qm', {
                    comment: customer.customer.qm.comment
                  })
                "
                [pTooltip]="latestChanges.comment"
                >QM Kommentar</span
              >
            </div>
            <div class="detail-value" style="white-space: pre-wrap">
              <strong>{{ customer.customer.qm.comment || "-" }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer.customer.id, 'customer', {
                    summary_customer: customer.customer.summary_customer
                  })
                "
                (click)="
                  openDiffViewHistory(customer.customer.id, 'customer', {
                    summary_customer: customer.customer.summary_customer
                  })
                "
                [pTooltip]="latestChanges.summary_customer"
                >Zusammenfassung Kunde</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ customer.customer.summary_customer || "-" }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer.customer.id, 'customer', {
                    comment_customer: customer.customer.comment_customer
                  })
                "
                (click)="
                  openDiffViewHistory(customer.customer.id, 'customer', {
                    comment_customer: customer.customer.comment_customer
                  })
                "
                [pTooltip]="latestChanges.comment_customer"
                >Kommentar Kunde</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ customer.customer.comment_customer || "-" }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    summary_intern: customer[selectedPatient].summary_intern
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    summary_intern: customer[selectedPatient].summary_intern
                  })
                "
                [pTooltip]="latestChanges.summary_intern"
                >Zusammenfassung Intern</span
              >
            </div>
            <div class="detail-value">
              <strong *ngIf="!customer[selectedPatient].summary_intern"
                >-</strong
              >
              <i
                class="pi pi-info-circle"
                [pTooltip]="customer[selectedPatient].summary_intern"
                *ngIf="customer[selectedPatient].summary_intern"
              ></i>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'qm', {
                    comment_new_customer_consultant:
                      customer.customer.qm.comment_new_customer_consultant
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'qm', {
                    comment_new_customer_consultant:
                      customer.customer.qm.comment_new_customer_consultant
                  })
                "
                [pTooltip]="latestChanges.comment_new_customer_consultant"
                >Kommentar für NK-Berater</span
              >
            </div>
            <div class="detail-value" style="white-space: pre-wrap">
              <strong>{{
                customer.customer.qm.comment_new_customer_consultant || "-"
              }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer.customer.id, 'customer', {
                    attention_from_string:
                      customer.customer.attention_from_string,
                    attention_from_info: customer.customer.attention_from_info
                  })
                "
                (click)="
                  openDiffViewHistory(customer.customer.id, 'customer', {
                    attention_from_string:
                      customer.customer.attention_from_string,
                    attention_from_info: customer.customer.attention_from_info
                  })
                "
                [pTooltip]="
                  latestChanges.attention_from_info ||
                  latestChanges.attention_from_string
                "
                >Aufmerksam durch</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ customer.customer.attention_from_string || "-" }}
                {{
                  customer.customer.attention_from_info
                    ? "(" + customer.customer.attention_from_info + ")"
                    : ""
                }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer.customer.id, 'customer', {
                    recommendation_type: customer.customer.recommendation_type,
                    recommendation_id: customer.customer.recommendation_id
                  })
                "
                (click)="
                  openDiffViewHistory(customer.customer.id, 'customer', {
                    recommendation_type: customer.customer.recommendation_type,
                    recommendation_id: customer.customer.recommendation_id
                  })
                "
                [pTooltip]="
                  latestChanges.recommendation_type ||
                  latestChanges.recommendation_id
                "
                >Empfehlung durch</span
              >
            </div>
            <div class="detail-value">
              <strong *ngIf="customer.customer.recommendation">{{
                customer.customer.recommendation.full_name
              }}</strong>
              <strong *ngIf="!customer.customer.recommendation">-</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer.customer.id, 'customer', {
                    summary_from_recommendation:
                      customer.customer.summary_from_recommendation
                  })
                "
                (click)="
                  openDiffViewHistory(customer.customer.id, 'customer', {
                    summary_from_recommendation:
                      customer.customer.summary_from_recommendation
                  })
                "
                [pTooltip]="latestChanges.summary_from_recommendation"
                >Zusammenfassung Empfehler</span
              >
            </div>
            <div class="detail-value">
              <strong *ngIf="!customer.customer.summary_from_recommendation"
                >-</strong
              >
              <i
                class="pi pi-info-circle"
                [pTooltip]="customer.customer.summary_from_recommendation"
                *ngIf="customer.customer.summary_from_recommendation"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="detail-information-column">
      <div class="detail-information-container">
        <div class="detail-information-header">Pflegedaten</div>
        <div class="detail-information-body">
          <div class="detail-information">
            <div class="detail-key">
              <span>Preis</span>
            </div>
            <div class="detail-value detail-value-with-actions">
              <div style="flex-direction: column; display: block">
                <strong *ngIf="customer[selectedPatient].current_price">
                  {{ customer[selectedPatient].current_price.price_formatted }}
                  (seit
                  {{ customer[selectedPatient].current_price.since_formatted
                  }}{{
                    customer[selectedPatient].current_price.until
                      ? " bis " +
                        customer[selectedPatient].current_price.until_formatted
                      : ""
                  }})
                </strong>

                <strong *ngIf="!customer[selectedPatient].current_price">
                  {{ currentSystemPrice }} (SYSTEMPREIS)
                </strong>

                <div style="display: flex; flex-direction: column; width: 100%">
                  <strong
                    class="color-gray"
                    *ngFor="let price of customer[selectedPatient].next_prices"
                  >
                    {{ price.price_formatted }} (ab {{ price.since_formatted
                    }}{{ price.until ? " bis " + price.until_formatted : "" }})
                  </strong>
                </div>
              </div>

              <div>
                <i
                  tooltipPosition="left"
                  (click)="openPriceHistoryDialog()"
                  class="pi pi-history"
                  pTooltip="Historie ansehen"
                ></i>
              </div>
            </div>
          </div>

          <!--          <div-->
          <!--            class="detail-information"-->
          <!--            [class.background-hightlight-alert]="-->
          <!--              !customer[selectedPatient]?.has_price_change2024-->
          <!--            "-->
          <!--          >-->
          <!--            <div class="detail-key">-->
          <!--              <span>Preisänderung 2024</span>-->
          <!--            </div>-->
          <!--            <div class="detail-value">-->
          <!--              <i-->
          <!--                *ngIf="customer[selectedPatient]?.has_price_change2024"-->
          <!--                class="pi pi-euro color-main1"-->
          <!--              ></i>-->
          <!--              <i-->
          <!--                *ngIf="!customer[selectedPatient]?.has_price_change2024"-->
          <!--                class="pi pi-euro color-alert"-->
          <!--              ></i>-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    budget_information:
                      customer[selectedPatient].budget_information
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    budget_information:
                      customer[selectedPatient].budget_information
                  })
                "
                [pTooltip]="latestChanges.budget_information"
                >Kommentar Budgetierung</span
              >
            </div>
            <div class="detail-value">
              <strong>{{
                customer[selectedPatient].budget_information || "-"
              }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    service_for_formatted:
                      customer[selectedPatient].service_for_formatted
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    service_for_formatted:
                      customer[selectedPatient].service_for_formatted
                  })
                "
                [pTooltip]="latestChanges.service_for_formatted"
                >Leistung als</span
              >
            </div>
            <div class="detail-value">
              <strong>{{
                customer[selectedPatient].service_for_formatted || "-"
              }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    insured_via_string:
                      customer[selectedPatient].insured_via_string,
                    insured_via_other_text:
                      customer[selectedPatient].insured_via_other_text
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    insured_via_string:
                      customer[selectedPatient].insured_via_string,
                    insured_via_other_text:
                      customer[selectedPatient].insured_via_other_text
                  })
                "
                [pTooltip]="
                  latestChanges.insured_via_string ||
                  latestChanges.insured_via_other_text
                "
                >Abrechnungsstatus</span
              >
            </div>
            <div class="detail-value p-flex-column">
              <strong
                >{{ customer[selectedPatient].insured_via_string || "-" }}
                {{
                  customer[selectedPatient].insured_via_other_text
                    ? "(" +
                      customer[selectedPatient].insured_via_other_text +
                      ")"
                    : ""
                }}</strong
              >
              <strong
                *ngIf="
                  customer[selectedPatient].insured_via.includes(
                    'Selbstzahler'
                  ) && customer[selectedPatient].selbstzahler_budget_type
                "
              >
                Selbstzahler
                {{ customer[selectedPatient].selbstzahler_budget_type }}
                {{
                  customer[selectedPatient].selbstzahler_budget_type === "Max"
                    ? customer[selectedPatient].selbstzahler_budget_max + " Std"
                    : ""
                }}</strong
              >

              <strong
                *ngIf="
                  customer[selectedPatient].insured_via.includes(
                    'Kostenträger'
                  ) && customer[selectedPatient].kostentraeger_budget
                "
              >
                Kostenträger
                {{ customer[selectedPatient].kostentraeger_budget }} Std /
                Woche, Gültig bis:
                {{
                  customer[selectedPatient].kostentraeger_valid_type === "Datum"
                    ? customer[selectedPatient]
                        .kostentraeger_valid_until_date_string
                    : "Unbegrenzt"
                }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>SEPA</span>
            </div>
            <div class="detail-value p-align-center">
              <i
                *ngIf="customer[selectedPatient].first_sepa"
                class="pi pi-check-circle color-main1"
                pTooltip="SEPA wurde hochgeladen"
              ></i>
              <i
                *ngIf="!customer[selectedPatient].first_sepa"
                class="pi pi-times color-gray"
                pTooltip="Keine SEPA hochgeladen"
              ></i>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>DLV</span>
            </div>
            <div class="detail-value p-align-center">
              <i
                *ngIf="customer[selectedPatient].first_dlv"
                class="pi pi-check-circle color-main1"
                pTooltip="DLV wurde hochgeladen"
              ></i>
              <i
                *ngIf="!customer[selectedPatient].first_dlv"
                class="pi pi-times color-gray"
                pTooltip="Kein DLV hochgeladen"
              ></i>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    insurer_number: customer[selectedPatient].insurer_number
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    insurer_number: customer[selectedPatient].insurer_number
                  })
                "
                [pTooltip]="latestChanges.insurer_number"
                >VS-Nummer</span
              >
            </div>
            <div class="detail-value">
              <strong>{{
                customer[selectedPatient].insurer_number || "-"
              }}</strong>
            </div>
          </div>

          <div
            class="detail-information"
            *ngIf="
              customer[selectedPatient].insured_via.includes('Kostenträger')
            "
          >
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    file_number: customer[selectedPatient].file_number
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    file_number: customer[selectedPatient].file_number
                  })
                "
                [pTooltip]="latestChanges.file_number"
                >Unser Aktenzeichen</span
              >
            </div>
            <div class="detail-value">
              <strong>{{
                customer[selectedPatient].file_number || "-"
              }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    care_insurance_pflege_ik:
                      customer[selectedPatient].care_insurance_pflege_ik
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    care_insurance_pflege_ik:
                      customer[selectedPatient].care_insurance_pflege_ik
                  })
                "
                [pTooltip]="latestChanges.care_insurance_pflege_ik"
                >IK-Nummer</span
              >
            </div>
            <div class="detail-value">
              <strong>{{
                customer[selectedPatient].care_insurance_pflege_ik || "-"
              }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    care_insurance_name:
                      customer[selectedPatient].care_insurance_name
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    care_insurance_name:
                      customer[selectedPatient].care_insurance_name
                  })
                "
                [pTooltip]="latestChanges.care_insurance_name"
                >Pflegekasse</span
              >
            </div>
            <div class="detail-value">
              <strong
                style="display: flex; align-items: center; gap: 5px"
                *ngIf="customer[selectedPatient].care_insurance_name"
              >
                <a
                  routerLink="/multipliers/{{
                    customer[selectedPatient].care_insurance.id
                  }}"
                >
                  {{ customer[selectedPatient].care_insurance_name }}
                </a>

                <i
                  class="pi pi-arrow-up-right color-gray"
                  style="font-size: 12px"
                ></i>
              </strong>

              <strong *ngIf="!customer[selectedPatient].care_insurance_name"
                >-</strong
              >
            </div>
          </div>

          <div
            class="detail-information"
            *ngIf="
              customer[selectedPatient].insured_via.includes('Kostenträger')
            "
          >
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    cost_unit_name: customer[selectedPatient].cost_unit_name
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    cost_unit_name: customer[selectedPatient].cost_unit_name
                  })
                "
                [pTooltip]="latestChanges.cost_unit_name"
                >Kostenträger</span
              >
            </div>
            <div class="detail-value">
              <strong
                style="display: flex; align-items: center; gap: 5px"
                *ngIf="customer[selectedPatient].cost_unit_name"
              >
                <a
                  routerLink="/multipliers/{{
                    customer[selectedPatient].cost_unit.id
                  }}"
                >
                  {{ customer[selectedPatient].cost_unit_name }}
                </a>

                <i
                  class="pi pi-arrow-up-right color-gray"
                  style="font-size: 12px"
                ></i>
              </strong>

              <strong *ngIf="!customer[selectedPatient].cost_unit_name"
                >-</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    nursing_person: customer[selectedPatient].nursing_person,
                    nursing_person_name:
                      customer[selectedPatient].nursing_person_name
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    nursing_person: customer[selectedPatient].nursing_person,
                    nursing_person_name:
                      customer[selectedPatient].nursing_person_name
                  })
                "
                [pTooltip]="
                  latestChanges.nursing_person ||
                  latestChanges.nursing_person_name
                "
                >Pflegeperson</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ customer[selectedPatient].nursing_person }}
                {{ customer[selectedPatient].nursing_person_name }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    degree_of_care: customer[selectedPatient].degree_of_care,
                    degree_of_care_since_string:
                      customer[selectedPatient].degree_of_care_since_string
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    degree_of_care: customer[selectedPatient].degree_of_care,
                    degree_of_care_since_string:
                      customer[selectedPatient].degree_of_care_since_string
                  })
                "
                [pTooltip]="
                  latestChanges.degree_of_care ||
                  latestChanges.degree_of_care_since_string
                "
                >Pflegegrad</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ customer[selectedPatient].degree_of_care || "-" }}
                {{
                  customer[selectedPatient].degree_of_care_since_string
                    ? "(" +
                      customer[selectedPatient].degree_of_care_since_string +
                      ")"
                    : ""
                }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    degree_of_care_old:
                      customer[selectedPatient].degree_of_care_old,
                    degree_of_care_old_since_string:
                      customer[selectedPatient].degree_of_care_old_since_string
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    degree_of_care_old:
                      customer[selectedPatient].degree_of_care_old,
                    degree_of_care_old_since_string:
                      customer[selectedPatient].degree_of_care_old_since_string
                  })
                "
                [pTooltip]="
                  latestChanges.degree_of_care_old ||
                  latestChanges.degree_of_care_old_since_string
                "
                >Alter Pflegegrad</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ customer[selectedPatient].degree_of_care_old || "-" }}
                {{
                  customer[selectedPatient].degree_of_care_old_since_string
                    ? "(" +
                      customer[selectedPatient]
                        .degree_of_care_old_since_string +
                      ")"
                    : ""
                }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    other_service_providers:
                      customer[selectedPatient].other_service_providers
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    other_service_providers:
                      customer[selectedPatient].other_service_providers
                  })
                "
                [pTooltip]="latestChanges.other_service_providers"
                >Andere Dienstleister</span
              >
            </div>
            <div class="detail-value">
              <strong>{{
                customer[selectedPatient].other_service_providers || "-"
              }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    other_service_provider_text:
                      customer[selectedPatient].other_service_provider_text
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    other_service_provider_text:
                      customer[selectedPatient].other_service_provider_text
                  })
                "
                [pTooltip]="latestChanges.other_service_provider_text"
                >Derzeitige / vergangene Dienstleistungen</span
              >
            </div>
            <div class="detail-value">
              <strong>{{
                customer[selectedPatient].other_service_provider_text || "-"
              }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    services_string: customer[selectedPatient].services_string,
                    services_text: customer[selectedPatient].services_text
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    services_string: customer[selectedPatient].services_string,
                    services_text: customer[selectedPatient].services_text
                  })
                "
                [pTooltip]="
                  latestChanges.services_string || latestChanges.services_text
                "
                >Leistungsart</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ customer[selectedPatient].services_string || "-" }}
                {{
                  customer[selectedPatient].services_text
                    ? "(" + customer[selectedPatient].services_text + ")"
                    : ""
                }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    infectious_diseases:
                      customer[selectedPatient].infectious_diseases,
                    infectious_diseases_info:
                      customer[selectedPatient].infectious_diseases_info
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    infectious_diseases:
                      customer[selectedPatient].infectious_diseases,
                    infectious_diseases_info:
                      customer[selectedPatient].infectious_diseases_info
                  })
                "
                [pTooltip]="
                  latestChanges.infectious_diseases ||
                  latestChanges.infectious_diseases_info
                "
                >Ansteckende Krankheiten</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ customer[selectedPatient].infectious_diseases }}
                <span *ngIf="customer[selectedPatient].infectious_diseases_info"
                  >({{
                    customer[selectedPatient].infectious_diseases_info
                  }})</span
                ></strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    blood_thinner: customer[selectedPatient].blood_thinner,
                    blood_thinner_info:
                      customer[selectedPatient].blood_thinner_info
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    blood_thinner: customer[selectedPatient].blood_thinner,
                    blood_thinner_info:
                      customer[selectedPatient].blood_thinner_info
                  })
                "
                [pTooltip]="
                  latestChanges.blood_thinner ||
                  latestChanges.blood_thinner_info
                "
                >Blutverdünner</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ customer[selectedPatient].blood_thinner }}
                <span *ngIf="customer[selectedPatient].blood_thinner_info"
                  >({{ customer[selectedPatient].blood_thinner_info }})</span
                ></strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    diseases_string: customer[selectedPatient].diseases_string,
                    diseases_text: customer[selectedPatient].diseases_text,
                    stroke_left: customer[selectedPatient].stroke_left,
                    stroke_right: customer[selectedPatient].stroke_right
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    diseases_string: customer[selectedPatient].diseases_string,
                    diseases_text: customer[selectedPatient].diseases_text,
                    stroke_left: customer[selectedPatient].stroke_left,
                    stroke_right: customer[selectedPatient].stroke_right
                  })
                "
                [pTooltip]="
                  latestChanges.diseases_string ||
                  latestChanges.diseases_text ||
                  latestChanges.stroke_left ||
                  latestChanges.stroke_right
                "
                >Krankheitsmerkmale</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ customer[selectedPatient].diseases_string || "-" }}
                {{
                  customer[selectedPatient].diseases_text
                    ? "(" + customer[selectedPatient].diseases_text + ")"
                    : ""
                }}
                {{
                  customer[selectedPatient].stroke_left
                    ? "Schlaganfall links"
                    : ""
                }}
                {{
                  customer[selectedPatient].stroke_right
                    ? "Schlaganfall rechts"
                    : ""
                }}
              </strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    tools_string: customer[selectedPatient].tools_string,
                    tools_text: customer[selectedPatient].tools_text
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    tools_string: customer[selectedPatient].tools_string,
                    tools_text: customer[selectedPatient].tools_text
                  })
                "
                [pTooltip]="
                  latestChanges.tools_string || latestChanges.tools_text
                "
                >Hilfsmittel / Mobilität</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ customer[selectedPatient].tools_string || "-" }}
                {{
                  customer[selectedPatient].tools_text
                    ? "(" + customer[selectedPatient].tools_text + ")"
                    : ""
                }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    transfer_needed_general:
                      customer[selectedPatient].transfer_needed_general,
                    transfer_needed_general_info:
                      customer[selectedPatient].transfer_needed_general_info
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    transfer_needed_general:
                      customer[selectedPatient].transfer_needed_general,
                    transfer_needed_general_info:
                      customer[selectedPatient].transfer_needed_general_info
                  })
                "
                [pTooltip]="
                  latestChanges.transfer_needed_general ||
                  latestChanges.transfer_needed_general_info
                "
                >Transfer notwendig?</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ customer[selectedPatient].transfer_needed_general || "-" }}
                {{
                  customer[selectedPatient].transfer_needed_general_info
                    ? "(" +
                      customer[selectedPatient].transfer_needed_general_info +
                      ")"
                    : ""
                }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    no_incontinence: customer[selectedPatient].no_incontinence,
                    incontinence_urine:
                      customer[selectedPatient].incontinence_urine,
                    incontinence_stool:
                      customer[selectedPatient].incontinence_stool,
                    incontinence_text:
                      customer[selectedPatient].incontinence_text
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    no_incontinence: customer[selectedPatient].no_incontinence,
                    incontinence_urine:
                      customer[selectedPatient].incontinence_urine,
                    incontinence_stool:
                      customer[selectedPatient].incontinence_stool,
                    incontinence_text:
                      customer[selectedPatient].incontinence_text
                  })
                "
                [pTooltip]="
                  latestChanges.no_incontinence ||
                  latestChanges.incontinence_urine ||
                  latestChanges.incontinence_stool ||
                  latestChanges.incontinence_text
                "
                >Inkontinenz</span
              >
            </div>
            <div class="detail-value">
              <strong *ngIf="customer[selectedPatient].no_incontinence"
                >Keine Inkontinenz</strong
              >
              <strong *ngIf="!customer[selectedPatient].no_incontinence">
                Urin: {{ customer[selectedPatient].incontinence_urine || "-" }}
                <br />
                Stuhl: {{ customer[selectedPatient].incontinence_stool || "-" }}
                <br />
                Erläuterung:
                {{ customer[selectedPatient].incontinence_text || "-" }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    communication_complete_maintained:
                      customer[selectedPatient]
                        .communication_complete_maintained,
                    communication_types_string:
                      customer[selectedPatient].communication_types_string,
                    communication_text:
                      customer[selectedPatient].communication_text
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    communication_complete_maintained:
                      customer[selectedPatient]
                        .communication_complete_maintained,
                    communication_types_string:
                      customer[selectedPatient].communication_types_string,
                    communication_text:
                      customer[selectedPatient].communication_text
                  })
                "
                [pTooltip]="
                  latestChanges.communication_text ||
                  latestChanges.communication_types_string ||
                  latestChanges.communication_complete_maintained
                "
                >Kommunikationsfähigkeit</span
              >
            </div>
            <div class="detail-value">
              <strong
                *ngIf="
                  customer[selectedPatient]
                    .communication_complete_maintained === 'Ja'
                "
                >Vollständig erhalten</strong
              >
              <strong
                *ngIf="
                  customer[selectedPatient]
                    .communication_complete_maintained !== 'Ja'
                "
                >Eingeschränkt:
                {{ customer[selectedPatient].communication_types_string }}
                <br />
                Erläuterung: {{ customer[selectedPatient].communication_text }}
              </strong>
            </div>
          </div>
        </div>
      </div>
      <div class="detail-information-container">
        <div class="detail-information-header">Kontakt</div>
        <div class="detail-information-body">
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    phone_1: customer[selectedPatient].phone_1,
                    phone_1_info: customer[selectedPatient].phone_1_info
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    phone_1: customer[selectedPatient].phone_1,
                    phone_1_info: customer[selectedPatient].phone_1_info
                  })
                "
                [pTooltip]="latestChanges.phone_1 || latestChanges.phone_1_info"
                >Telefon 1</span
              >
            </div>
            <div class="detail-value">
              <strong *ngIf="!customer[selectedPatient].phone_1">-</strong>
              <strong class="p-d-flex p-align-center">
                <a
                  (click)="
                    phoneCallService.createCalling(
                      customer[selectedPatient].phone_1
                    )
                  "
                  *ngIf="customer[selectedPatient].phone_1"
                  href="tel:{{ customer[selectedPatient].phone_1 }}"
                >
                  {{ customer[selectedPatient].phone_1 }}
                </a>

                <i
                  style="margin: 0 0 0 10px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="customer[selectedPatient].phone_1_info"
                  [pTooltip]="customer[selectedPatient].phone_1_info"
                ></i>
              </strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    phone_2: customer[selectedPatient].phone_2,
                    phone_2_info: customer[selectedPatient].phone_2_info
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    phone_2: customer[selectedPatient].phone_2,
                    phone_2_info: customer[selectedPatient].phone_2_info
                  })
                "
                [pTooltip]="latestChanges.phone_2 || latestChanges.phone_2_info"
                >Telefon 2</span
              >
            </div>
            <div class="detail-value">
              <strong *ngIf="!customer[selectedPatient].phone_2">-</strong>
              <strong class="p-d-flex p-align-center">
                <a
                  (click)="
                    phoneCallService.createCalling(
                      customer[selectedPatient].phone_2
                    )
                  "
                  *ngIf="customer[selectedPatient].phone_2"
                  href="tel:{{ customer[selectedPatient].phone_2 }}"
                >
                  {{ customer[selectedPatient].phone_2 }}
                </a>

                <i
                  style="margin: 0 0 0 10px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="customer[selectedPatient].phone_2_info"
                  [pTooltip]="customer[selectedPatient].phone_2_info"
                ></i>
              </strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(customer[selectedPatient].id, 'patient', {
                    email: customer[selectedPatient].email,
                    email_info: customer[selectedPatient].email_info
                  })
                "
                (click)="
                  openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                    email: customer[selectedPatient].email,
                    email_info: customer[selectedPatient].email_info
                  })
                "
                [pTooltip]="latestChanges.email || latestChanges.email_info"
                >E-Mail</span
              >
            </div>
            <div class="detail-value">
              <strong *ngIf="!customer[selectedPatient].email">-</strong>
              <strong class="p-d-flex p-align-center">
                <a
                  *ngIf="customer[selectedPatient].email"
                  href="mailto:{{ customer[selectedPatient].email }}"
                >
                  {{ customer[selectedPatient].email }}
                </a>

                <i
                  style="margin: 0 0 0 10px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="customer[selectedPatient].email_info"
                  [pTooltip]="customer[selectedPatient].email_info"
                ></i>
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="content new-content-container"
  *ngIf="!loading && customer.customer.offer_type === 'OFFER'"
>
  <header class="content-header">
    <span>
      <i class="pi pi-home"></i>
      Haushalt

      <div
        class="color-blue"
        style="
          margin: 0 0 0 30px;
          display: flex;
          align-items: center;
          gap: 10px;
        "
        *ngIf="customer.customer.household.can_print"
      >
        <i class="pi pi-print"></i>
        Kann drucken
      </div>
    </span>
  </header>
  <div class="content-body without-padding">
    <div class="detail-information-body inline-information-body">
      <div class="detail-information">
        <div class="detail-key clickable">
          <span
            tooltipPosition="left"
            (mouseenter)="
              getLatestChange(customer.customer.household.id, 'household', {
                type: customer.customer.household.type,
                living_space: customer.customer.household.living_space,
                living_floor_number:
                  customer.customer.household.living_floor_number,
                living_floor: customer.customer.household.living_floor
              })
            "
            (click)="
              openDiffViewHistory(customer.customer.household.id, 'household', {
                type: customer.customer.household.type,
                living_space: customer.customer.household.living_space,
                living_floor_number:
                  customer.customer.household.living_floor_number,
                living_floor: customer.customer.household.living_floor
              })
            "
            [pTooltip]="
              latestChanges.type ||
              latestChanges.living_space ||
              latestChanges.living_floor_number ||
              latestChanges.living_floor
            "
            >Details</span
          >
        </div>
        <div class="detail-value">
          <strong
            >{{ customer.customer.household.type }},
            {{
              customer.customer.household.living_space
                ? customer.customer.household.living_space + " qm"
                : ""
            }}, {{ customer.customer.household.living_floor_number }}
            {{ customer.customer.household.living_floor }}</strong
          >
        </div>
      </div>

      <div class="detail-information">
        <div class="detail-key clickable">
          <span
            tooltipPosition="left"
            (mouseenter)="
              getLatestChange(customer.customer.household.id, 'household', {
                pets: customer.customer.household.pets,
                pets_info: customer.customer.household.pets_info,
                dogs: customer.customer.household.dogs,
                cats: customer.customer.household.cats
              })
            "
            (click)="
              openDiffViewHistory(customer.customer.household.id, 'household', {
                pets: customer.customer.household.pets,
                pets_info: customer.customer.household.pets_info,
                dogs: customer.customer.household.dogs,
                cats: customer.customer.household.cats
              })
            "
            [pTooltip]="
              latestChanges.pets ||
              latestChanges.pets_info ||
              latestChanges.cats ||
              latestChanges.dogs
            "
            >Haustiere im Haushalt</span
          >
        </div>
        <div class="detail-value">
          <strong
            >{{ customer.customer.household.pets || "-" }}
            {{ customer.customer.household.pets_info_string ? " - " : "" }}
            {{ customer.customer.household.pets_info_string }}</strong
          >
        </div>
      </div>
    </div>

    <div class="detail-information-body inline-information-body">
      <div class="detail-information">
        <div class="detail-key clickable">
          <span
            tooltipPosition="left"
            (mouseenter)="
              getLatestChange(customer.customer.household.id, 'household', {
                smoking: customer.customer.household.smoking
              })
            "
            (click)="
              openDiffViewHistory(customer.customer.household.id, 'household', {
                smoking: customer.customer.household.smoking
              })
            "
            [pTooltip]="latestChanges.smoking"
            >Rauchen im Haushalt</span
          >
        </div>
        <div class="detail-value">
          <strong>{{ customer.customer.household.smoking || "-" }}</strong>
        </div>
      </div>

      <div class="detail-information">
        <div class="detail-key clickable">
          <span
            tooltipPosition="left"
            (mouseenter)="
              getLatestChange(customer[selectedPatient].id, 'patient', {
                smoker_allowed: customer[selectedPatient].smoker_allowed
              })
            "
            (click)="
              openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                smoker_allowed: customer[selectedPatient].smoker_allowed
              })
            "
            [pTooltip]="latestChanges.smoker_allowed"
            >Raucher erlaubt?</span
          >
        </div>
        <div class="detail-value">
          <strong
            >{{ customer[selectedPatient].smoker_allowed || "-" }}
          </strong>
        </div>
      </div>

      <div class="detail-information">
        <div class="detail-key clickable">
          <span
            tooltipPosition="left"
            (mouseenter)="
              getLatestChange(customer[selectedPatient].id, 'patient', {
                driving_license_needed:
                  customer[selectedPatient].driving_license_needed
              })
            "
            (click)="
              openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                driving_license_needed:
                  customer[selectedPatient].driving_license_needed
              })
            "
            [pTooltip]="latestChanges.driving_license_needed"
            >Führerschein notwendig?</span
          >
        </div>
        <div class="detail-value">
          <strong
            >{{ customer[selectedPatient].driving_license_needed || "-" }}
          </strong>
        </div>
      </div>

      <div class="detail-information">
        <div class="detail-key clickable">
          <span
            tooltipPosition="left"
            (mouseenter)="
              getLatestChange(customer[selectedPatient].id, 'patient', {
                should_be_vaccinated:
                  customer[selectedPatient].should_be_vaccinated
              })
            "
            (click)="
              openDiffViewHistory(customer[selectedPatient].id, 'patient', {
                should_be_vaccinated:
                  customer[selectedPatient].should_be_vaccinated
              })
            "
            [pTooltip]="latestChanges.should_be_vaccinated"
            >Soll geimpft sein?</span
          >
        </div>
        <div class="detail-value">
          <strong
            >{{ customer[selectedPatient].should_be_vaccinated || "-" }}
          </strong>
        </div>
      </div>
    </div>

    <div class="detail-information-body inline-information-body">
      <div class="detail-information">
        <div class="detail-key clickable">
          <span
            tooltipPosition="left"
            (mouseenter)="
              getLatestChange(customer.customer.household.id, 'household', {
                transfer_needed: customer.customer.household.transfer_needed
              })
            "
            (click)="
              openDiffViewHistory(customer.customer.household.id, 'household', {
                transfer_needed: customer.customer.household.transfer_needed
              })
            "
            [pTooltip]="latestChanges.transfer_needed"
            >Transfer zwischen Stockwerken</span
          >
        </div>
        <div class="detail-value">
          <strong>{{
            customer.customer.household.transfer_needed || "-"
          }}</strong>
        </div>
      </div>

      <div class="detail-information">
        <div class="detail-key clickable">
          <span
            tooltipPosition="left"
            (mouseenter)="
              getLatestChange(customer.customer.household.id, 'household', {
                condition: customer.customer.household.condition
              })
            "
            (click)="
              openDiffViewHistory(customer.customer.household.id, 'household', {
                condition: customer.customer.household.condition
              })
            "
            [pTooltip]="latestChanges.condition"
            >Zustand Haushalt</span
          >
        </div>
        <div class="detail-value">
          <strong>{{ customer.customer.household.condition || "-" }}</strong>
        </div>
      </div>

      <div class="detail-information">
        <div class="detail-key clickable">
          <span
            tooltipPosition="left"
            (mouseenter)="
              getLatestChange(customer.customer.household.id, 'household', {
                key_case: customer.customer.household.key_case
              })
            "
            (click)="
              openDiffViewHistory(customer.customer.household.id, 'household', {
                key_case: customer.customer.household.key_case
              })
            "
            [pTooltip]="latestChanges.key_case"
            >Schlüsselkasten</span
          >
        </div>
        <div class="detail-value">
          <strong>{{ customer.customer.household.key_case || "-" }}</strong>
        </div>
      </div>

      <div class="detail-information">
        <div class="detail-key clickable">
          <span
            tooltipPosition="left"
            (mouseenter)="
              getLatestChange(customer.customer.household.id, 'household', {
                cleaning_stuff: customer.customer.household.cleaning_stuff
              })
            "
            (click)="
              openDiffViewHistory(customer.customer.household.id, 'household', {
                cleaning_stuff: customer.customer.household.cleaning_stuff
              })
            "
            [pTooltip]="latestChanges.cleaning_stuff"
            >Reinigungsmittel</span
          >
        </div>
        <div class="detail-value">
          <strong>{{
            customer.customer.household.cleaning_stuff || "-"
          }}</strong>
        </div>
      </div>
    </div>

    <div class="detail-information-body inline-information-body">
      <div class="detail-information">
        <div class="detail-key clickable">
          <span
            tooltipPosition="left"
            (mouseenter)="
              getLatestChange(customer.customer.household.id, 'household', {
                specials: customer.customer.household.specials
              })
            "
            (click)="
              openDiffViewHistory(customer.customer.household.id, 'household', {
                specials: customer.customer.household.specials
              })
            "
            [pTooltip]="latestChanges.specials"
            >Besonderheiten</span
          >
        </div>
        <div class="detail-value">
          <strong>{{ customer.customer.household.specials || "-" }}</strong>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="content new-content-container"
  *ngIf="!loading && customer.customer.offer_type === 'OFFER'"
>
  <header class="content-header">
    <span>
      <i class="pi pi-phone"></i>
      Ansprechpartner
      <span
        style="margin: 0 0 0 20px"
        class="p-tag p-tag-rounded p-tag-outline"
        *ngIf="
          customer.first_patient.is_contact_person ||
          customer.second_patient?.is_contact_person
        "
        >Patient ist AP</span
      >
    </span>
    <button
      pButton
      (click)="openContactPersonDialog()"
      label="Neuer Ansprechpartner"
      type="button"
      class="p-button-text button-without-padding"
      icon="pi pi-plus"
      iconPos="right"
    ></button>
  </header>
  <div
    class="content-body contact-person-content-body"
    *ngIf="customer.customer.contact_persons.length === 0"
  >
    <span class="content-no-message"> Kein Ansprechpartner vorhanden </span>
  </div>

  <div
    class="content-body contact-person-content-body without-padding p-flex-row p-flex-wrap"
    *ngIf="customer.customer.contact_persons.length > 0"
  >
    <div
      *ngFor="
        let contactPerson of customer.customer.contact_persons;
        let i = index
      "
      class="detail-information-column contact-person-column border-right"
    >
      <div class="detail-information-container">
        <div class="detail-information-header for-contact-person">
          Ansprechpartner {{ i + 1 }}

          <div style="display: flex; align-items: center">
            <i
              pTooltip="Kann drucken"
              class="pi pi-print"
              *ngIf="contactPerson.can_print"
            ></i>
            <i
              class="pi pi-pencil"
              (click)="openContactPersonDialog(contactPerson)"
            ></i>
          </div>
        </div>
        <div class="detail-information-body">
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(contactPerson.id, 'contact_person', {
                    informations_string: contactPerson.informations_string
                  })
                "
                (click)="
                  openDiffViewHistory(contactPerson.id, 'contact_person', {
                    informations_string: contactPerson.informations_string
                  })
                "
                [pTooltip]="latestChanges.informations_string"
                >Infos</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ contactPerson.informations_string || "-" }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(contactPerson.id, 'contact_person', {
                    form_of_address: contactPerson.form_of_address,
                    first_name: contactPerson.first_name,
                    last_name: contactPerson.last_name
                  })
                "
                (click)="
                  openDiffViewHistory(contactPerson.id, 'contact_person', {
                    form_of_address: contactPerson.form_of_address,
                    first_name: contactPerson.first_name,
                    last_name: contactPerson.last_name
                  })
                "
                [pTooltip]="
                  latestChanges.form_of_address ||
                  latestChanges.first_name ||
                  latestChanges.last_name
                "
                >Name</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ contactPerson.form_of_address }}
                {{ contactPerson.first_name }}
                {{ contactPerson.last_name }}</strong
              >
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(contactPerson.id, 'contact_person', {
                    relation: contactPerson.relation
                  })
                "
                (click)="
                  openDiffViewHistory(contactPerson.id, 'contact_person', {
                    relation: contactPerson.relation
                  })
                "
                [pTooltip]="latestChanges.relation"
                >Beziehung</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ contactPerson.relation }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(contactPerson.id, 'contact_person', {
                    phone_1: contactPerson.phone_1,
                    phone_1_info: contactPerson.phone_1_info
                  })
                "
                (click)="
                  openDiffViewHistory(contactPerson.id, 'contact_person', {
                    phone_1: contactPerson.phone_1,
                    phone_1_info: contactPerson.phone_1_info
                  })
                "
                [pTooltip]="latestChanges.phone_1 || latestChanges.phone_1_info"
                >Telefon 1</span
              >
            </div>
            <div class="detail-value">
              <strong class="p-d-flex p-align-center">
                <a
                  (click)="
                    phoneCallService.createCalling(contactPerson.phone_1)
                  "
                  href="tel:{{ contactPerson.phone_1 }}"
                >
                  {{ contactPerson.phone_1 }}
                </a>

                <i
                  style="margin: 0 0 0 10px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="contactPerson.phone_1_info"
                  [pTooltip]="contactPerson.phone_1_info"
                ></i>
              </strong>
            </div>
          </div>
          <div class="detail-information" *ngIf="openedContactPersons[i]">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(contactPerson.id, 'contact_person', {
                    phone_2: contactPerson.phone_2,
                    phone_2_info: contactPerson.phone_2_info
                  })
                "
                (click)="
                  openDiffViewHistory(contactPerson.id, 'contact_person', {
                    phone_2: contactPerson.phone_2,
                    phone_2_info: contactPerson.phone_2_info
                  })
                "
                [pTooltip]="latestChanges.phone_2 || latestChanges.phone_2_info"
                >Telefon 2</span
              >
            </div>
            <div class="detail-value">
              <strong class="p-d-flex p-align-center">
                <a
                  (click)="
                    phoneCallService.createCalling(contactPerson.phone_2)
                  "
                  href="tel:{{ contactPerson.phone_2 }}"
                >
                  {{ contactPerson.phone_2 }}
                </a>

                <i
                  style="margin: 0 0 0 10px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="contactPerson.phone_2_info"
                  [pTooltip]="contactPerson.phone_2_info"
                ></i>
              </strong>
            </div>
          </div>
          <div class="detail-information" *ngIf="openedContactPersons[i]">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(contactPerson.id, 'contact_person', {
                    birthday_string: contactPerson.birthday_string
                  })
                "
                (click)="
                  openDiffViewHistory(contactPerson.id, 'contact_person', {
                    birthday_string: contactPerson.birthday_string
                  })
                "
                [pTooltip]="latestChanges.birthday_string"
                >Geburtstag</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ contactPerson.birthday_string }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(contactPerson.id, 'contact_person', {
                    email_private: contactPerson.email_private,
                    email_private_info: contactPerson.email_private_info
                  })
                "
                (click)="
                  openDiffViewHistory(contactPerson.id, 'contact_person', {
                    email_private: contactPerson.email_private,
                    email_private_info: contactPerson.email_private_info
                  })
                "
                [pTooltip]="
                  latestChanges.email_private ||
                  latestChanges.email_private_info
                "
                >E-Mail Privat</span
              >
            </div>
            <div class="detail-value">
              <strong class="p-d-flex p-align-center">
                <a href="mailto:{{ contactPerson.email_private }}">
                  {{ contactPerson.email_private }}
                </a>

                <i
                  style="margin: 0 0 0 10px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="contactPerson.email_private_info"
                  [pTooltip]="contactPerson.email_private_info"
                ></i>
              </strong>
            </div>
          </div>
          <div class="detail-information" *ngIf="openedContactPersons[i]">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(contactPerson.id, 'contact_person', {
                    email_work: contactPerson.email_work,
                    email_work_info: contactPerson.email_work_info
                  })
                "
                (click)="
                  openDiffViewHistory(contactPerson.id, 'contact_person', {
                    email_work: contactPerson.email_work,
                    email_work_info: contactPerson.email_work_info
                  })
                "
                [pTooltip]="
                  latestChanges.email_work || latestChanges.email_work_info
                "
                >E-Mail Geschäftlich</span
              >
            </div>
            <div class="detail-value">
              <strong class="p-d-flex p-align-center">
                <a href="mailto:{{ contactPerson.email_work }}">
                  {{ contactPerson.email_work }}
                </a>

                <i
                  style="margin: 0 0 0 10px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="contactPerson.email_work_info"
                  [pTooltip]="contactPerson.email_work_info"
                ></i>
              </strong>
            </div>
          </div>
          <div class="detail-information" *ngIf="openedContactPersons[i]">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(contactPerson.id, 'contact_person', {
                    zipcode: contactPerson.zipcode,
                    city: contactPerson.city,
                    city_part: contactPerson.city_part
                  })
                "
                (click)="
                  openDiffViewHistory(contactPerson.id, 'contact_person', {
                    zipcode: contactPerson.zipcode,
                    city: contactPerson.city,
                    city_part: contactPerson.city_part
                  })
                "
                [pTooltip]="
                  latestChanges.zipcode ||
                  latestChanges.city ||
                  latestChanges.city_part
                "
                >PLZ / Ort</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ contactPerson.zipcode }} {{ contactPerson.city }}
                {{ contactPerson.city_part }}</strong
              >
            </div>
          </div>
          <div class="detail-information" *ngIf="openedContactPersons[i]">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(contactPerson.id, 'contact_person', {
                    street_house_number: contactPerson.street_house_number
                  })
                "
                (click)="
                  openDiffViewHistory(contactPerson.id, 'contact_person', {
                    street_house_number: contactPerson.street_house_number
                  })
                "
                [pTooltip]="latestChanges.street_house_number"
                >Adresse</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ contactPerson.street_house_number || "-" }}</strong>
            </div>
          </div>
          <div class="detail-information" *ngIf="openedContactPersons[i]">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(contactPerson.id, 'contact_person', {
                    local_specialties: contactPerson.local_specialties
                  })
                "
                (click)="
                  openDiffViewHistory(contactPerson.id, 'contact_person', {
                    local_specialties: contactPerson.local_specialties
                  })
                "
                [pTooltip]="latestChanges.local_specialties"
                >Örtliche Besonderheiten</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ contactPerson.local_specialties || "-" }}</strong>
            </div>
          </div>
          <div class="detail-information" *ngIf="openedContactPersons[i]">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(contactPerson.id, 'contact_person', {
                    comment: contactPerson.comment
                  })
                "
                (click)="
                  openDiffViewHistory(contactPerson.id, 'contact_person', {
                    comment: contactPerson.comment
                  })
                "
                [pTooltip]="latestChanges.comment"
                >Kommentar</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ contactPerson.comment || "-" }}</strong>
            </div>
          </div>
        </div>

        <span
          class="show-more-btn"
          (click)="openedContactPersons[i] = !openedContactPersons[i]"
        >
          {{ openedContactPersons[i] ? "Weniger anzeigen" : "Mehr anzeigen" }}
        </span>
      </div>
    </div>
  </div>
</div>

<div class="content-grid no-wrap" *ngIf="!loading">
  <div class="content new-content-container w-2/4 content-margin-right">
    <header class="content-header">
      <span>
        <i class="pi pi-clock"></i>
        Letzte Historie
      </span>

      <button
        pButton
        label="Neuer Eintrag"
        (click)="openManualHistoryDialog(false)"
        type="button"
        class="p-button-text button-without-padding"
        icon="pi pi-plus"
        iconPos="right"
      ></button>
    </header>
    <div
      class="content-body content-base-height"
      *ngIf="
        customer &&
        customer.last_histories[this.selectedPatient] &&
        customer.last_histories[this.selectedPatient].histories.length === 0
      "
    >
      <span class="content-no-message">Keine History vorhanden</span>
    </div>
    <div
      class="content-body content-base-height without-padding"
      *ngIf="
        customer.last_histories[this.selectedPatient] &&
        customer.last_histories[this.selectedPatient].histories.length > 0
      "
    >
      <p-table
        [value]="customer.last_histories[this.selectedPatient].histories"
        [rowHover]="true"
        [scrollable]="true"
        scrollHeight="100%"
      >
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <th pSortableColumn="type_text">
              Typ <p-sortIcon field="type_text"></p-sortIcon>
            </th>
            <th pSortableColumn="created_at">
              am <p-sortIcon field="created_at"></p-sortIcon>
            </th>
            <th style="width: 50px"></th>
            <th style="min-width: 200px">Informationen</th>
            <th style="width: 100px"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-history>
          <tr
            [class.background-hightlight-blue]="
              history.available_for_recommendation
            "
            [class.background-hightlight-yellow]="history.important"
          >
            <td>
              <strong>
                <!--                <i-->
                <!--                  class="pi pi-star color-orange"-->
                <!--                  *ngIf="history.important"-->
                <!--                  pTooltip="Wichtiges Ereignis"-->
                <!--                ></i>-->
                {{ history.type_text }}
              </strong>
            </td>

            <td>
              <div style="display: flex; flex-direction: column">
                <span>{{ history.occurred_at_string }}</span>
                <small>{{ history.created_from_name || "??" }}</small>
              </div>
            </td>

            <td style="width: 50px">
              <i
                tooltipPosition="left"
                [pTooltip]="history.phone_call.comment"
                class="pi pi-info-circle"
                *ngIf="history.phone_call?.comment"
              ></i>

              <i
                tooltipPosition="left"
                [pTooltip]="history.manual_description"
                class="pi pi-info-circle"
                *ngIf="history.manual_description"
              ></i>
            </td>
            <td style="min-width: 200px">
              <span
                class="history-information"
                *ngIf="history.type === 'PHONE_CALL'"
              >
                <i><strong>Dauer:</strong> {{ history.phone_call.duration }}</i>
              </span>

              <span
                class="history-information"
                *ngIf="
                  history.type === 'CONTACT_PERSON_CREATED' ||
                  history.type === 'CONTACT_PERSON_UPDATED' ||
                  history.type === 'PATIENT_CHANGED' ||
                  history.type === 'CONTACT_PERSON_DEACTIVATED'
                "
              >
                <i><strong>Name:</strong> {{ history.manual_description }}</i>
              </span>

              <span
                class="history-information"
                *ngIf="
                  history.type === 'MANUAL' ||
                  history.type === 'STATUS_CHANGED' ||
                  history.type === 'CUSTOMER_SERVICE_CHANGED' ||
                  history.type === 'TODO_CREATED' ||
                  history.type === 'TODO_FINISHED' ||
                  history.type === 'TODO_DELETED' ||
                  history.type === 'TODO_UPDATED' ||
                  history.type === 'VETO_CREATED' ||
                  history.type === 'VETO_DELETED' ||
                  history.type === 'VETO_UPDATED' ||
                  history.type === 'DOCUMENT_CHANGED' ||
                  history.type === 'DOCUMENT_REMOVED' ||
                  history.type === 'DOCUMENT_ARCHIVED' ||
                  history.type === 'DOCUMENT_UNARCHIVED' ||
                  history.type === 'BUDGET_CHANGED' ||
                  history.type === 'BUDGET_REMOVED_FROM_LIST' ||
                  history.type === 'PAUSED' ||
                  history.type === 'PATIENT_PRICE_CREATED' ||
                  history.type === 'PAUSE_REACTIVATED' ||
                  history.type === 'INVOICE_OPENED' ||
                  history.type === 'INVOICE_STORNO' ||
                  history.type === 'OFFER_SENT' ||
                  history.type === 'APPOINTMENTS_SENT' ||
                  history.type === 'CONSULTING_CREATED' ||
                  history.type === 'CONSULTING_UPDATED' ||
                  history.type === 'CONSULTING_DELETED' ||
                  history.type === 'CONSULTING_CONFIRMED' ||
                  history.type === 'DESIRED_DATE_CREATED' ||
                  history.type === 'DESIRED_DATE_UPDATED' ||
                  history.type === 'DESIRED_DATE_DELETED' ||
                  history.type === 'CANCELLED_DATE_CREATED' ||
                  history.type === 'CANCELLED_DATE_UPDATED' ||
                  history.type === 'CANCELLED_DATE_DELETED' ||
                  history.type === 'CREATED' ||
                  history.type === 'SYSTEM_MESSAGE_SENT' ||
                  history.type === 'ACCOUNTING_RETURN_CREATED' ||
                  history.type === 'ACCOUNTING_RETURN_DELETED' ||
                  history.type === 'LETTER_SENT'
                "
              >
                <i
                  ><b>{{ history.manual_title }}</b></i
                >
              </span>
            </td>
            <td class="table-action" style="width: 100px">
              <i
                tooltipStyleClass="tooltip-wider"
                tooltipPosition="right"
                [pTooltip]="history.appointment_html_table"
                class="pi pi-calendar"
                [escape]="false"
                *ngIf="history.appointment_html_table"
              ></i>

              <i
                (click)="openDiffView(history.id)"
                tooltipPosition="left"
                *ngIf="
                  history.type === 'CHANGED' ||
                  history.type === 'NOTICE_CHANGED' ||
                  history.type === 'NOTICE_ACCOUNTING_CHANGED' ||
                  history.type === 'CUSTOMER_CHANGED' ||
                  history.type === 'PATIENT_CHANGED' ||
                  history.type === 'QM_CHANGED' ||
                  history.type === 'HOUSEHOLD_CHANGED' ||
                  history.type === 'CONTACT_PERSON_UPDATED'
                "
                class="pi pi-eye"
              ></i>
              <i
                (click)="openOfferMail(history.historiable_id)"
                pTooltip="E-Mail ansehen"
                *ngIf="
                  history.type === 'OFFER_SENT' &&
                  history.manual_title.includes('Mail')
                "
                class="pi pi-eye"
              ></i>
              <i
                (click)="openAppointmentsMail(history.historiable_id)"
                pTooltip="E-Mail ansehen"
                *ngIf="
                  history.type === 'APPOINTMENTS_SENT' &&
                  history.manual_title.includes('Mail')
                "
                class="pi pi-eye"
              ></i>
              <i
                (click)="openLetterMail(history.historiable_id)"
                pTooltip="E-Mail ansehen"
                *ngIf="
                  history.type === 'LETTER_SENT' &&
                  history.manual_title.includes('Mail')
                "
                class="pi pi-eye"
              ></i>
              <i
                *ngIf="history.type === 'PHONE_CALL'"
                class="pi pi-pencil"
                (click)="openPhonecallEditModal(history.phone_call)"
              ></i>
              <i
                *ngIf="
                  history.type === 'TODO_CREATED' ||
                  history.type === 'TODO_FINISHED' ||
                  history.type === 'TODO_UPDATED'
                "
                class="pi pi-eye"
                pTooltip="Todo ansehen"
                (click)="openShowTodoDialog(history.todo)"
              ></i>
              <i
                *ngIf="history.type === 'MANUAL'"
                (click)="openManualHistoryDialog(true, history)"
                class="pi pi-pencil"
              ></i>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div class="content new-content-container w-2/4">
    <header class="content-header">
      <span>
        <i class="pi pi-check-circle"></i>
        Todos
      </span>
    </header>
    <div
      class="content-body content-base-height"
      *ngIf="customer.todos.length === 0"
    >
      <span class="content-no-message">Keine Todos vorhanden</span>
    </div>

    <div
      class="content-body content-base-height without-padding"
      *ngIf="customer.todos.length > 0"
    >
      <p-table
        [value]="customer.todos"
        [rowHover]="true"
        [scrollable]="true"
        scrollHeight="100%"
      >
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <th style="width: 120px" pSortableColumn="status">
              Status <p-sortIcon field="status"></p-sortIcon>
            </th>
            <th pSortableColumn="title">
              Titel <p-sortIcon field="title"></p-sortIcon>
            </th>
            <th style="width: 50px"></th>
            <th pSortableColumn="for_when">
              Zu erledigen <p-sortIcon field="for_when"></p-sortIcon>
            </th>
            <th>Kommentare</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-todo>
          <tr class="clickable" (click)="openShowTodoDialog(todo)">
            <td style="width: 120px">
              <span class="p-tag p-tag-rounded p-tag-{{ todo.status }}">{{
                todo.status_string
              }}</span>
            </td>
            <td>{{ todo.title }}</td>
            <td style="width: 50px">
              <i
                tooltipPosition="left"
                [pTooltip]="todo.comment"
                class="pi pi-info-circle"
                *ngIf="todo.comment"
              ></i>
            </td>
            <td>
              {{ todo.for_when_type === "FINISH_AT" ? "am" : "bis" }}
              {{ todo.for_when_string }}
            </td>
            <td>
              {{ todo.comments_count }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
