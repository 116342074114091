import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { TodoModel } from '../models/todo/todo.model'
import { TodoHeaderModel } from '../models/todo/todo-header.model'

@Injectable({
  providedIn: 'root',
})
export class TodoService {
  constructor(private http: HttpClient) {}

  public load(todoId: string | number): Observable<TodoModel> {
    return this.http.get<TodoModel>(`${environment.api}/todo/${todoId}`)
  }

  public changeStatus(todo: TodoModel): Observable<[]> {
    return this.http.put<[]>(`${environment.api}/todo/${todo.id}/status`, {
      ...todo,
    })
  }

  public changeAssignments(todo: TodoModel): Observable<[]> {
    return this.http.put<[]>(`${environment.api}/todo/${todo.id}/assignments`, {
      ...todo,
    })
  }

  public changeMyPriority(todoId: any, myPriority: number): Observable<[]> {
    return this.http.put<[]>(`${environment.api}/todo/${todoId}/my-priority`, {
      myPriority,
    })
  }

  public store(todo: TodoModel, extraData: any): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/todo`, { ...todo, extraData })
  }

  public storeReminder(todoId: number, values: any): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/todo/${todoId}/reminder`, {
      ...values,
    })
  }

  public update(todo: TodoModel): Observable<[]> {
    return this.http.put<[]>(`${environment.api}/todo/${todo.id}`, { ...todo })
  }

  public delete(id: number | null): Observable<[]> {
    return this.http.delete<[]>(`${environment.api}/todo/${id}`)
  }

  public loadOpen(settings: any): Observable<TodoModel[]> {
    return this.http.get<TodoModel[]>(`${environment.api}/todo/open`, {
      params: {
        ...settings,
      },
    })
  }

  public loadReminder(todoId: number): Observable<any> {
    return this.http.get<any>(`${environment.api}/todo/${todoId}/reminder`)
  }

  public openForPatient(patientId: any): Observable<TodoModel[]> {
    return this.http.get<TodoModel[]>(
      `${environment.api}/todo/${patientId}/open-for-patient`
    )
  }

  public storeComment(todoId: number | string, data: {}): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/todo/${todoId}/comment`, data)
  }

  public updateComment(
    todoCommentId: number | string,
    data: {}
  ): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/todo/${todoCommentId}/comment`,
      data
    )
  }

  public loadOpenForHeader(): Observable<TodoHeaderModel> {
    return this.http.get<TodoHeaderModel>(`${environment.api}/todo/open-header`)
  }

  public loadFinished(): Observable<TodoModel[]> {
    return this.http.get<TodoModel[]>(`${environment.api}/todo/finished`)
  }

  public loadOwn(): Observable<TodoModel[]> {
    return this.http.get<TodoModel[]>(`${environment.api}/todo/own`)
  }
}
