import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { PatientService } from '../../../services/patient.service'
import { TodoModel } from '../../../models/todo/todo.model'
import { UserSystemService } from '../../../services/user-system.service'
import {
  UserSystemModel,
  UserSystemSimpleModel,
} from '../../../models/user/user-system.model'
import { SearchService } from '../../../services/search.service'
import { SearchResultModel } from '../../../models/search/search-result.model'
import { TodoService } from '../../../services/todo.service'
import { ConfirmationService, SelectItem } from 'primeng/api'
import { ShowTodoDialogComponent } from '../show-todo-dialog/show-todo-dialog.component'
import { HelperService } from '../../../services/helper.service'
import { AddDocumentDialogComponent } from '../add-document-dialog/add-document-dialog.component'
import { DocumentService } from '../../../services/document.service'
import Tribute from '@redmine-ui/tributejs'
import { AuthService } from '../../../services/auth.service'

@Component({
  selector: 'app-todo-form-dialog',
  templateUrl: './todo-form-dialog.component.html',
})
export class TodoFormDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  submitted = false
  submittedDelete = false

  public priorityOptions = [
    { label: 'Hoch', value: 1 },
    { label: 'Normal', value: 2 },
    { label: 'Niedrig', value: 3 },
  ]

  public todoCategories = []

  public userSystemOptions: any[] = []
  public caregivers: SearchResultModel[] = []
  public cars: SearchResultModel[] = []
  public patients: SearchResultModel[] = []
  public todo = new TodoModel()
  public data: any

  constructor(
    public documentService: DocumentService,
    private helperService: HelperService,
    private ref: DynamicDialogRef,
    private dialogService: DialogService,
    private config: DynamicDialogConfig,
    private authService: AuthService,
    private searchService: SearchService,
    private todoService: TodoService,
    private userSystemService: UserSystemService,
    private patientService: PatientService,
    private eventbus: EventBusService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService
  ) {}

  public ngOnInit(): void {
    this.data = this.config.data

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.helperService.dependencies$.subscribe((data: any) => {
      this.todoCategories = data['todo_categories']
    })

    this.loadAllUserSystem()
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public openAddDocumentDialog(): void {
    const ref = this.dialogService.open(AddDocumentDialogComponent, {
      data: {
        // Standardmäßig wird das Dokument auf die ID 1 gebucht.
        type_id: this.todo.id ?? 1,
        type: 'todo',
        default_document_type: 'misc',
      },
      header: 'Dokument hochladen',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '450px',
    })

    ref.onClose.subscribe((document: any) => {
      if (document) {
        this.todo.media.push(document.media)
      }
    })
  }

  public resetOptions(): void {
    this.todo.patient = null
    this.todo.caregiver = null
  }

  public searchCaregivers(event: any): void {
    this.searchService
      .findCaregivers(event.query.trim())
      .subscribe((results: SearchResultModel[]) => {
        this.caregivers = results
      })
  }

  public searchCars(event: any): void {
    this.searchService
      .findCars(event.query.trim())
      .subscribe((results: SearchResultModel[]) => {
        this.cars = results
      })
  }

  public searchPatients(event: any): void {
    this.searchService
      .findPatients(event.query.trim())
      .subscribe((results: SearchResultModel[]) => {
        this.patients = results
      })
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    const subscription = this.data.isEdit
      ? this.todoService.update(this.todo)
      : this.todoService.store(this.todo, this.data)

    subscription.subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.TodoChanged)
        this.ref.close(true)
        this.toastService.success(
          'Todo gespeichert',
          'Das Todo wurde erfolgreich gespeichert'
        )
      },
      () => {
        this.toastService.error(
          'Etwas ist schiefgelaufen...',
          'Bitte wenden Sie sich an den Support'
        )

        this.submitted = false
      }
    )
  }

  private loadData(): void {
    if (this.data.isEdit) {
      this.loadTodo()
    }

    if (this.data.isPreSelected) {
      this.todo.assignment_type = this.data.selectedType
      this.todo.comment = this.data.comment

      if (this.data.selectedType === 'CAREGIVER') {
        this.todo.caregiver = new SearchResultModel()
        this.todo.caregiver.id = this.data.user_type_id
      } else if (this.data.selectedType === 'PATIENT') {
        this.todo.patient = new SearchResultModel()
        this.todo.patient.id = this.data.user_type_id
      }
    }
  }

  private loadTodo(): void {
    this.todoService
      .load(this.data.todo.id)
      .subscribe((response: TodoModel) => {
        this.todo = response

        // @ts-ignore
        this.todo.user_systems = this.todo.user_systems.map(
          (userSystem: UserSystemModel) => {
            return {
              id: userSystem.id,
              full_name: userSystem.full_name.trim(),
            }
          }
        )
      })
  }

  public openShowTodoDialog(): void {
    this.ref.close()
    this.dialogService.open(ShowTodoDialogComponent, {
      header: `Todo ansehen #${this.todo.id}`,
      width: '820px',
      styleClass: 'dialog-container',
      data: {
        isEdit: true,
        todo: this.todo,
        todo_id: this.todo.id,
        user_type_name:
          this.todo?.caregiver?.full_name || this.todo?.patient?.full_name,
      },
    })
  }

  private loadAllUserSystem(): void {
    this.userSystemService
      .listActiveSimple()
      .subscribe((data: UserSystemSimpleModel[]) => {
        for (let userSystem of data) {
          this.userSystemOptions.push({
            id: userSystem.id,
            full_name: userSystem.full_name.trim(),
          })
        }

        this.loadData()

        this.initMentions()
      })
  }

  chooseMe(): void {
    const myId = this.authService.getUser()?.id

    const me = this.userSystemOptions.find((userSystem: UserSystemModel) => {
      return userSystem.id === myId
    })

    if (me && !this.todo.user_systems.includes(me)) {
      this.todo.user_systems = [...this.todo.user_systems, me]
    }
  }

  private initMentions(): void {
    setTimeout(() => {
      var tribute = new Tribute({
        menuItemLimit: 5,
        // replaceTextSuffix: '',
        values: this.userSystemOptions.map((t) => {
          return {
            key: t.full_name,
            value: t.full_name,
          }
        }),
      })

      // @ts-ignore
      tribute.attach(document.querySelectorAll('textarea'))
    }, 500)
  }

  public remove(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Todo wirklich löschen?',
      accept: () => {
        this.submittedDelete = true

        this.todoService.delete(this.todo.id || null).subscribe(
          () => {
            this.submittedDelete = false
            this.eventbus.emit(GlobalEvent.TodoChanged)
            this.ref.close()
            this.toastService.success(
              'Todo gelöscht',
              'Das Todo wurde erfolgreich gelöscht'
            )
          },
          () => {
            this.submittedDelete = false
            this.toastService.error(
              'Löschen fehlgeschlagen',
              'Das Todo konnte nicht gelöscht werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }
}
