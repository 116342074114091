import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { ToastService } from '../../../../../services/toast.service'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { PatientService } from '../../../../../services/patient.service'
import { CustomerDetailBudgetModel } from '../../../../../models/customer-patient/patient-detail-budget.model'
import { HelperService } from '../../../../../services/helper.service'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'

@Component({
  selector: 'app-patients-detail-appointments',
  templateUrl: './patients-detail-appointments.component.html',
})
export class PatientsDetailAppointmentsComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null
  public firstPage = 0
  public globalFilterValue = ''
  public contentLoading = true

  public loading = true
  public currentHover = ''
  public year = ''
  public month = ''
  public showAll = true

  public selectedYear = 0
  public selectedMonth = 0

  public appointments: any = []

  private eventBusSubscription: Subscription = new Subscription()
  public patients: CustomerDetailBudgetModel = new CustomerDetailBudgetModel()

  public patientId: string | null = null

  public monthOptions: any = []
  public yearOptions = [
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
    { label: '2025', value: '2025' },
  ]

  constructor(
    private patientService: PatientService,
    private toastService: ToastService,
    private helperService: HelperService,
    private eventbus: EventBusService,
    private dialogService: DialogService,
    private route: ActivatedRoute
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  ngOnInit(): void {
    const date = new Date()
    this.selectedYear = date.getFullYear()
    this.selectedMonth = date.getMonth()

    this.helperService.dependencies$.subscribe((data: any) => {
      this.monthOptions = data.months
      this.month = this.monthOptions[this.selectedMonth].label
      this.year = this.selectedYear.toString()
    })

    if (this.route.parent?.parent) {
      this.patientId = this.route.parent.parent.snapshot.params.id

      this.loadAppointments()
    }

    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.BudgetChanged:
            this.loadAppointments(false)
        }
      }
    )
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  /**
   * Springt zum letzten Monat.
   */
  public goToPreviousMonth(): void {
    if (this.selectedMonth === 0) {
      const lastYear = this.selectedYear - 1

      const hasYearOption = this.yearOptions.find(
        (data: any) => data.value == lastYear
      )

      if (hasYearOption) {
        this.selectedYear = lastYear
        this.selectedMonth = 11
      }
    } else {
      this.selectedMonth--
    }

    this.year = this.selectedYear.toString()
    this.month = this.monthOptions[this.selectedMonth].label

    this.loadAppointments(false)
  }

  /**
   * Springt zum nächsten Monat.
   */
  public goToNextMonth(): void {
    if (this.selectedMonth === 11) {
      const nextYear = this.selectedYear + 1

      const hasYearOption = this.yearOptions.find(
        (data: any) => data.value == nextYear
      )

      if (hasYearOption) {
        this.selectedYear = nextYear
        this.selectedMonth = 0
      }
    } else {
      this.selectedMonth++
    }

    this.year = this.selectedYear.toString()
    this.month = this.monthOptions[this.selectedMonth].label

    this.loadAppointments(false)
  }

  public loadAppointments(withLoading: boolean = true): void {
    this.contentLoading = true

    if (withLoading) {
      this.loading = true
    }

    this.patientService
      .loadAppointmentList(this.patientId, this.month, this.year, this.showAll)
      .subscribe((data: any) => {
        this.appointments = data

        this.loading = false
        this.contentLoading = false

        setTimeout(() => {
          if (this.table) {
            const totalItems = this.table._totalRecords

            this.globalFilterValue = this.table.filters.global?.value ?? ''

            if (
              this.table._first > totalItems ||
              this.table._rows > totalItems
            ) {
              this.firstPage = 0
            }
          }
        })
      })
  }
}
