import { Component, OnInit } from '@angular/core'
import { DynamicDialogConfig } from 'primeng/dynamicdialog'
import { PatientPriceService } from '../../../services/patient-price.service'

@Component({
  selector: 'app-patient-price-history-dialog',
  templateUrl: './patient-price-history-dialog.component.html',
})
export class PatientPriceHistoryDialogComponent implements OnInit {
  public data: any[] = []

  constructor(
    private patientPriceService: PatientPriceService,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.patientPriceService
      .getHistory(this.config.data.patient.id)
      .subscribe((data: []) => {
        console.log(data)

        this.data = data
      })
  }
}
