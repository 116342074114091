import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'
import { CustomerDetailModel } from '../models/customer-patient/customer-detail.model'
import { CustomerDetailGeneralModel } from '../models/customer-patient/customer-detail-general.model'
import { CustomerDetailDocumentsModel } from '../models/customer-patient/patient-detail-documents.model'
import { CustomerDetailBudgetModel } from '../models/customer-patient/patient-detail-budget.model'
import { CustomerDetailVetoModel } from '../models/customer-patient/patient-detail-veto.model'
import { PatientDetailHistoryModel } from '../models/customer-patient/patient-detail-history.model'
import { PatientPauseModel } from '../models/customer-patient/patient-pause.model'
import { HistoryListModel } from '../models/history/history-list.model'

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  constructor(private http: HttpClient) {}

  // TODO: model erstellen
  public qualificationOpen(): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.api}/patients/qualification-open`
    )
  }

  // TODO: model erstellen
  public inactive(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.api}/patients/inactive`)
  }

  // TODO: model erstellen
  public paused(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.api}/patients/paused`)
  }

  public vetos(id: string | null): Observable<CustomerDetailVetoModel> {
    return this.http.get<CustomerDetailVetoModel>(
      `${environment.api}/patients/${id}/vetos`
    )
  }

  public loadPatientsFromCustomerId(customerId: any): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.api}/patients/${customerId}/customer-patients`
    )
  }

  public getBudgetTypesForMonth(
    id: string | null,
    year: string,
    month: string
  ): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.api}/patients/${id}/budget-types?year=${year}&month=${month}`
    )
  }

  // TODO: model erstellen
  public getReceivers(id: any): Observable<any> {
    return this.http.get<any>(`${environment.api}/patients/${id}/receivers`)
  }

  // TODO: model erstellen
  public loadRecommendation(id: any): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/patients/${id}/recommendation`
    )
  }

  // TODO: model erstellen
  public getNearPatients(
    customerId: number | null,
    area: number
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/patients/${customerId}/near-patients`,
      {
        params: {
          area,
        },
      }
    )
  }

  // TODO: model erstellen
  public getNearPatientsWithOpenConsultings(
    patientId: number | null,
    date: string,
    area: number = 30
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/patients/${patientId}/near-patients-with-open-consultings`,
      {
        params: {
          date,
          area,
        },
      }
    )
  }

  // TODO: model erstellen
  public loadBudgetCalculations(id: string): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/patients/${id}/budget-calculations`
    )
  }

  public loadBudgets(
    id: string | null,
    year: string
  ): Observable<CustomerDetailBudgetModel> {
    return this.http.get<CustomerDetailBudgetModel>(
      `${environment.api}/patients/${id}/budgets?year=${year}`
    )
  }

  public loadAppointmentList(
    id: string | null,
    month: any,
    year: any,
    showAll: boolean
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/patients/${id}/appointment-list?year=${year}&month=${month}&show_all=${showAll}`
    )
  }

  // TODO: model erstellen
  public confirmBudgets(
    id: string,
    values: any,
    year: string
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.api}/patients/${id}/confirm-budgets?year=${year}`,
      values
    )
  }

  // TODO: model erstellen
  public changeBudget(patientId: string, values: any): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/patients/${patientId}/change-budget`,
      values
    )
  }

  public enableBudget(
    patientId: number,
    type: string,
    year: number
  ): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/patients/${patientId}/enable-budget`,
      {
        type,
        year,
      }
    )
  }

  // TODO: model erstellen
  public changeAppointmentTypes(
    id: string,
    values: any,
    comment: string,
    receiver: any
  ): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/patients/${id}/appointment-types`,
      {
        values,
        comment,
        receiver,
      }
    )
  }

  // TODO: model erstellen
  public changeInvoiceTypes(
    id: string,
    values: any,
    comment: string,
    receiver: any
  ): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/patients/${id}/invoice-types`,
      {
        values,
        comment,
        receiver,
      }
    )
  }

  // TODO: model erstellen
  public new(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.api}/patients/new`)
  }

  // TODO: model erstellen
  public regular(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.api}/patients/regular`)
  }

  public load(id: any): Observable<CustomerDetailGeneralModel> {
    return this.http.get<CustomerDetailGeneralModel>(
      `${environment.api}/patients/${id}`
    )
  }

  public changeCustomerService(
    customerId: number | null,
    data: any
  ): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/patients/${customerId}/change-service`,
      data
    )
  }

  public getPersplanCaregivers(
    patientId: number | null,
    budgetDate: string,
    budgetType: string
  ): Observable<string> {
    return this.http.get(
      `${environment.api}/patients/${patientId}/budget-caregivers`,
      {
        responseType: 'text',
        params: {
          budgetDate,
          budgetType,
        },
      }
    )
  }

  public getPersplanMonth(
    patientId: string,
    budgetMonth: string,
    budgetYear: string,
    withFunction: boolean
  ): Observable<string> {
    return this.http.get(
      `${environment.api}/patients/${patientId}/budget-month`,
      {
        responseType: 'text',
        params: {
          budgetMonth,
          budgetYear,
          withFunction: withFunction.toString(),
        },
      }
    )
  }

  public getPersplanMonthForAppointmentSending(
    patientId: string,
    budgetMonth: string,
    budgetYear: string,
    withFunction: boolean
  ): Observable<string> {
    return this.http.get(
      `${environment.api}/patients/${patientId}/budget-month-appointment-sending`,
      {
        responseType: 'text',
        params: {
          budgetMonth,
          budgetYear,
          withFunction: withFunction.toString(),
        },
      }
    )
  }

  public getPersplanMonthForSelect(
    patientId: string,
    budgetMonth: string,
    budgetYear: string
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/patients/${patientId}/budget-month-select`,
      {
        params: {
          budgetMonth,
          budgetYear,
        },
      }
    )
  }

  public getBudgetOverwritten(
    patientId: number | null,
    budgetDate: string,
    budgetType: string
  ): Observable<string> {
    return this.http.get(
      `${environment.api}/patients/${patientId}/budget-overwritten`,
      {
        responseType: 'text',
        params: {
          budgetDate,
          budgetType,
        },
      }
    )
  }

  public history(id: string | null): Observable<PatientDetailHistoryModel> {
    return this.http.get<PatientDetailHistoryModel>(
      `${environment.api}/patients/${id}/history`
    )
  }

  public historyImportant(id: string | null): Observable<HistoryListModel[]> {
    return this.http.get<HistoryListModel[]>(
      `${environment.api}/patients/${id}/history-important`
    )
  }

  public historyPlanning(
    id: string | null
  ): Observable<PatientDetailHistoryModel> {
    return this.http.get<PatientDetailHistoryModel>(
      `${environment.api}/patients/${id}/history-planning`
    )
  }

  public details(id: string | null): Observable<CustomerDetailModel> {
    return this.http.get<CustomerDetailModel>(
      `${environment.api}/patients/${id}/details`
    )
  }

  public documents(
    id: string | null,
    onlyArchived: boolean = false,
    listView: boolean = false
  ): Observable<CustomerDetailDocumentsModel> {
    return this.http.get<CustomerDetailDocumentsModel>(
      `${environment.api}/patients/${id}/documents?only_archived=${onlyArchived}&list_view=${listView}`
    )
  }

  public changeStatus(values: any): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/patients/change-status`, {
      ...values,
    })
  }

  public lockAppointments(id: number | undefined): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/lock-appointments/patients/${id}/lock`,
      {}
    )
  }

  public unlockAppointments(
    id: number | undefined,
    values: any
  ): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/lock-appointments/patients/${id}/unlock`,
      {
        ...values,
      }
    )
  }

  public pause(values: any): Observable<[]> {
    return this.http.put<[]>(`${environment.api}/patients/pause`, {
      ...values,
    })
  }

  public reactivate(values: any): Observable<[]> {
    return this.http.put<[]>(`${environment.api}/patients/reactivate`, {
      ...values,
    })
  }

  public getConsultingPdfPreviewLink(patientId: number | null): string {
    // const params = new HttpParams({ fromObject: values }).toString()

    return `${environment.api}/consulting/${patientId}/preview`
  }

  public pauseEdit(
    currentPause: PatientPauseModel,
    values: any
  ): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/patients/pause/${currentPause.id}`,
      {
        ...values,
      }
    )
  }

  public reactivateEdit(
    currentPause: PatientPauseModel,
    values: any
  ): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/patients/reactivate/${currentPause.id}`,
      {
        ...values,
      }
    )
  }

  public getDocumentDownloadLink(uuid: string): string {
    return `${environment.api}/documents/${uuid}`
  }
}
