<app-loader [loading]="loading"></app-loader>

<div class="content new-content-container" *ngIf="!loading">
  <header class="content-header">
    <span *ngIf="patients[selectedPatient].degree_of_care">
      PG {{ patients[selectedPatient].degree_of_care }} seit
      {{ patients[selectedPatient].degree_of_care_since_string }}
    </span>

    <div class="content-header-buttons">
      <i
        (click)="goToPreviousYear()"
        style="margin: 0 10px 0 0"
        class="pi pi-chevron-left"
      ></i>

      <p-dropdown
        inputId="year"
        name="year"
        (onChange)="loadBudgets(false)"
        [options]="yearOptions"
        [(ngModel)]="year"
      ></p-dropdown>

      <i
        (click)="goToNextYear()"
        class="pi pi-chevron-right"
        style="margin: 0 0 0 10px"
      ></i>
    </div>
  </header>

  <div
    class="content-body"
    *ngIf="patients[this.selectedPatient].calculated_budgets.length === 0"
  >
    <div class="initial-documents-container">
      <button
        pButton
        (click)="openConfirmDialogDialog()"
        label="Budgets bestätigen"
        type="button"
        icon="pi pi-check-square"
        class="p-button p-button-info"
      ></button>
    </div>
  </div>

  <div
    class="patient-budget-container"
    *ngIf="patients[selectedPatient].calculated_budgets.length > 0"
  >
    <div class="patient-budget-types">
      <span style="height: 62px"></span>
      <span
        [class.highlight]="currentHover === 'selbstzahler'"
        (mouseleave)="setHover('')"
        (mouseenter)="setHover('selbstzahler')"
        ><i
          *ngIf="patients[selectedPatient].selbstzahler_budget_type"
          [pTooltip]="
            patients[selectedPatient].selbstzahler_budget_type +
            ' ' +
            (patients[selectedPatient].selbstzahler_budget_type === 'Max'
              ? patients[selectedPatient].selbstzahler_budget_max + ' Std'
              : '')
          "
          class="pi pi-info-circle"
        ></i>
        Selbstzahler
      </span>
      <span
        [class.highlight]="currentHover === 'sgb v'"
        (mouseleave)="setHover('')"
        (mouseenter)="setHover('sgb v')"
        >SGB V</span
      >
      <span
        [class.highlight]="currentHover === 'unfallkasse'"
        (mouseleave)="setHover('')"
        (mouseenter)="setHover('unfallkasse')"
        >Unfallkasse</span
      >
      <span
        [class.highlight]="currentHover === 'kostenträger'"
        (mouseleave)="setHover('')"
        (mouseenter)="setHover('kostenträger')"
      >
        <i
          *ngIf="patients[selectedPatient].kostentraeger_budget"
          [pTooltip]="patients[selectedPatient].kostentraeger_budget + ' Std'"
          class="pi pi-info-circle"
        ></i>
        Kostenträger</span
      >
      <span
        [class.highlight]="currentHover === '37'"
        (mouseleave)="setHover('')"
        (mouseenter)="setHover('37')"
        >§37</span
      >
      <span
        [class.highlight]="currentHover === '45a'"
        (mouseleave)="setHover('')"
        (mouseenter)="setHover('45a')"
        >§45a</span
      >
      <span
        [class.highlight]="currentHover === '45b alt'"
        (mouseleave)="setHover('')"
        (mouseenter)="setHover('45b alt')"
        >§45b ALT</span
      >
      <span
        [class.highlight]="currentHover === '45b laufend'"
        (mouseleave)="setHover('')"
        (mouseenter)="setHover('45b laufend')"
        >§45b LAUFEND</span
      >
      <span
        [class.highlight]="currentHover === '39'"
        (mouseleave)="setHover('')"
        (mouseenter)="setHover('39')"
      >
        <i
          *ngIf="!patients[selectedPatient].has_39_for_selected_year"
          pTooltip="Budget hinzufügen"
          class="pi pi-plus clickable"
          (click)="enableBudget('39')"
        ></i>
        §39
      </span>
      <span
        [class.highlight]="currentHover === '42'"
        (mouseleave)="setHover('')"
        (mouseenter)="setHover('42')"
      >
        <i
          *ngIf="
            !patients[selectedPatient].has_39_for_selected_year &&
            !patients[selectedPatient].has_42_for_selected_year
          "
          pTooltip="§39 muss vorher bestätigt werden"
          class="pi pi-plus"
          style="opacity: 0.7"
        ></i>
        <i
          *ngIf="
            patients[selectedPatient].has_39_for_selected_year &&
            !patients[selectedPatient].has_42_for_selected_year
          "
          (click)="enableBudget('42')"
          pTooltip="Budget hinzufügen"
          class="pi pi-plus clickable"
        ></i>
        §39 plus</span
      >
    </div>
    <div class="patient-budget-boxes">
      <div
        class="patient-budget-box"
        *ngFor="
          let calculatedBudget of patients[this.selectedPatient]
            .calculated_budgets;
          let i = index
        "
      >
        <div
          class="patient-budget-box-header"
          [class.current-month]="calculatedBudget.is_current_month"
        >
          <span>{{ calculatedBudget.title }}</span>
        </div>
        <div class="patient-budget-box-header">
          <span style="width: 30%">Budget</span>
          <span style="width: 50%">Leistung</span>
          <span style="width: 20%">Rest</span>
        </div>
        <div class="patient-budget-box-body">
          <div
            [class.finished]="
              calculatedBudget.budgets['SELBSTZAHLER']?.amount_hours_ist
            "
            [class.disabled]="!calculatedBudget.budgets['SELBSTZAHLER']"
            [class.highlight]="currentHover === 'selbstzahler'"
            (mouseleave)="setHover('')"
            (mouseenter)="setHover('selbstzahler')"
          >
            <span style="width: 30%">{{
              calculatedBudget.budgets["SELBSTZAHLER"] ? "" : "nicht relevant"
            }}</span>
            <span style="width: 25%">
              <i
                class="pi pi-check"
                pTooltip="Abgeschlossen (IST)"
                *ngIf="
                  calculatedBudget.budgets['SELBSTZAHLER']?.amount_hours_ist
                "
              ></i>

              <i
                tooltipPosition="bottom"
                [tooltipStyleClass]="
                  i < 10 ? 'tooltip-wider' : 'tooltip-wider-left'
                "
                [escape]="false"
                [pTooltip]="
                  budgetCaregivers[this.selectedPatient][
                    calculatedBudget.title + 'SELBSTZAHLER'
                  ]
                "
                (mouseenter)="
                  getPersplanCaregivers(calculatedBudget.title, 'SELBSTZAHLER')
                "
                class="pi pi-info-circle"
                *ngIf="
                  calculatedBudget.budgets['SELBSTZAHLER'] &&
                  calculatedBudget.budgets['SELBSTZAHLER']
                    ?.amount_hours_soll !== '0 Std'
                "
              ></i>
              {{
                calculatedBudget.budgets["SELBSTZAHLER"]?.amount_hours_real ||
                  ""
              }}
            </span>
            <span style="width: 25%">
              <i
                class="pi pi-info-circle"
                *ngIf="calculatedBudget.budgets['SELBSTZAHLER']"
                [pTooltip]="
                  calculatedBudget.budgets['SELBSTZAHLER'].current_price
                "
              ></i
              >{{ calculatedBudget.budgets["SELBSTZAHLER"]?.costs || "" }}</span
            >
            <span style="width: 20%"></span>
          </div>
          <div
            [class.finished]="
              calculatedBudget.budgets['SGB_V']?.amount_hours_ist
            "
            [class.disabled]="!calculatedBudget.budgets['SGB_V']"
            [class.highlight]="currentHover === 'sgb v'"
            (mouseleave)="setHover('')"
            (mouseenter)="setHover('sgb v')"
          >
            <span style="width: 30%">
              {{
                calculatedBudget.budgets["SGB_V"] ? "" : "nicht relevant"
              }}</span
            >
            <span style="width: 25%">
              <i
                class="pi pi-check"
                pTooltip="Abgeschlossen (IST)"
                *ngIf="calculatedBudget.budgets['SGB_V']?.amount_hours_ist"
              ></i>

              <i
                tooltipPosition="bottom"
                [tooltipStyleClass]="
                  i < 10 ? 'tooltip-wider' : 'tooltip-wider-left'
                "
                [escape]="false"
                [pTooltip]="
                  budgetCaregivers[this.selectedPatient][
                    calculatedBudget.title + 'SGB_V'
                  ]
                "
                (mouseenter)="
                  getPersplanCaregivers(calculatedBudget.title, 'SGB_V')
                "
                class="pi pi-info-circle"
                *ngIf="
                  calculatedBudget.budgets['SGB_V'] &&
                  calculatedBudget.budgets['SGB_V']?.amount_hours_soll !==
                    '0 Std'
                "
              ></i>
              {{
                calculatedBudget.budgets["SGB_V"]?.amount_hours_real || ""
              }}</span
            >
            <span style="width: 25%">
              <i
                class="pi pi-info-circle"
                *ngIf="calculatedBudget.budgets['SGB_V']"
                [pTooltip]="calculatedBudget.budgets['SGB_V'].current_price"
              ></i
              >{{ calculatedBudget.budgets["SGB_V"]?.costs || "" }}</span
            >
            <span style="width: 20%"></span>
          </div>
          <div
            [class.finished]="
              calculatedBudget.budgets['UNFALLKASSE']?.amount_hours_ist
            "
            [class.disabled]="!calculatedBudget.budgets['UNFALLKASSE']"
            [class.highlight]="currentHover === 'unfallkasse'"
            (mouseleave)="setHover('')"
            (mouseenter)="setHover('unfallkasse')"
          >
            <span style="width: 30%">
              {{
                calculatedBudget.budgets["UNFALLKASSE"] ? "" : "nicht relevant"
              }}</span
            >
            <span style="width: 25%">
              <i
                class="pi pi-check"
                pTooltip="Abgeschlossen (IST)"
                *ngIf="
                  calculatedBudget.budgets['UNFALLKASSE']?.amount_hours_ist
                "
              ></i>

              <i
                tooltipPosition="bottom"
                [tooltipStyleClass]="
                  i < 10 ? 'tooltip-wider' : 'tooltip-wider-left'
                "
                [escape]="false"
                [pTooltip]="
                  budgetCaregivers[this.selectedPatient][
                    calculatedBudget.title + 'UNFALLKASSE'
                  ]
                "
                (mouseenter)="
                  getPersplanCaregivers(calculatedBudget.title, 'UNFALLKASSE')
                "
                class="pi pi-info-circle"
                *ngIf="
                  calculatedBudget.budgets['UNFALLKASSE'] &&
                  calculatedBudget.budgets['UNFALLKASSE']?.amount_hours_soll !==
                    '0 Std'
                "
              ></i>
              {{
                calculatedBudget.budgets["UNFALLKASSE"]?.amount_hours_real || ""
              }}</span
            >
            <span style="width: 25%">
              <i
                class="pi pi-info-circle"
                *ngIf="calculatedBudget.budgets['UNFALLKASSE']"
                [pTooltip]="
                  calculatedBudget.budgets['UNFALLKASSE'].current_price
                "
              ></i
              >{{ calculatedBudget.budgets["UNFALLKASSE"]?.costs || "" }}</span
            >
            <span style="width: 20%"></span>
          </div>
          <div
            [class.finished]="
              calculatedBudget.budgets['KOSTENTRAEGER']?.amount_hours_ist
            "
            [class.disabled]="!calculatedBudget.budgets['KOSTENTRAEGER']"
            [class.highlight]="currentHover === 'kostenträger'"
            (mouseleave)="setHover('')"
            (mouseenter)="setHover('kostenträger')"
          >
            <span style="width: 30%">
              {{
                calculatedBudget.budgets["KOSTENTRAEGER"]
                  ? ""
                  : "nicht relevant"
              }}</span
            >
            <span style="width: 25%">
              <i
                class="pi pi-check"
                pTooltip="Abgeschlossen (IST)"
                *ngIf="
                  calculatedBudget.budgets['KOSTENTRAEGER']?.amount_hours_ist
                "
              ></i>

              <i
                tooltipPosition="bottom"
                [tooltipStyleClass]="
                  i < 10 ? 'tooltip-wider' : 'tooltip-wider-left'
                "
                [escape]="false"
                [pTooltip]="
                  budgetCaregivers[this.selectedPatient][
                    calculatedBudget.title + 'KOSTENTRAEGER'
                  ]
                "
                (mouseenter)="
                  getPersplanCaregivers(calculatedBudget.title, 'KOSTENTRAEGER')
                "
                class="pi pi-info-circle"
                *ngIf="
                  calculatedBudget.budgets['KOSTENTRAEGER'] &&
                  calculatedBudget.budgets['KOSTENTRAEGER']
                    ?.amount_hours_soll !== '0 Std'
                "
              ></i>
              {{
                calculatedBudget.budgets["KOSTENTRAEGER"]?.amount_hours_real ||
                  ""
              }}</span
            >
            <span style="width: 25%">
              <i
                class="pi pi-info-circle"
                *ngIf="calculatedBudget.budgets['KOSTENTRAEGER']"
                [pTooltip]="
                  calculatedBudget.budgets['KOSTENTRAEGER'].current_price
                "
              ></i
              >{{
                calculatedBudget.budgets["KOSTENTRAEGER"]?.costs || ""
              }}</span
            >
            <span style="width: 20%"></span>
          </div>
          <div
            [class.finished]="calculatedBudget.budgets['37']?.amount_hours_ist"
            [class.disabled]="!calculatedBudget.budgets['37']"
            [class.highlight]="currentHover === '37'"
            (mouseleave)="setHover('')"
            (mouseenter)="setHover('37')"
          >
            <span style="width: 30%">
              <i
                (click)="
                  openChangeBudgetDialog(
                    calculatedBudget.budgets['37'],
                    '§37',
                    calculatedBudget.title
                  )
                "
                pTooltip="Budget bearbeiten"
                *ngIf="calculatedBudget.budgets['37']?.is_changeable"
                class="pi pi-pencil"
              ></i>

              <i
                [pTooltip]="
                  budgetOverwritten[this.selectedPatient][
                    calculatedBudget.title + '37'
                  ]
                "
                (mouseenter)="
                  getBudgetOverwritten(calculatedBudget.title, '37')
                "
                *ngIf="calculatedBudget.budgets['37']?.is_overwritten"
                class="pi pi-comment color-blue"
              ></i>
              {{
                calculatedBudget.budgets["37"]?.budget_start || "nicht relevant"
              }}</span
            >
            <span style="width: 25%">
              <i
                class="pi pi-check"
                pTooltip="Abgeschlossen (IST)"
                *ngIf="calculatedBudget.budgets['37']?.amount_hours_ist"
              ></i>

              <i
                tooltipPosition="bottom"
                [tooltipStyleClass]="
                  i < 10 ? 'tooltip-wider' : 'tooltip-wider-left'
                "
                [escape]="false"
                [pTooltip]="
                  budgetCaregivers[this.selectedPatient][
                    calculatedBudget.title + '37'
                  ]
                "
                (mouseenter)="
                  getPersplanCaregivers(calculatedBudget.title, '37')
                "
                class="pi pi-info-circle"
                *ngIf="
                  calculatedBudget.budgets['37'] &&
                  calculatedBudget.budgets['37']?.amount_hours_soll !== '0 Std'
                "
              ></i>
              {{
                calculatedBudget.budgets["37"]?.amount_hours_real || ""
              }}</span
            >
            <span style="width: 25%">
              <i
                class="pi pi-info-circle"
                *ngIf="calculatedBudget.budgets['37']"
                [pTooltip]="calculatedBudget.budgets['37'].current_price"
              ></i
              >{{ calculatedBudget.budgets["37"]?.costs || "" }}</span
            >
            <span style="width: 20%">{{
              calculatedBudget.budgets["37"]?.budget_end || ""
            }}</span>
          </div>
          <div
            [class.disabled]="!calculatedBudget.budgets['45a']"
            [class.finished]="calculatedBudget.budgets['45a']?.amount_hours_ist"
            [class.highlight]="currentHover === '45a'"
            (mouseleave)="setHover('')"
            (mouseenter)="setHover('45a')"
          >
            <span style="width: 30%">
              <i
                (click)="
                  openChangeBudgetDialog(
                    calculatedBudget.budgets['45a'],
                    '§45a',
                    calculatedBudget.title
                  )
                "
                pTooltip="Budget bearbeiten"
                *ngIf="calculatedBudget.budgets['45a']?.is_changeable"
                class="pi pi-pencil"
              ></i>

              <i
                [pTooltip]="
                  budgetOverwritten[this.selectedPatient][
                    calculatedBudget.title + '45a'
                  ]
                "
                (mouseenter)="
                  getBudgetOverwritten(calculatedBudget.title, '45a')
                "
                *ngIf="calculatedBudget.budgets['45a']?.is_overwritten"
                class="pi pi-comment color-blue"
              ></i>

              {{
                calculatedBudget.budgets["45a"]?.budget_start ||
                  "nicht relevant"
              }}</span
            >
            <span style="width: 25%">
              <i
                class="pi pi-check"
                pTooltip="Abgeschlossen (IST)"
                *ngIf="calculatedBudget.budgets['45a']?.amount_hours_ist"
              ></i>

              <i
                tooltipPosition="bottom"
                [tooltipStyleClass]="
                  i < 10 ? 'tooltip-wider' : 'tooltip-wider-left'
                "
                [escape]="false"
                [pTooltip]="
                  budgetCaregivers[this.selectedPatient][
                    calculatedBudget.title + '45a'
                  ]
                "
                (mouseenter)="
                  getPersplanCaregivers(calculatedBudget.title, '45a')
                "
                class="pi pi-info-circle"
                *ngIf="
                  calculatedBudget.budgets['45a'] &&
                  calculatedBudget.budgets['45a']?.amount_hours_soll !== '0 Std'
                "
              ></i>
              {{
                calculatedBudget.budgets["45a"]?.amount_hours_real || ""
              }}</span
            >
            <span style="width: 25%">
              <i
                class="pi pi-info-circle"
                *ngIf="calculatedBudget.budgets['45a']"
                [pTooltip]="calculatedBudget.budgets['45a'].current_price"
              ></i
              >{{ calculatedBudget.budgets["45a"]?.costs || "" }}</span
            >
            <span style="width: 20%">
              {{ calculatedBudget.budgets["45a"]?.budget_end || "" }}</span
            >
          </div>
          <div
            [class.finished]="
              calculatedBudget.budgets['45b_OLD']?.amount_hours_ist
            "
            [class.disabled]="!calculatedBudget.budgets['45b_OLD']"
            [class.highlight]="currentHover === '45b alt'"
            (mouseleave)="setHover('')"
            (mouseenter)="setHover('45b alt')"
          >
            <span style="width: 30%">
              <i
                (click)="
                  openChangeBudgetDialog(
                    calculatedBudget.budgets['45b_OLD'],
                    '§45b ALT',
                    calculatedBudget.title
                  )
                "
                pTooltip="Budget bearbeiten"
                *ngIf="calculatedBudget.budgets['45b_OLD']?.is_changeable"
                class="pi pi-pencil"
              ></i>

              <i
                [pTooltip]="
                  budgetOverwritten[this.selectedPatient][
                    calculatedBudget.title + '45b ALT'
                  ]
                "
                (mouseenter)="
                  getBudgetOverwritten(calculatedBudget.title, '45b ALT')
                "
                *ngIf="calculatedBudget.budgets['45b_OLD']?.is_overwritten"
                class="pi pi-comment color-blue"
              ></i>

              {{
                calculatedBudget.budgets["45b_OLD"]?.budget_start ||
                  "nicht relevant"
              }}</span
            >
            <span style="width: 25%">
              <i
                class="pi pi-check"
                pTooltip="Abgeschlossen (IST)"
                *ngIf="calculatedBudget.budgets['45b_OLD']?.amount_hours_ist"
              ></i>

              <i
                tooltipPosition="bottom"
                [tooltipStyleClass]="
                  i < 10 ? 'tooltip-wider' : 'tooltip-wider-left'
                "
                [escape]="false"
                [pTooltip]="
                  budgetCaregivers[this.selectedPatient][
                    calculatedBudget.title + '45b_OLD'
                  ]
                "
                (mouseenter)="
                  getPersplanCaregivers(calculatedBudget.title, '45b_OLD')
                "
                class="pi pi-info-circle"
                *ngIf="
                  calculatedBudget.budgets['45b_OLD'] &&
                  calculatedBudget.budgets['45b_OLD']?.amount_hours_soll !==
                    '0 Std'
                "
              ></i>
              {{
                calculatedBudget.budgets["45b_OLD"]?.amount_hours_real || ""
              }}</span
            >
            <span style="width: 25%">
              <i
                class="pi pi-info-circle"
                *ngIf="calculatedBudget.budgets['45b_OLD']"
                [pTooltip]="calculatedBudget.budgets['45b_OLD'].current_price"
              ></i
              >{{ calculatedBudget.budgets["45b_OLD"]?.costs || "" }}</span
            >
            <span style="width: 20%">{{
              calculatedBudget.budgets["45b_OLD"]?.budget_end || ""
            }}</span>
          </div>
          <div
            [class.finished]="
              calculatedBudget.budgets['45b_CURRENT']?.amount_hours_ist
            "
            [class.disabled]="!calculatedBudget.budgets['45b_CURRENT']"
            [class.highlight]="currentHover === '45b laufend'"
            (mouseleave)="setHover('')"
            (mouseenter)="setHover('45b laufend')"
          >
            <span style="width: 30%">
              <i
                (click)="
                  openChangeBudgetDialog(
                    calculatedBudget.budgets['45b_CURRENT'],
                    '§45b LAUFEND',
                    calculatedBudget.title
                  )
                "
                pTooltip="Budget bearbeiten"
                *ngIf="calculatedBudget.budgets['45b_CURRENT']?.is_changeable"
                class="pi pi-pencil"
              ></i>

              <i
                [pTooltip]="
                  budgetOverwritten[this.selectedPatient][
                    calculatedBudget.title + '45b LAUFEND'
                  ]
                "
                (mouseenter)="
                  getBudgetOverwritten(calculatedBudget.title, '45b LAUFEND')
                "
                *ngIf="calculatedBudget.budgets['45b_CURRENT']?.is_overwritten"
                class="pi pi-comment color-blue"
              ></i>
              {{
                calculatedBudget.budgets["45b_CURRENT"]?.budget_start ||
                  "nicht relevant"
              }}</span
            >
            <span style="width: 25%">
              <i
                class="pi pi-check"
                pTooltip="Abgeschlossen (IST)"
                *ngIf="
                  calculatedBudget.budgets['45b_CURRENT']?.amount_hours_ist
                "
              ></i>

              <i
                tooltipPosition="bottom"
                [tooltipStyleClass]="
                  i < 10 ? 'tooltip-wider' : 'tooltip-wider-left'
                "
                [escape]="false"
                [pTooltip]="
                  budgetCaregivers[this.selectedPatient][
                    calculatedBudget.title + '45b_CURRENT'
                  ]
                "
                (mouseenter)="
                  getPersplanCaregivers(calculatedBudget.title, '45b_CURRENT')
                "
                class="pi pi-info-circle"
                *ngIf="
                  calculatedBudget.budgets['45b_CURRENT'] &&
                  calculatedBudget.budgets['45b_CURRENT']?.amount_hours_soll !==
                    '0 Std'
                "
              ></i>
              {{
                calculatedBudget.budgets["45b_CURRENT"]?.amount_hours_real || ""
              }}</span
            >
            <span style="width: 25%"
              ><i
                class="pi pi-info-circle"
                *ngIf="calculatedBudget.budgets['45b_CURRENT']"
                [pTooltip]="
                  calculatedBudget.budgets['45b_CURRENT'].current_price
                "
              ></i
              >{{ calculatedBudget.budgets["45b_CURRENT"]?.costs || "" }}</span
            >
            <span style="width: 20%">{{
              calculatedBudget.budgets["45b_CURRENT"]?.budget_end || ""
            }}</span>
          </div>
          <div
            [class.finished]="calculatedBudget.budgets['39']?.amount_hours_ist"
            [class.disabled]="!calculatedBudget.budgets['39']"
            [class.highlight]="currentHover === '39'"
            (mouseleave)="setHover('')"
            (mouseenter)="setHover('39')"
          >
            <span style="width: 30%">
              <i
                (click)="
                  openChangeBudgetDialog(
                    calculatedBudget.budgets['39'],
                    '§39',
                    calculatedBudget.title
                  )
                "
                pTooltip="Budget bearbeiten"
                *ngIf="calculatedBudget.budgets['39']?.is_changeable"
                class="pi pi-pencil"
              ></i>

              <i
                [pTooltip]="
                  budgetOverwritten[this.selectedPatient][
                    calculatedBudget.title + '39'
                  ]
                "
                (mouseenter)="
                  getBudgetOverwritten(calculatedBudget.title, '39')
                "
                *ngIf="calculatedBudget.budgets['39']?.is_overwritten"
                class="pi pi-comment color-blue"
              ></i>

              {{
                calculatedBudget.budgets["39"]?.budget_start || "nicht relevant"
              }}</span
            >
            <span style="width: 25%">
              <i
                class="pi pi-check"
                pTooltip="Abgeschlossen (IST)"
                *ngIf="calculatedBudget.budgets['39']?.amount_hours_ist"
              ></i>

              <i
                tooltipPosition="bottom"
                [tooltipStyleClass]="
                  i < 10 ? 'tooltip-wider' : 'tooltip-wider-left'
                "
                [escape]="false"
                [pTooltip]="
                  budgetCaregivers[this.selectedPatient][
                    calculatedBudget.title + '39'
                  ]
                "
                (mouseenter)="
                  getPersplanCaregivers(calculatedBudget.title, '39')
                "
                class="pi pi-info-circle"
                *ngIf="
                  calculatedBudget.budgets['39'] &&
                  calculatedBudget.budgets['39']?.amount_hours_soll !== '0 Std'
                "
              ></i>
              {{
                calculatedBudget.budgets["39"]?.amount_hours_real || ""
              }}</span
            >
            <span style="width: 25%">
              <i
                class="pi pi-info-circle"
                *ngIf="calculatedBudget.budgets['39']"
                [pTooltip]="calculatedBudget.budgets['39'].current_price"
              ></i
              >{{ calculatedBudget.budgets["39"]?.costs || "" }}</span
            >
            <span style="width: 20%">{{
              calculatedBudget.budgets["39"]?.budget_end || ""
            }}</span>
          </div>
          <div
            [class.finished]="calculatedBudget.budgets['42']?.amount_hours_ist"
            [class.disabled]="!calculatedBudget.budgets['42']"
            [class.highlight]="currentHover === '42'"
            (mouseleave)="setHover('')"
            (mouseenter)="setHover('42')"
          >
            <span style="width: 30%">
              <i
                (click)="
                  openChangeBudgetDialog(
                    calculatedBudget.budgets['42'],
                    '§42',
                    calculatedBudget.title
                  )
                "
                pTooltip="Budget bearbeiten"
                *ngIf="calculatedBudget.budgets['42']?.is_changeable"
                class="pi pi-pencil"
              ></i>

              <i
                [pTooltip]="
                  budgetOverwritten[this.selectedPatient][
                    calculatedBudget.title + '42'
                  ]
                "
                (mouseenter)="
                  getBudgetOverwritten(calculatedBudget.title, '42')
                "
                *ngIf="calculatedBudget.budgets['42']?.is_overwritten"
                class="pi pi-comment color-blue"
              ></i>
              {{
                calculatedBudget.budgets["42"]?.budget_start || "nicht relevant"
              }}</span
            >
            <span style="width: 25%">
              <i
                class="pi pi-check"
                pTooltip="Abgeschlossen (IST)"
                *ngIf="calculatedBudget.budgets['42']?.amount_hours_ist"
              ></i>

              <i
                tooltipPosition="bottom"
                [tooltipStyleClass]="
                  i < 10 ? 'tooltip-wider' : 'tooltip-wider-left'
                "
                [escape]="false"
                [pTooltip]="
                  budgetCaregivers[this.selectedPatient][
                    calculatedBudget.title + '42'
                  ]
                "
                (mouseenter)="
                  getPersplanCaregivers(calculatedBudget.title, '42')
                "
                class="pi pi-info-circle"
                *ngIf="
                  calculatedBudget.budgets['42'] &&
                  calculatedBudget.budgets['42']?.amount_hours_soll !== '0 Std'
                "
              ></i>
              {{
                calculatedBudget.budgets["42"]?.amount_hours_real || ""
              }}</span
            >
            <span style="width: 25%">
              <i
                class="pi pi-info-circle"
                *ngIf="calculatedBudget.budgets['42']"
                [pTooltip]="calculatedBudget.budgets['42'].current_price"
              ></i
              >{{ calculatedBudget.budgets["42"]?.costs || "" }}</span
            >
            <span style="width: 20%">{{
              calculatedBudget.budgets["42"]?.budget_end || ""
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
