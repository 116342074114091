<div class="content-actions">
  <i class="tio icon-large">time</i>

  <h2>
    <i class="tio">time</i>
    Planung
  </h2>
</div>

<header class="content-header-tabs">
  <a
    *ngIf="authService.can('Betreuungskraft.Liste: Planung')"
    routerLink="/plannings/change-required"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Umplanungen
  </a>
  <a
    *ngIf="authService.can('Betreuungskraft.Globale Urlaubsplanung einsehen')"
    routerLink="/plannings/vacation"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Urlaubsplanung
  </a>
</header>

<router-outlet></router-outlet>
