import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { SearchService } from '../../../services/search.service'
import { PatientService } from '../../../services/patient.service'
import { ConfirmationService } from 'primeng/api'
import { HelperService } from '../../../services/helper.service'
import { PatientPriceService } from '../../../services/patient-price.service'

@Component({
  selector: 'app-change-patient-price-dialog',
  templateUrl: './change-patient-price-dialog.component.html',
})
export class ChangePatientPriceDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  public patients: any = []

  public isEdit = false
  public currentSystemPrice = ''

  public monthOptions: any = []
  public yearOptions = [
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
    { label: '2025', value: '2025' },
  ]

  public data: any = {}
  public values = {
    id: null,
    comment: '',
    price: '',
    since: '',
    use_system_price: false,
    month: '',
    year: '',
  }

  public submitted = false
  public submittedDelete = false

  constructor(
    private ref: DynamicDialogRef,
    private searchService: SearchService,
    private config: DynamicDialogConfig,
    private patientPriceService: PatientPriceService,
    private eventbus: EventBusService,
    private helperService: HelperService,
    private toastService: ToastService,
    private patientService: PatientService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.data = this.config.data

    const date = new Date()
    let currentYear = date.getFullYear()
    let nextMonth = date.getMonth() + 1

    // Wenn der aktuelle Monat einen index von 11 hatte (also bereits Dezember),
    // müssen wir den nächsten Monat auf Januar (also index 0) setzen und das Jahr erhöhen.
    if (nextMonth === 12) {
      nextMonth = 0
      currentYear++
    }

    this.helperService.dependencies$.subscribe((data: any) => {
      this.currentSystemPrice = data.current_system_price
      this.monthOptions = data.months
      this.values.month = this.monthOptions[nextMonth].label
      this.values.year = currentYear.toString()
    })

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public store(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    const subscription = this.isEdit
      ? this.patientPriceService.store(this.data.patient.id, this.values)
      : this.patientPriceService.store(this.data.patient.id, this.values)

    subscription.subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.PatientStatusChanged)
        this.ref.close()
        this.toastService.success(
          'Preis gespeichert',
          'Der Preis wurde erfolgreich gespeichert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }
}
