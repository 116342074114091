import { AppointmentLockModel } from '../appointments/appointment-lock.model'

export class CustomerDetailBudgetModel {
  first_patient: PatientDetailBudgetModel = new PatientDetailBudgetModel()
  second_patient: PatientDetailBudgetModel = new PatientDetailBudgetModel()
}

export class PatientDetailBudgetModel {
  patient_type = ''
  full_name = ''
  persplan_updated_at: Date | null = null
  id = 0
  status = ''
  status_string = ''
  degree_of_care = ''
  selbstzahler_budget_max = null
  selbstzahler_budget_type = ''
  kostentraeger_budget = ''
  degree_of_care_since_string = ''
  is_paused = false
  calculated_budgets: PatientCalculatedBudgets[] = []
  appointment_lock: AppointmentLockModel | null = null
  has_42_for_selected_year = false
  has_39_for_selected_year = false
}

export class PatientCalculatedBudgets {
  title = ''
  is_current_month = false
  budgets = new PatientBudgets()
}

export class PatientBudgets {
  'SELBSTZAHLER'? = new PatientBudget()
  'KOSTENTRAEGER'? = new PatientBudget()
  'SGB_V'? = new PatientBudget()
  'UNFALLKASSE'? = new PatientBudget()
  '37'? = new PatientBudget()
  '39'? = new PatientBudget()
  '42'? = new PatientBudget()
  '45a'? = new PatientBudget()
  '45b_OLD'? = new PatientBudget()
  '45b_CURRENT'? = new PatientBudget()
}

export class PatientBudget {
  budget_start_original = ''
  budget_start = ''
  budget_end = ''
  amount_hours_real = ''
  amount_hours_soll = ''
  amount_hours_ist = ''
  is_overwritten = false
  is_changeable = false
  costs = ''
  current_price = ''
}
