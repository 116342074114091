<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-6">
        <label for="month">Ab dem Monat *</label>
        <p-dropdown
          appendTo="body"
          scrollHeight="500px"
          inputId="month"
          name="month"
          [options]="monthOptions"
          [(ngModel)]="values.month"
        ></p-dropdown>
      </div>

      <div class="p-field p-col-6">
        <label for="year">Jahr *</label>
        <p-dropdown
          inputId="year"
          name="year"
          [options]="yearOptions"
          [(ngModel)]="values.year"
        ></p-dropdown>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label
          for="price"
          style="display: flex; justify-content: space-between"
        >
          Preis *

          <div class="p-formgroup-inline">
            <div class="p-field-checkbox" style="margin: 0 !important">
              <p-checkbox
                name="use_system_price"
                [binary]="true"
                [tabindex]="-1"
                label="Systempreis"
                [(ngModel)]="values.use_system_price"
              ></p-checkbox>
            </div>
          </div>
        </label>

        <strong *ngIf="values.use_system_price">{{
          currentSystemPrice
        }}</strong>

        <p-inputNumber
          *ngIf="!values.use_system_price"
          mode="currency"
          currency="EUR"
          locale="de-DE"
          inputId="price"
          name="price"
          [(ngModel)]="values.price"
          [required]="true"
          #price="ngModel"
          [class.p-invalid]="price.invalid && (price.dirty || price.touched)"
        ></p-inputNumber>
      </div>
    </div>

    <div class="p-field">
      <label for="comment">Bemerkung</label>
      <textarea
        id="comment"
        name="comment"
        [(ngModel)]="values.comment"
        [rows]="3"
        pInputTextarea
      ></textarea>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i></i>

    <button
      (click)="store()"
      pButton
      label="Speichern"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
