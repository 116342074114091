<div class="content-actions">
  <i class="tio icon-large">quarantine_outlined</i>

  <h2>
    <i class="tio">quarantine_outlined</i>
    Multiplikatoren
  </h2>
</div>

<app-loader [loading]="loading"></app-loader>

<div class="content new-content-container" style="margin: 0" *ngIf="!loading">
  <header class="content-header">
    <span>Alle Multiplikatoren</span>

    <div class="content-header-buttons">
      <a routerLink="/multipliers/new">
        <button
          pButton
          label="Neuer Multiplikator"
          type="button"
          icon="pi pi-plus"
          iconPos="left"
          class="p-button-outlined"
        ></button>
      </a>

      <div
        class="table-search-container"
        [class.searchfield-active]="globalFilterValue != ''"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [(ngModel)]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin-bottom: 0" *ngIf="!loading">
  <div class="content-body content-body-almost-full-height without-padding">
    <p-table
      #dt
      [value]="multipliers"
      [globalFilterFields]="[
        'id',
        'name',
        'zipcode',
        'city',
        'disciplines_as_string',
        'pflege_ik',
      ]"
      [rowHover]="true"
      [paginator]="true"
      scrollHeight="100%"
      [scrollable]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Multiplikatoren"
      [rows]="50"
      [(first)]="firstPage"
      stateStorage="local"
      stateKey="state-multipliers-list-2"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="min-width: 300px" pSortableColumn="name">
            Name <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="zipcode">
            Adresse <p-sortIcon field="zipcode"></p-sortIcon>
          </th>
          <th pSortableColumn="phone_central">
            Telefon <p-sortIcon field="phone_central"></p-sortIcon>
          </th>
          <th pSortableColumn="disciplines_as_string">
            Kategorien <p-sortIcon field="disciplines_as_string"></p-sortIcon>
          </th>
          <th style="max-width: 150px" pSortableColumn="deleted_at">
            Status <p-sortIcon field="deleted_at"></p-sortIcon>
          </th>
          <th style="max-width: 150px" pSortableColumn="user">
            Account <p-sortIcon field="user"></p-sortIcon>
          </th>
          <th
            *ngIf="isSuperAdmin"
            pSortableColumn="user.last_activity.created_at"
          >
            Letzte Aktivität
            <p-sortIcon field="user.last_activity.created_at"></p-sortIcon>
          </th>
          <th pSortableColumn="notice">
            Notiz <p-sortIcon field="notice"></p-sortIcon>
          </th>
          <th style="max-width: 100px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-multiplier>
        <tr>
          <td style="min-width: 300px">
            <a
              class="color-main1"
              routerLink="/multipliers/{{ multiplier.id }}"
            >
              {{ multiplier.name }}
            </a>
          </td>
          <td>{{ multiplier.zipcode }} {{ multiplier.city }}</td>
          <td>
            <div style="display: flex; flex-direction: column">
              <span *ngIf="multiplier.phone_central">
                <a
                  (click)="
                    phoneCallService.createCalling(multiplier.phone_central)
                  "
                  href="tel:{{ multiplier.phone_central }}"
                  >{{ multiplier.phone_central }}</a
                >
              </span>
              <span *ngIf="multiplier.mobile">
                <a
                  (click)="phoneCallService.createCalling(multiplier.mobile)"
                  href="tel:{{ multiplier.mobile }}"
                  >{{ multiplier.mobile }}</a
                >
              </span>
            </div>
          </td>
          <td>
            {{ multiplier.disciplines_as_string }}
          </td>
          <td style="max-width: 150px">
            <span
              *ngIf="multiplier.deleted_at"
              class="p-tag p-tag-danger p-tag-rounded"
              >Inaktiv</span
            >
            <span *ngIf="!multiplier.deleted_at" class="p-tag p-tag-rounded"
              >Aktiv</span
            >
          </td>
          <td style="max-width: 150px">
            <span
              *ngIf="multiplier.user?.deleted_at"
              class="p-tag p-tag-danger p-tag-rounded"
              >Inaktiv</span
            >
            <span
              *ngIf="multiplier.user && !multiplier.user.deleted_at"
              class="p-tag p-tag-rounded"
              >Aktiv</span
            >
          </td>
          <td *ngIf="isSuperAdmin">
            <div *ngIf="multiplier.user?.last_activity">
              {{
                multiplier.user.last_activity.created_at
                  | date: "dd.MM.y, H:mm:ss"
              }}
            </div>
          </td>
          <td>
            <i
              *ngIf="multiplier.notice"
              class="pi pi-info-circle color-gray"
              style="font-size: 13px"
              [pTooltip]="multiplier.notice"
            ></i>
          </td>
          <td class="table-action" style="max-width: 100px">
            <i
              class="pi pi-lock"
              *ngIf="isSuperAdmin && multiplier.user"
              (click)="loginUsingId(multiplier.user_id)"
              pTooltip="Als Benutzer einloggen"
              tooltipPosition="left"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
