<app-loader [loading]="loading"></app-loader>

<div class="content new-content-container" *ngIf="!loading">
  <header class="content-header" *ngIf="data.length > 0">
    <span>Offene Rechnungen</span>

    <div
      class="table-search-container"
      [class.searchfield-active]="globalFilterValue != ''"
      *ngIf="data.length > 0"
    >
      <div class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pInputText
          (input)="filterTableGlobal($event)"
          placeholder="Suche..."
          [(ngModel)]="globalFilterValue"
        />
      </div>
    </div>
  </header>

  <div class="content-body" *ngIf="data.length == 0">
    <span class="content-no-message">Keine offenen Rechnungen vorhanden</span>
  </div>

  <div
    class="content-body content-body-full-height-with-subtabs without-padding"
    *ngIf="data.length > 0"
  >
    <p-table
      #dt
      [value]="data"
      [globalFilterFields]="[
        'invoiceable_id',
        'invoiceable.full_name',
        'invoice_number_complete'
      ]"
      [rowHover]="true"
      stateStorage="local"
      stateKey="invoices-km-opened-4"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Einträge"
      [rows]="50"
      [paginator]="true"
      [(first)]="firstPage"
      scrollHeight="100%"
      [scrollable]="true"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th pSortableColumn="invoice_number">
            Rechnungsnummer
            <p-sortIcon field="invoice_number"></p-sortIcon>
          </th>
          <th pSortableColumn="invoiceable.last_name">
            Patient <p-sortIcon field="invoiceable.last_name"></p-sortIcon>
          </th>
          <th>Anzahl fahrten</th>
          <th pSortableColumn="trips_mileage_sum">
            Anzahl Kilometer
            <p-sortIcon field="trips_mileage_sum"></p-sortIcon>
          </th>
          <th pSortableColumn="total_price">
            Gesamtkosten
            <p-sortIcon field="total_price"></p-sortIcon>
          </th>
          <th style="max-width: 100px" pSortableColumn="part_payments_sum">
            Bezahlt
            <p-sortIcon field="part_payments_sum"></p-sortIcon>
          </th>
          <th pSortableColumn="opened_at">
            Datum
            <p-sortIcon field="opened_at"></p-sortIcon>
          </th>
          <th style="max-width: 120px" pSortableColumn="last_reminder.type">
            Mahnung
            <p-sortIcon field="last_reminder.type"></p-sortIcon>
          </th>
          <th
            style="max-width: 120px"
            pSortableColumn="just_reminder_date_open"
          >
            Erinnerung
            <p-sortIcon field="just_reminder_date_open"></p-sortIcon>
          </th>
          <th style="max-width: 50px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr class="clickable">
          <td (click)="openInvoiceDialog(item)">
            {{ item.invoice_number_complete }}
          </td>
          <td (click)="openInvoiceDialog(item)">
            <!-- TODO: Aktuell hardcodiert "patient" kann sich aber in zukunft ändern -->
            <a routerLink="/patients/{{ item.invoiceable.id }}"
              >{{ item.invoiceable.last_name }},
              {{ item.invoiceable.first_name }}</a
            >
          </td>
          <td (click)="openInvoiceDialog(item)">
            {{ item.trips.length }}
            {{ item.trips.length === 1 ? "Fahrt" : "Fahrten" }}
          </td>
          <td (click)="openInvoiceDialog(item)">
            {{ item.trips_mileage_sum }} KM
          </td>
          <td (click)="openInvoiceDialog(item)">
            {{ item.total_price_euro_formatted }}
          </td>
          <td style="max-width: 100px" (click)="openInvoiceDialog(item)">
            {{ item.part_payments_sum_euro_formatted }}
          </td>
          <td (click)="openInvoiceDialog(item)">
            <div class="text-center-container">
              {{ item.opened_at | date: "dd.MM.y, H:mm" }} ({{
                item.sent_type_text
              }})
              <span
                class="color-gray"
                *ngIf="item.user?.user_system"
                [pTooltip]="item.user.user_system.full_name"
              >
                {{ item.user.user_system.first_name[0]
                }}{{ item.user.user_system.last_name[0] }}
              </span>
            </div>
          </td>
          <td style="max-width: 120px" (click)="openInvoiceDialog(item)">
            <div class="text-center-container" style="gap: 20px">
              <!-- Zahlungsziel 1 -->
              <i
                class="pi pi-check-circle color-gray"
                pTooltip="Fälligkeit offen"
                *ngIf="item.due_date_open && !item.last_reminder"
              ></i>
              <i
                class="pi pi-check-circle color-main1"
                pTooltip="1. Mahnung"
                *ngIf="item.due_date_open && item.last_reminder?.type"
              ></i>

              <!-- Zahlungsziel 2 -->
              <i
                class="pi pi-check-circle color-gray"
                pTooltip="Fälligkeit offen"
                *ngIf="
                  item.last_reminder?.type === 1 &&
                  item.last_reminder?.due_date_open
                "
              ></i>
              <i
                class="pi pi-check-circle color-main1"
                pTooltip="2. Mahnung"
                *ngIf="item.last_reminder?.type === 2"
              ></i>
            </div>
          </td>
          <td style="max-width: 120px" (click)="openInvoiceDialog(item)">
            <i
              class="pi pi-clock color-gray"
              pTooltip="Erinnerung möglich"
              *ngIf="
                item.just_reminder_date_open &&
                !item.just_reminder &&
                !item.last_reminder
              "
            ></i>

            <i
              class="pi pi-clock color-blue"
              pTooltip="Erinnerung versendet"
              *ngIf="item.just_reminder"
            ></i>
          </td>
          <td style="max-width: 50px" (click)="openInvoiceDialog(item)">
            <i
              class="pi pi-info-circle color-gray"
              style="font-size: 14px"
              tooltipPosition="left"
              [pTooltip]="item.intern_comment"
              *ngIf="item.intern_comment"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
