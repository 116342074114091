<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header without-border">
    <span>
      <i class="pi pi-user"></i>
      Multiplikator
    </span>
    <div class="setting-menu-container" #settingContainer>
      <button
        pButton
        label="Einstellungen"
        type="button"
        class="p-button-outlined"
        icon="pi pi-chevron-down"
        iconPos="right"
        (click)="menu.toggle($event)"
      ></button>
      <p-menu #menu [popup]="true" [model]="settingMenuItems"></p-menu>
    </div>
  </header>
</div>

<div class="content" *ngIf="!loading">
  <div class="content-body without-padding p-flex-row">
    <div class="detail-information-column border-right">
      <div class="detail-information-container">
        <div class="detail-information-header">Daten</div>
        <div class="detail-information-body">
          <div class="detail-information">
            <div class="detail-key">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(
                    'disciplines_as_string',
                    multiplier.disciplines_as_string
                  )
                "
                [pTooltip]="latestChanges.disciplines_as_string"
                >Kategorie</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ multiplier.disciplines_as_string }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key">
              <span
                tooltipPosition="left"
                (mouseenter)="getLatestChange('name', multiplier.name)"
                [pTooltip]="latestChanges.name"
                >Name</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ multiplier.name }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(
                    'street_house_number',
                    multiplier.street_house_number
                  )
                "
                [pTooltip]="latestChanges.street_house_number"
                >Straße / Hausnummer</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ multiplier.street_house_number }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key">
              <span
                tooltipPosition="left"
                (mouseenter)="getLatestChange('zipcode', multiplier.zipcode)"
                [pTooltip]="latestChanges.zipcode"
                >PLZ / Ort</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ multiplier.zipcode }} {{ multiplier.city }}
                {{ multiplier.city_part }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange('kassen_ik', multiplier.kassen_ik)
                "
                [pTooltip]="latestChanges.kassen_ik"
                >Kassen IK</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ multiplier.kassen_ik }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange('pflege_ik', multiplier.pflege_ik)
                "
                [pTooltip]="latestChanges.pflege_ik"
                >Pflege IK</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ multiplier.pflege_ik }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="detail-information-column">
      <div class="detail-information-container">
        <div class="detail-information-header">Kontakt</div>
        <div class="detail-information-body">
          <div class="detail-information" *ngIf="authService.isSuperAdmin()">
            <div class="detail-key">
              <span>Erster Login</span>
            </div>
            <div class="detail-value">
              <strong *ngIf="multiplier.user?.first_login">
                {{
                  multiplier.user?.first_login.created_at
                    | date: "dd.MM.y, H:mm:ss"
                }}
                Uhr
              </strong>
              <strong *ngIf="!multiplier.user?.first_login"> - </strong>
            </div>
          </div>

          <div class="detail-information" *ngIf="authService.isSuperAdmin()">
            <div class="detail-key">
              <span>Letzte Aktivität</span>
            </div>
            <div class="detail-value">
              <strong *ngIf="multiplier.user?.last_activity">
                {{
                  multiplier.user?.last_activity.created_at
                    | date: "dd.MM.y, H:mm:ss"
                }}
                Uhr
              </strong>
              <strong *ngIf="!multiplier.user?.last_activity"> - </strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange('phone_central', multiplier.phone_central)
                "
                [pTooltip]="latestChanges.phone_central"
                >Telefon Zentrale</span
              >
            </div>
            <div class="detail-value">
              <strong *ngIf="!multiplier.phone_central">-</strong>
              <strong>
                <a
                  href="tel:{{ multiplier.phone_central }}"
                  (click)="
                    phoneCallService.createCalling(multiplier.phone_central)
                  "
                >
                  {{ multiplier.phone_central }}
                </a>
              </strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key">
              <span
                tooltipPosition="left"
                (mouseenter)="getLatestChange('mobile', multiplier.mobile)"
                [pTooltip]="latestChanges.mobile"
                (click)="phoneCallService.createCalling(multiplier.mobile)"
                >Mobil</span
              >
            </div>
            <div class="detail-value">
              <strong *ngIf="!multiplier.mobile">-</strong>
              <strong>
                <a
                  href="tel:{{ multiplier.mobile }}"
                  (click)="phoneCallService.createCalling(multiplier.mobile)"
                >
                  {{ multiplier.mobile }}
                </a>
              </strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key">
              <span
                tooltipPosition="left"
                (mouseenter)="getLatestChange('fax', multiplier.fax)"
                [pTooltip]="latestChanges.fax"
                >Fax</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ multiplier.fax || "-" }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key">
              <span
                tooltipPosition="left"
                (mouseenter)="getLatestChange('email', multiplier.email)"
                [pTooltip]="latestChanges.email"
                >E-Mail</span
              >
            </div>
            <div class="detail-value">
              <strong *ngIf="!multiplier.email">-</strong>
              <strong *ngIf="multiplier.email">
                <a href="mailto:{{ multiplier.email }}">
                  {{ multiplier.email }}
                </a>
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="content new-content-container" *ngIf="!loading">
  <header class="content-header">
    <span>
      <i class="pi pi-phone"></i>
      Ansprechpartner
    </span>
    <button
      pButton
      (click)="openContactPersonDialog()"
      label="Neuer Ansprechpartner"
      type="button"
      class="p-button-text button-without-padding"
      icon="pi pi-plus"
      iconPos="right"
    ></button>
  </header>
  <div
    class="content-body contact-person-content-body"
    *ngIf="multiplier.contact_persons.length === 0"
  >
    <span class="content-no-message"> Kein Ansprechpartner vorhanden </span>
  </div>

  <div
    class="content-body contact-person-content-body without-padding p-flex-row p-flex-wrap"
    *ngIf="multiplier.contact_persons.length > 0"
  >
    <div
      *ngFor="let contactPerson of multiplier.contact_persons; let i = index"
      class="detail-information-column contact-person-column border-right"
    >
      <div class="detail-information-container">
        <div class="detail-information-header for-contact-person">
          Ansprechpartner {{ i + 1 }}
          <i
            class="pi pi-pencil"
            (click)="openContactPersonDialog(contactPerson)"
          ></i>
        </div>
        <div class="detail-information-body">
          <div class="detail-information">
            <div class="detail-key">
              <span>Name</span>
            </div>
            <div class="detail-value">
              <strong
                >{{ contactPerson.form_of_address }}
                {{ contactPerson.first_name }}
                {{ contactPerson.last_name }}</strong
              >
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key">
              <span>Abteilung</span>
            </div>
            <div class="detail-value">
              <strong>{{ contactPerson.relation }}</strong>
            </div>
          </div>
          <div class="detail-information" *ngIf="openedContactPersons[i]">
            <div class="detail-key">
              <span>Telefon 1</span>
            </div>
            <div class="detail-value">
              <strong>
                <a
                  href="tel:{{ contactPerson.phone_1 }}"
                  (click)="
                    phoneCallService.createCalling(contactPerson.phone_1)
                  "
                >
                  {{ contactPerson.phone_1 }}
                </a>
              </strong>
            </div>
          </div>
          <div class="detail-information" *ngIf="openedContactPersons[i]">
            <div class="detail-key">
              <span>Telefon 2</span>
            </div>
            <div class="detail-value">
              <strong>
                <a
                  href="tel:{{ contactPerson.phone_2 }}"
                  (click)="
                    phoneCallService.createCalling(contactPerson.phone_2)
                  "
                >
                  {{ contactPerson.phone_2 }}
                </a>
              </strong>
            </div>
          </div>
          <div class="detail-information" *ngIf="openedContactPersons[i]">
            <div class="detail-key">
              <span>E-Mail Privat</span>
            </div>
            <div class="detail-value">
              <strong>
                <a href="mailto:{{ contactPerson.email_private }}">
                  {{ contactPerson.email_private }}
                </a>
              </strong>
            </div>
          </div>
          <div class="detail-information" *ngIf="openedContactPersons[i]">
            <div class="detail-key">
              <span>E-Mail Geschäftlich</span>
            </div>
            <div class="detail-value">
              <strong>
                <a href="mailto:{{ contactPerson.email_work }}">
                  {{ contactPerson.email_work }}
                </a>
              </strong>
            </div>
          </div>
          <div class="detail-information" *ngIf="openedContactPersons[i]">
            <div class="detail-key">
              <span>PLZ / Ort</span>
            </div>
            <div class="detail-value">
              <strong
                >{{ contactPerson.zipcode }} {{ contactPerson.city }}</strong
              >
            </div>
          </div>
          <div class="detail-information" *ngIf="openedContactPersons[i]">
            <div class="detail-key">
              <span>Adresse</span>
            </div>
            <div class="detail-value">
              <strong>{{ contactPerson.street_house_number }}</strong>
            </div>
          </div>
          <div class="detail-information" *ngIf="openedContactPersons[i]">
            <div class="detail-key">
              <span>Kommentar</span>
            </div>
            <div class="detail-value">
              <strong>{{ contactPerson.comment }}</strong>
            </div>
          </div>
        </div>

        <span
          class="show-more-btn"
          (click)="openedContactPersons[i] = !openedContactPersons[i]"
        >
          {{ openedContactPersons[i] ? "Weniger anzeigen" : "Mehr anzeigen" }}
        </span>
      </div>
    </div>
  </div>
</div>
