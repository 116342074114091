import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { PhoneCallService } from '../../../services/phone-call.service'
import { AuthService } from '../../../services/auth.service'
import { ConsultingService } from '../../../services/consulting.service'
import { InvoiceDialogComponent } from '../../../components/dialogs/invoice-dialog/invoice-dialog.component'
import { ConsultingConfirmDialogComponent } from '../../../components/dialogs/consulting-confirm-dialog/consulting-confirm-dialog.component'
import { ConsultingDialogComponent } from '../../../components/dialogs/consulting-dialog/consulting-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { Subject, Subscription } from 'rxjs'
import { InvoiceViewDialogComponent } from '../../../components/dialogs/invoice-view-dialog/invoice-view-dialog.component'
import { InvoiceNewDialogComponent } from '../../../components/dialogs/invoice-new-dialog/invoice-new-dialog.component'
import { HelperService } from '../../../services/helper.service'
import {
  CaregiverDependenciesModel,
  CountyModel,
} from '../../../models/caregiver/caregiver-dependencies.model'
import { CaregiverService } from '../../../services/caregivers.service'
import { switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-consultings-list-invoices',
  templateUrl: './consultings-list-invoices.component.html',
  styleUrls: ['./consultings-list-invoices.component.scss'],
})
export class ConsultingsListInvoicesComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null

  public isSuperAdmin = false
  private eventBusSubscription: Subscription = new Subscription()
  public firstPage = 0

  public globalFilterValue = ''
  public contentLoading = true
  public loading = true
  public consultings: any[] = []
  public counties: any[] = []

  public dependencies: CaregiverDependenciesModel = new CaregiverDependenciesModel()

  public monthOptions: any = []
  public yearOptions = [
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
    { label: '2025', value: '2025' },
  ]

  public selectedCities: any[] = []
  public filters = {
    year: null,
    months: [],
    only_new: false,
    degrees_of_care: ['I', 'II', 'III', 'IV', 'V'],
  }

  private loadTrigger$ = new Subject<void>()
  private subscription: Subscription = new Subscription()

  constructor(
    public phoneCallService: PhoneCallService,
    public authService: AuthService,
    private helperService: HelperService,
    private dialogService: DialogService,
    private eventbus: EventBusService,
    private caregiverService: CaregiverService,
    private consultingService: ConsultingService
  ) {}

  ngOnInit(): void {
    this.initFilters()

    this.isSuperAdmin = this.authService.isSuperAdmin()

    this.helperService.dependencies$.subscribe((data: any) => {
      this.monthOptions = data.months
    })

    this.activateDataLoading()

    this.loadDependencies()
    this.loadData()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.subscription.unsubscribe()
  }

  private loadDependencies(): void {
    this.caregiverService
      .dependencies()
      .subscribe((dependencies: CaregiverDependenciesModel) => {
        this.dependencies = dependencies
      })
  }

  private initFilters(): void {
    if (localStorage.getItem('consultings-list-filter-new') !== null) {
      this.filters = JSON.parse(
        localStorage.getItem('consultings-list-filter-new') ||
          JSON.stringify(this.filters)
      )
    }
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.InvoiceChanged:
          case GlobalEvent.ConsultingChanged:
            this.loadData(false)
        }
      }
    )
  }

  private getSelectedCities(): any[] {
    let selectedCities = []
    if (this.selectedCities) {
      selectedCities = this.selectedCities
        .filter((city: any) => city.type === 'CITY')
        .map((city: any) => city.id)
    }

    return selectedCities
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public openMediaFromUuid(uuid: string): void {
    window.open(this.consultingService.getDocumentDownloadLink(uuid))
  }

  public openInvoiceDialog(invoice: any): void {
    if (invoice.status !== 'READY') {
      this.dialogService.open(InvoiceViewDialogComponent, {
        header: 'Rechnung',
        width: '96%',
        height: '90%',
        styleClass: 'dialog-container',
        data: {
          invoice,
        },
      })
    } else {
      this.dialogService.open(InvoiceNewDialogComponent, {
        header: 'Rechnung',
        width: '96%',
        height: '90%',
        styleClass: 'dialog-container',
        data: {
          invoice,
        },
      })
    }
  }

  public openConsultingConfirmDialog(consulting: any): void {
    this.dialogService.open(ConsultingConfirmDialogComponent, {
      header: 'Beratungseinsatz bestätigen',
      width: '490px',
      styleClass: 'dialog-container',
      data: {
        consulting: { ...consulting },
      },
    })
  }

  public openConsultingEditDialog(consulting: any): void {
    this.dialogService.open(ConsultingDialogComponent, {
      header: 'Beratungseinsatz bearbeiten',
      width: '650px',
      styleClass: 'dialog-container',
      data: {
        isNew: false,
        consulting: { ...consulting },
        patient: { ...consulting.patient },
      },
    })
  }

  public loadData(withLoading: boolean = true): void {
    this.contentLoading = true

    if (withLoading) {
      this.loading = true
    }

    localStorage.setItem(
      'consultings-list-filter-new',
      JSON.stringify(this.filters)
    )

    this.loadTrigger$.next()
  }

  private activateDataLoading(): void {
    this.subscription.add(
      this.loadTrigger$
        .pipe(
          switchMap(() =>
            this.consultingService.loadInvoices(
              this.filters,
              this.getSelectedCities()
            )
          )
        )
        .subscribe((data: any) => {
          this.consultings = data.consultings
          this.counties = data.counties

          this.loading = false
          this.contentLoading = false

          setTimeout(() => {
            if (this.table) {
              const totalItems = this.table._totalRecords

              this.globalFilterValue = this.table.filters.global?.value ?? ''

              if (
                this.table._first > totalItems ||
                this.table._rows > totalItems
              ) {
                this.firstPage = 0
              }
            }
          })
        })
    )
  }
}
