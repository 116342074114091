import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { NotificationModel } from '../models/notification/notification.model'

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private http: HttpClient) {}

  public loadAllRead(): Observable<NotificationModel[]> {
    return this.http.get<[]>(`${environment.api}/notification/all-read`)
  }

  public read(id: number): Observable<[]> {
    return this.http.put<[]>(`${environment.api}/notification/${id}/read`, {})
  }

  public readAll(ids: number[]): Observable<[]> {
    return this.http.put<[]>(`${environment.api}/notification/read/all`, {
      ids,
    })
  }
}
