import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { HistoryService } from '../../../../services/history.service'
import { LatestChangeModel } from '../../../../models/history/latest-change.model'
import { CarService } from '../../../../services/car.service'
import { CarsDetailGeneralModel } from '../../../../models/car/cars-detail-general.model'
import { DialogService } from 'primeng/dynamicdialog'
import { NoticeDialogComponent } from '../../../../components/dialogs/notice-dialog/notice-dialog.component'
import { MenuItem } from 'primeng/api'
import { AddCarMileageDialogComponent } from '../../../../components/dialogs/add-car-mileage-dialog/add-car-mileage-dialog.component'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { CaregiverCarModel } from '../../../../models/car/caregiver-car.model'
import { AddCaregiverCarDialogComponent } from '../../../../components/dialogs/add-caregiver-car-dialog/add-caregiver-car-dialog.component'
import { DiffViewHistoryDialogComponent } from '../../../../components/dialogs/diff-view-history-dialog/diff-view-history-dialog.component'
import { ShowTodoDialogComponent } from '../../../../components/dialogs/show-todo-dialog/show-todo-dialog.component'
import { AddCarStatusDialogComponent } from '../../../../components/dialogs/add-car-status-dialog/add-car-status-dialog.component'
import { TodoModel } from 'src/app/models/todo/todo.model'

@Component({
  selector: 'app-cars-detail-general',
  templateUrl: './cars-detail-general.component.html',
})
export class CarsDetailGeneralComponent implements OnInit, OnDestroy {
  public loading = true
  public car: CarsDetailGeneralModel = new CarsDetailGeneralModel()
  public latestChanges: LatestChangeModel = new LatestChangeModel()

  private eventBusSubscription: Subscription = new Subscription()
  private routeSubscription: any = null

  public settingMenuItems: MenuItem[] = [
    {
      label: 'Notiz',
      icon: 'pi pi-align-left',
      command: () => this.openNoticeDialog(),
    },
  ]

  public openedDetails = {
    mileages: false,
    status: false,
  }

  constructor(
    private carService: CarService,
    private route: ActivatedRoute,
    private router: Router,
    private eventbus: EventBusService,
    private dialogService: DialogService,
    private historyService: HistoryService
  ) {}

  ngOnInit(): void {
    this.loadCar()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.routeSubscription && this.routeSubscription.unsubscribe()
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.CarDetailReload:
            this.loadCar(false)
        }
      }
    )
  }

  public getLatestChange(
    id: number | string | null | undefined,
    type: 'car',
    data: { [key: string]: any }
  ): void {
    const keys = Object.keys(data)

    for (let key of keys) {
      data[key] = data[key] != null ? encodeURIComponent(data[key]) : ''
    }

    this.historyService
      .getLatestChangeMultiple(id || '', type, data)
      .subscribe((date: string) => {
        for (let key of keys) {
          this.latestChanges[key] = date
        }
      })
  }

  public openDiffViewHistory(
    id: number | string | null | undefined,
    type: 'car',
    data: { [key: string]: any }
  ): void {
    const keys = Object.keys(data)

    for (let key of keys) {
      data[key] = data[key] != null ? encodeURIComponent(data[key]) : ''
    }

    this.dialogService.open(DiffViewHistoryDialogComponent, {
      data: {
        id,
        type,
        data,
      },
      header: 'Änderungen ansehen',
      styleClass: 'dialog-diff-view',
      dismissableMask: true,
    })
  }

  public openAddCarMileageDialog(): void {
    this.dialogService.open(AddCarMileageDialogComponent, {
      header: 'Kilometerstand ändern',
      width: '550px',
      dismissableMask: true,
      styleClass: 'dialog-container',
      data: {
        isEdit: false,
        car: this.car,
      },
    })
  }

  public openAddCarStatusDialog(): void {
    this.dialogService.open(AddCarStatusDialogComponent, {
      header: 'Status ändern',
      width: '550px',
      dismissableMask: true,
      styleClass: 'dialog-container',
      data: {
        isEdit: false,
        car: this.car,
      },
    })
  }

  public openNoticeDialog(): void {
    this.dialogService.open(NoticeDialogComponent, {
      header: 'Notiz bearbeiten',
      width: '450px',
      dismissableMask: true,
      styleClass: 'dialog-container',
      data: {
        type: 'car',
        type_id: this.car.id,
        notice: this.car.notice,
      },
    })
  }

  public openAddCaregiverCarDialog(car: CaregiverCarModel | null = null): void {
    this.dialogService.open(AddCaregiverCarDialogComponent, {
      header: car ? 'Benutzer bearbeiten' : 'Benutzer hinzufügen',
      width: '450px',
      dismissableMask: true,
      styleClass: 'dialog-container',
      data: {
        isEdit: !!car,
        from_type: 'car',
        car: this.car,
      },
    })
  }

  public openShowTodoDialog(todo: TodoModel): void {
    this.dialogService.open(ShowTodoDialogComponent, {
      header: `Todo ansehen #${todo?.id}`,
      width: '820px',
      styleClass: 'dialog-container',
      data: {
        isEdit: true,
        todo,
        todo_id: todo.id,
      },
    })
  }

  public loadCar(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.routeSubscription = this.route.paramMap.subscribe(
      (params: ParamMap) => {
        this.carService
          .load(params.get('id'))
          .subscribe((data: CarsDetailGeneralModel) => {
            this.car = data

            if (withLoading) {
              this.loading = false
            }
          })
      }
    )
  }
}
