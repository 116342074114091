import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { HistoryService } from '../../../../services/history.service'
import { LatestChangeModel } from '../../../../models/history/latest-change.model'
import { NoticeDialogComponent } from '../../../../components/dialogs/notice-dialog/notice-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import { CustomerDetailGeneralModel } from '../../../../models/customer-patient/customer-detail-general.model'
import { PatientService } from '../../../../services/patient.service'
import { ContactPersonModel } from '../../../../models/contact-person/contact-person.model'
import { ContactPersonDialogComponent } from '../../../../components/dialogs/contact-person-dialog/contact-person-dialog.component'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { MenuItem } from 'primeng/api'
import { QualificationDialogComponent } from '../../../../components/dialogs/qualification-dialog/qualification-dialog.component'
import { PhoneCallService } from '../../../../services/phone-call.service'
import { ChangeCustomerServiceDialogComponent } from '../../../../components/dialogs/change-customer-service-dialog/change-customer-service-dialog.component'
import { TodoModel } from '../../../../models/todo/todo.model'
import { HistoryListModel } from '../../../../models/history/history-list.model'
import { HistoryManualDialogComponent } from '../../../../components/dialogs/history-manual-dialog/history-manual-dialog.component'
import { DiffViewDialogComponent } from '../../../../components/dialogs/diff-view-dialog/diff-view-dialog.component'
import { PhoneCallsListModel } from '../../../../models/phone-call/phone-calls-list.model'
import { PhoneCallEditDialogComponent } from '../../../../components/dialogs/phone-call-edit-dialog/phone-call-edit-dialog.component'
import { NoticeAccountingDialogComponent } from '../../../../components/dialogs/notice-accounting-dialog/notice-accounting-dialog.component'
import { SendDlvDialogComponent } from '../../../../components/dialogs/send-dlv-dialog/send-dlv-dialog.component'
import { OfferService } from '../../../../services/offer.service'
import { SendOfferService } from '../../../../services/send-offer.service'
import { SendAppointmentsService } from '../../../../services/send-appointments.service'
import { PrintAddressDialogComponent } from '../../../../components/dialogs/print-address-dialog/print-address-dialog.component'
import { PauseDialogComponent } from '../../../../components/dialogs/pause-dialog/pause-dialog.component'
import * as dayjs from 'dayjs'
import { PatientPauseModel } from '../../../../models/customer-patient/patient-pause.model'
import { SendLetterService } from '../../../../services/send-letter.service'
import { AddDesiredDateDialogComponent } from '../../../../components/dialogs/add-desired-date-dialog/add-desired-date-dialog.component'
import { AddCancelledDateDialogComponent } from '../../../../components/dialogs/add-cancelled-date-dialog/add-cancelled-date-dialog.component'
import { DiffViewHistoryDialogComponent } from '../../../../components/dialogs/diff-view-history-dialog/diff-view-history-dialog.component'
import { ChangeStatusPatientDialogComponent } from '../../../../components/dialogs/change-status-patient-dialog/change-status-patient-dialog.component'
import { ConfirmImportantHistoriesDialogComponent } from '../../../../components/dialogs/confirm-important-histories-dialog/confirm-important-histories-dialog.component'
import { ShowTodoDialogComponent } from '../../../../components/dialogs/show-todo-dialog/show-todo-dialog.component'
import { LockPatientDialogComponent } from '../../../../components/dialogs/lock-patient-dialog/lock-patient-dialog.component'
import { ChangePatientAppointmentTypesDialogComponent } from '../../../../components/dialogs/change-patient-appointment-types-dialog/change-patient-appointment-types-dialog.component'
import { ChangePatientInvoiceTypesDialogComponent } from '../../../../components/dialogs/change-patient-invoice-types-dialog/change-patient-invoice-types-dialog.component'
import { Clipboard } from '@angular/cdk/clipboard'
import { AuthService } from '../../../../services/auth.service'
import { AccountCustomerDialogComponent } from '../../../../components/dialogs/account-customer-dialog/account-customer-dialog.component'
import { HelperService } from '../../../../services/helper.service'
import { ChangePatientPriceDialogComponent } from '../../../../components/dialogs/change-patient-price-dialog/change-patient-price-dialog.component'
import { PatientPriceHistoryDialogComponent } from '../../../../components/dialogs/patient-price-history-dialog/patient-price-history-dialog.component'

@Component({
  selector: 'app-patients-detail-general',
  templateUrl: './patients-detail-general.component.html',
})
export class PatientsDetailGeneralComponent implements OnInit, OnDestroy {
  @ViewChild('op') overlayPanel: ElementRef | any = null
  @ViewChild('opNearPatients') overlayPanelNearPatients: ElementRef | any = null

  public loading = true
  public customer: CustomerDetailGeneralModel = new CustomerDetailGeneralModel()
  public area = 1
  public nearPatientsLoading = false
  public nearPatients = []
  public latestChanges = new LatestChangeModel()
  public openedContactPersons: { [index: number]: boolean } = {}
  private eventBusSubscription: Subscription = new Subscription()
  public onlyImportant = false

  public currentSystemPrice = ''

  private persplanDates: any = {}
  public persplanData = new LatestChangeModel()
  public selectedPatient: 'first_patient' | 'second_patient' = 'first_patient'

  public currentBudgetTable = ''

  public addressCopied = false
  public settingMenuItems: MenuItem[] = []

  constructor(
    private authService: AuthService,
    private offerService: OfferService,
    private patientService: PatientService,
    private clipboard: Clipboard,
    private helperService: HelperService,
    private route: ActivatedRoute,
    public phoneCallService: PhoneCallService,
    private eventbus: EventBusService,
    private dialogService: DialogService,
    private router: Router,
    private sendOfferService: SendOfferService,
    private sendAppointmentsService: SendAppointmentsService,
    private sendLetterService: SendLetterService,
    private historyService: HistoryService
  ) {}

  ngOnInit(): void {
    dayjs.locale('de')

    this.persplanDates = {
      last: {
        month: dayjs().subtract(1, 'month').format('MMMM'),
        year: dayjs().subtract(1, 'month').format('YYYY'),
      },
      current: {
        month: dayjs().format('MMMM'),
        year: dayjs().format('YYYY'),
      },
      next: {
        month: dayjs().add(1, 'month').format('MMMM'),
        year: dayjs().add(1, 'month').format('YYYY'),
      },
    }

    this.loadCustomer()
    this.listenForEventbus()

    this.helperService.dependencies$.subscribe((data: any) => {
      this.currentSystemPrice = data.current_system_price
    })
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public getLatestChange(
    id: number | string | null,
    type:
      | 'patient'
      | 'qm'
      | 'household'
      | 'customer'
      | 'contact_person'
      | 'caregiver',
    data: { [key: string]: any }
  ): void {
    const keys = Object.keys(data)

    for (let key of keys) {
      // Wir überprüfen nicht mehr, ob der Wert bereits geladen wurde.
      // Dadurch müssen wir nicht mehr prüfen, welcher Patient (wenn 2 vorhanden sind)
      // aktiv ist, oder ob es sich um Ansprechpartner oder anderes handelt, was
      // nicht mit dem Patienten direkt verbunden ist.
      // if (this.latestChanges[this.selectedPatient][key]) {
      //   return
      // }

      data[key] = data[key] != null ? encodeURIComponent(data[key]) : ''
    }

    this.historyService
      .getLatestChangeMultiple(id, type, data)
      .subscribe((date: string) => {
        for (let key of keys) {
          this.latestChanges[key] = date
        }
      })
  }

  public openNearPatients(event: any = null, withLoading = true): void {
    if (withLoading) {
      this.nearPatients = []
      this.nearPatientsLoading = true
    }

    this.patientService
      .getNearPatients(this.customer.customer.id, this.area)
      .subscribe((patients: any) => {
        this.nearPatients = patients

        this.nearPatientsLoading = false

        if (event) {
          this.overlayPanelNearPatients.toggle(event)
        }
      })
  }

  public openAccountDialog(): void {
    this.dialogService.open(AccountCustomerDialogComponent, {
      header: 'Account',
      dismissableMask: true,
      width: '760px',
      styleClass: 'dialog-container',
      data: {
        customer: { ...this.customer },
      },
    })
  }

  public openChangePriceDialog(): void {
    this.dialogService.open(ChangePatientPriceDialogComponent, {
      header: 'Preis ändern',
      dismissableMask: true,
      width: '490px',
      styleClass: 'dialog-container',
      data: {
        patient: this.customer[this.selectedPatient],
      },
    })
  }

  public openPriceHistoryDialog(): void {
    this.dialogService.open(PatientPriceHistoryDialogComponent, {
      header: 'Preisänderungen',
      width: '390px',
      dismissableMask: true,
      styleClass: 'dialog-container',
      data: {
        patient: this.customer[this.selectedPatient],
      },
    })
  }

  public copyAddressToClipboard(): void {
    this.clipboard.copy(this.customer[this.selectedPatient].full_address)

    this.addressCopied = true

    setTimeout(() => {
      this.addressCopied = false
    }, 2000)
  }

  public openDiffViewHistory(
    id: number | string | null,
    type: 'patient' | 'qm' | 'household' | 'customer' | 'contact_person',
    data: { [key: string]: any }
  ): void {
    const keys = Object.keys(data)

    for (let key of keys) {
      data[key] = data[key] != null ? encodeURIComponent(data[key]) : ''
    }

    this.dialogService.open(DiffViewHistoryDialogComponent, {
      data: {
        id,
        type,
        data,
      },
      header: 'Änderungen ansehen',
      styleClass: 'dialog-diff-view',
      dismissableMask: true,
    })
  }

  public openShowTodoDialog(todo: TodoModel): void {
    this.dialogService.open(ShowTodoDialogComponent, {
      header: `Todo ansehen #${todo?.id}`,
      width: '820px',
      styleClass: 'dialog-container',
      data: {
        isEdit: true,
        todo,
        todo_id: todo.id,
        user_type_name: todo?.patient?.full_name,
      },
    })
  }

  public openChangePatientAppointmentTypeDialog(): void {
    this.dialogService.open(ChangePatientAppointmentTypesDialogComponent, {
      header: 'Terminversand anpassen',
      width: '360px',
      dismissableMask: true,
      styleClass: 'dialog-container',
      data: {
        patient: this.customer[this.selectedPatient],
      },
    })
  }

  public openChangePatientInvoiceTypeDialog(): void {
    this.dialogService.open(ChangePatientInvoiceTypesDialogComponent, {
      header: 'Rechnungsversand anpassen',
      dismissableMask: true,
      width: '360px',
      styleClass: 'dialog-container',
      data: {
        patient: this.customer[this.selectedPatient],
      },
    })
  }

  public openDiffView(id: number): void {
    this.dialogService.open(DiffViewDialogComponent, {
      data: {
        id,
      },
      header: 'Änderungen ansehen',
      styleClass: 'dialog-diff-view',
      dismissableMask: true,
    })
  }

  public openPhonecallEditModal(phonecall: PhoneCallsListModel): void {
    this.dialogService.open(PhoneCallEditDialogComponent, {
      header: 'Telefonat bearbeiten',
      width: '540px',
      styleClass: 'dialog-container',
      dismissableMask: false,
      data: phonecall,
    })
  }

  public getPersplanBudget(
    patientId: number | null,
    type: 'current' | 'next' | 'last'
  ): void {
    if (this.persplanData[type + patientId]) {
      return
    }

    this.patientService
      .getPersplanMonth(
        patientId ? patientId.toString() : '',
        this.persplanDates[type].month,
        this.persplanDates[type].year,
        true
      )
      .subscribe((response: string) => {
        this.persplanData[type + patientId] = response
      })
  }

  public openBudgetOverlay(event: any, text: string): void {
    this.currentBudgetTable = text

    this.overlayPanel.toggle(event)
  }

  public changePatient(type: 'first_patient' | 'second_patient'): void {
    this.selectedPatient = type

    this.buildMenuItems()
  }

  public openNoticeDialog(): void {
    this.dialogService.open(NoticeDialogComponent, {
      header: 'Notiz bearbeiten',
      dismissableMask: true,
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        type: 'customer',
        type_id: this.customer.customer.id,
        notice: this.customer.customer.notice,
      },
    })
  }

  public openNoticeAccountingDialog(): void {
    this.dialogService.open(NoticeAccountingDialogComponent, {
      header: 'Notiz bearbeiten',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        type: 'customer',
        type_id: this.customer.customer.id,
        notice_accounting: this.customer.customer.notice_accounting,
      },
    })
  }

  public openQualificationDialog(): void {
    this.dialogService.open(QualificationDialogComponent, {
      header: 'Qualifizierung beenden',
      width: '1020px',
      styleClass: 'dialog-container',
      data: {
        ...this.customer,
      },
    })
  }

  public openSendDlvDialog(): void {
    this.dialogService.open(SendDlvDialogComponent, {
      header: 'Angebot / DLV versenden',
      width: '540px',
      styleClass: 'dialog-container',
      data: {
        ...this.customer,
        patient_id: this.customer[this.selectedPatient]?.id,
      },
    })
  }

  public openDesiredDateDialog(): void {
    this.dialogService.open(AddDesiredDateDialogComponent, {
      header: 'Wunschtermin erstellen',
      width: '620px',
      styleClass: 'dialog-container',
      data: {
        isEdit: false,
        fromCustomer: true,
        patient: this.customer[this.selectedPatient],
      },
    })
  }

  public openSendAppointmentsDialog(): void {
    this.dialogService.open(ConfirmImportantHistoriesDialogComponent, {
      header: 'Wichtige Ereignisse',
      width: '1200px',
      styleClass: 'dialog-container',
      data: {
        ...this.customer,
        patient_id: this.customer[this.selectedPatient]?.id,
      },
    })
  }

  public openLockPatientDialog(): void {
    this.dialogService.open(LockPatientDialogComponent, {
      header: 'Terminversand sperren',
      width: '420px',
      styleClass: 'dialog-container',
      data: {
        type: 'LOCK',
        patient: this.customer[this.selectedPatient],
      },
    })
  }

  public openUnlockPatientDialog(): void {
    this.dialogService.open(LockPatientDialogComponent, {
      header: 'Terminversand entsperren',
      width: '420px',
      styleClass: 'dialog-container',
      data: {
        type: 'UNLOCK',
        patient: this.customer[this.selectedPatient],
      },
    })
  }

  public openAddCancelledDateDialog(): void {
    this.dialogService.open(AddCancelledDateDialogComponent, {
      header: 'Termine absagen',
      width: '640px',
      styleClass: 'dialog-container',
      data: {
        ...this.customer,
        patient_id: this.customer[this.selectedPatient]?.id,
      },
    })
  }

  public openContactPersonDialog(
    contactPerson: ContactPersonModel | null = null
  ): void {
    this.dialogService.open(ContactPersonDialogComponent, {
      header: contactPerson
        ? 'Ansprechpartner bearbeiten'
        : 'Neuer Ansprechpartner',
      width: '1000px',
      styleClass: 'dialog-container',
      dismissableMask: true,
      data: {
        type: 'customer',
        edit: contactPerson !== null,
        type_id: this.customer.customer.id,
        contactPerson: { ...contactPerson },
      },
    })
  }

  public openChangeStatusDialog(): void {
    this.dialogService.open(ChangeStatusPatientDialogComponent, {
      header: 'Status ändern',
      width: '580px',
      styleClass: 'dialog-container',
      dismissableMask: true,
      data: {
        type_id: this.customer.customer.id,
        customer: { ...this.customer },
      },
    })
  }

  public openPauseDialog(currentPause: PatientPauseModel | null = null): void {
    this.dialogService.open(PauseDialogComponent, {
      header: 'Pausieren / Reaktivieren',
      width: '580px',
      styleClass: 'dialog-container',
      dismissableMask: true,
      data: {
        currentPause,
        patient: this.customer[this.selectedPatient],
        type_id: this.customer.customer.id,
        customer: { ...this.customer },
      },
    })
  }

  public openChangeCustomerServiceDialog(): void {
    this.dialogService.open(ChangeCustomerServiceDialogComponent, {
      header: 'Kundenbetreuer ändern',
      width: '580px',
      styleClass: 'dialog-container',
      dismissableMask: true,
      data: {
        customer: { ...this.customer },
      },
    })
  }

  public loadCustomer(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.patientService
        .load(params.get('id'))
        .subscribe((data: CustomerDetailGeneralModel) => {
          this.customer = data

          this.buildMenuItems()

          if (withLoading) {
            this.loading = false
          }
        })
    })
  }

  private buildMenuItems(): void {
    this.settingMenuItems = []

    if (
      !this.customer.first_patient.status ||
      this.customer.first_patient.status === 'QUALIFICATION_OPEN'
    ) {
      this.settingMenuItems.push({
        label: 'Qualifizierung beenden',
        icon: 'pi pi-check',
        command: () => this.openQualificationDialog(),
      })

      this.settingMenuItems.push({
        separator: true,
      })
    } else {
      if (this.customer[this.selectedPatient]?.appointment_lock) {
        this.settingMenuItems.push({
          label: 'Terminversand entsperren',
          icon: 'pi pi-unlock',
          command: () => this.openUnlockPatientDialog(),
        })
      } else {
        this.settingMenuItems.push({
          label: 'Terminversand sperren',
          icon: 'pi pi-lock',
          command: () => this.openLockPatientDialog(),
        })
      }

      this.settingMenuItems.push({
        separator: true,
      })

      this.settingMenuItems.push({
        label: 'Termine versenden',
        icon: 'pi pi-calendar',
        command: () => this.openSendAppointmentsDialog(),
      })

      this.settingMenuItems.push({
        label: 'Termine absagen',
        icon: 'pi pi-times',
        command: () => this.openAddCancelledDateDialog(),
      })

      this.settingMenuItems.push({
        separator: true,
      })
    }

    this.settingMenuItems.push({
      label: 'Wunschtermin erstellen',
      icon: 'pi pi-calendar',
      command: () => this.openDesiredDateDialog(),
    })

    if (this.authService.can('Kunde.Angebot versenden')) {
      this.settingMenuItems.push({
        label: 'Angebot / DLV versenden',
        icon: 'pi pi-send',
        command: () => this.openSendDlvDialog(),
      })
    }

    this.settingMenuItems.push({
      separator: true,
    })

    this.settingMenuItems.push({
      label: 'Notiz Allgemein',
      icon: 'pi pi-align-left',
      command: () => this.openNoticeDialog(),
    })

    this.settingMenuItems.push({
      label: 'Notiz Buchhaltung',
      icon: 'pi pi-align-left',
      command: () => this.openNoticeAccountingDialog(),
    })

    this.settingMenuItems.push({
      separator: true,
    })

    this.settingMenuItems.push({
      label: 'Status ändern',
      icon: 'pi pi-question-circle',
      command: () => this.openChangeStatusDialog(),
    })

    this.settingMenuItems.push({
      label: 'Pausieren / Reaktivieren',
      icon: 'pi pi-pause',
      command: () => this.openPauseDialog(),
    })

    this.settingMenuItems.push({
      label: 'Kundenbetreuer ändern',
      icon: 'pi pi-user',
      command: () => this.openChangeCustomerServiceDialog(),
    })

    this.settingMenuItems.push({
      separator: true,
    })

    this.settingMenuItems.push({
      label: 'Adressvordruck',
      icon: 'pi pi-file',
      command: () => this.openPrintAddressDialog(),
    })

    if (this.authService.isSuperAdmin()) {
      if (
        this.customer.first_patient.status === 'REGULAR' ||
        this.customer.first_patient.status === 'NEW'
      ) {
        this.settingMenuItems.push({
          separator: true,
        })

        this.settingMenuItems.push({
          label: 'Account',
          icon: 'pi pi-user',
          command: () => this.openAccountDialog(),
        })
      }

      if (this.customer.customer.user_id && this.authService.isSuperAdmin()) {
        this.settingMenuItems.push({
          label: 'Als Benutzer einloggen',
          icon: 'pi pi-lock',
          command: () => this.loginUsingId(),
        })
      }

      this.settingMenuItems.push({
        label: 'Preis ändern',
        icon: 'pi pi-euro',
        command: () => this.openChangePriceDialog(),
      })
    }
  }

  public loginUsingId(): void {
    this.authService
      .loginUsingId(this.customer.customer.user_id)
      .subscribe(() => {
        this.authService.setImpersonation()

        window.location.href = '/'
      })
  }

  public openManualHistoryDialog(
    edit: boolean,
    history?: HistoryListModel
  ): void {
    this.dialogService.open(HistoryManualDialogComponent, {
      data: {
        edit,
        history: history || new HistoryListModel(),
        type: 'patients',
        type_id: this.customer[this.selectedPatient].id,
        patients: this.customer,
      },
      header: history ? 'Eintrag bearbeiten' : 'Neuer Eintrag',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '520px',
    })
  }

  public openPrintAddressDialog(): void {
    this.dialogService.open(PrintAddressDialogComponent, {
      data: {
        customer: this.customer,
      },
      header: 'Adressvordruck',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '320px',
    })
  }

  public openOfferMail(offerId: string): void {
    window.open(this.sendOfferService.getMailLink(offerId))
  }

  public openAppointmentsMail(appointmentId: string): void {
    window.open(this.sendAppointmentsService.getMailLink(appointmentId))
  }

  public openLetterMail(letterId: string): void {
    window.open(this.sendLetterService.getMailLink(letterId))
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe((event) => {
      switch (event) {
        case GlobalEvent.ContactPersonChanged:
        case GlobalEvent.QualificationFinished:
        case GlobalEvent.CustomerServiceChanged:
        case GlobalEvent.NoticeChanged:
        case GlobalEvent.PatientStatusChanged:
        case GlobalEvent.TodoChanged:
        case GlobalEvent.HistoryListReload:
        case GlobalEvent.OfferSent:
          this.loadCustomer(false)
          break
      }
    })
  }
}
