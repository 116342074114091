<div class="content-actions">
  <i class="tio icon-large">pause_circle_outlined</i>

  <h2>
    <i class="tio">pause_circle_outlined</i>
    Pausierte Kunden
  </h2>
</div>

<app-loader [loading]="loading"></app-loader>

<div class="content new-content-container" style="margin: 0" *ngIf="!loading">
  <header class="content-header">
    <span>Alle Patienten die pausiert sind</span>

    <div
      class="table-search-container"
      [class.searchfield-active]="globalFilterValue != ''"
      *ngIf="patients.length > 0"
    >
      <div class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pInputText
          (input)="filterTableGlobal($event)"
          placeholder="Suche..."
          [(ngModel)]="globalFilterValue"
        />
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin-bottom: 0" *ngIf="!loading">
  <div class="content-body" *ngIf="patients.length == 0">
    <span class="content-no-message">Keine Inaktiven Kunden vorhanden</span>
  </div>

  <div
    class="content-body content-body-almost-full-height without-padding"
    *ngIf="patients.length > 0"
  >
    <p-table
      #dt
      [value]="patients"
      [globalFilterFields]="[
        'id',
        'first_name',
        'last_name',
        'full_name',
        'status_string',
        'city'
      ]"
      [rowHover]="true"
      [paginator]="true"
      [rows]="50"
      scrollHeight="100%"
      [scrollable]="true"
      [(first)]="firstPage"
      stateStorage="local"
      stateKey="state-patients-pause-2"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Patienten"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="width: 160px" pSortableColumn="status_string">
            Status
            <p-sortIcon field="status_string"></p-sortIcon>
          </th>
          <th style="width: 150px" pSortableColumn="id">
            ID <p-sortIcon field="id"></p-sortIcon>
          </th>
          <th pSortableColumn="last_name">
            Name <p-sortIcon field="last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="city">
            Wohnort <p-sortIcon field="city"></p-sortIcon>
          </th>
          <th pSortableColumn="last_active_pause.pause_from">
            von
            <p-sortIcon field="last_active_pause.pause_from"></p-sortIcon>
          </th>
          <th pSortableColumn="last_active_pause.pause_to">
            bis
            <p-sortIcon field="last_active_pause.pause_to"></p-sortIcon>
          </th>
          <th
            style="width: 80px"
            pTooltip="Kunde bei Pflegeherzen"
            tooltipPosition="left"
            pSortableColumn="is_pflegeherzen_customer"
          >
            PH <p-sortIcon field="is_pflegeherzen_customer"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-patient>
        <tr>
          <td>
            <span class="p-tag p-tag-rounded p-tag-{{ patient.status }}">{{
              patient.status_string
            }}</span>
          </td>
          <td style="width: 150px">{{ patient.id }}</td>
          <td>
            <a class="color-main1" routerLink="/patients/{{ patient.id }}">
              {{ patient.last_name }}, {{ patient.first_name }}
            </a>
          </td>
          <td>
            <div class="text-center-container">
              <i
                style="font-size: 14px"
                class="pi pi-info-circle color-gray"
                [pTooltip]="patient.full_address"
              ></i>
              {{ patient.city }}
            </div>
          </td>

          <td *ngIf="!patient.last_active_pause?.pause_from">-</td>
          <td *ngIf="patient.last_active_pause?.pause_from">
            {{ patient.last_active_pause.pause_from | date: "dd.MM.y" }}
          </td>

          <td *ngIf="!patient.last_active_pause?.pause_to">-</td>
          <td *ngIf="patient.last_active_pause?.pause_to">
            {{ patient.last_active_pause.pause_to | date: "dd.MM.y" }}
          </td>

          <td>
            <i
              class="pflegeherzen-logo-icon"
              *ngIf="patient.is_pflegeherzen_customer"
              tooltipPosition="left"
              pTooltip="Kunde bei Pflegeherzen"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
