import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { DialogService } from 'primeng/dynamicdialog'
import { DiffViewDialogComponent } from '../../../../../components/dialogs/diff-view-dialog/diff-view-dialog.component'
import { HistoryListModel } from '../../../../../models/history/history-list.model'
import { HistoryManualDialogComponent } from '../../../../../components/dialogs/history-manual-dialog/history-manual-dialog.component'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { PatientService } from '../../../../../services/patient.service'
import { PhoneCallsListModel } from '../../../../../models/phone-call/phone-calls-list.model'
import { PhoneCallEditDialogComponent } from '../../../../../components/dialogs/phone-call-edit-dialog/phone-call-edit-dialog.component'
import { TodoModel } from '../../../../../models/todo/todo.model'
import { OfferService } from '../../../../../services/offer.service'
import { SendAppointmentsService } from '../../../../../services/send-appointments.service'
import { SendOfferService } from '../../../../../services/send-offer.service'
import { PatientDetailHistoryModel } from '../../../../../models/customer-patient/patient-detail-history.model'
import { SendLetterService } from '../../../../../services/send-letter.service'
import { ShowTodoDialogComponent } from '../../../../../components/dialogs/show-todo-dialog/show-todo-dialog.component'
import { UserMultiplierService } from '../../../../../services/user-multiplier.service'

@Component({
  selector: 'app-user-multiplier-patients-detail-history',
  templateUrl: './user-multiplier-patients-detail-history.component.html',
  providers: [DialogService],
})
export class UserMultiplierPatientsDetailHistoryComponent
  implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null

  public loading = true
  public patientId: string | null = null
  public selectedPatient: 'first_patient' | 'second_patient' = 'first_patient'

  public patients: PatientDetailHistoryModel = new PatientDetailHistoryModel()
  public histories: HistoryListModel[] = []
  public globalFilterValue = ''

  public filterTypes: any = []
  public selectedFilterTypes: any = []
  public typeIsHidden: any = {}

  public onlyImportant = false

  private eventBusSubscription: Subscription = new Subscription()

  constructor(
    private patientService: PatientService,
    private userMultiplierService: UserMultiplierService,
    private offerService: OfferService,
    private sendOfferService: SendOfferService,
    private sendAppointmentsService: SendAppointmentsService,
    private sendLetterService: SendLetterService,
    private route: ActivatedRoute,
    private eventbus: EventBusService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.loadHistory()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public openOfferMail(offerId: string): void {
    window.open(this.sendOfferService.getMailLink(offerId))
  }

  public openAppointmentsMail(appointmentId: string): void {
    window.open(this.sendAppointmentsService.getMailLink(appointmentId))
  }

  public openLetterMail(letterId: string): void {
    window.open(this.sendLetterService.getMailLink(letterId))
  }

  public changePatient(type: 'first_patient' | 'second_patient'): void {
    this.selectedPatient = type
  }

  public openDiffView(id: number): void {
    this.dialogService.open(DiffViewDialogComponent, {
      data: {
        id,
      },
      header: 'Änderungen ansehen',
      styleClass: 'dialog-diff-view',
      dismissableMask: true,
    })
  }

  public openShowTodoDialog(todo: TodoModel): void {
    this.dialogService.open(ShowTodoDialogComponent, {
      header: `Todo ansehen #${todo?.id}`,
      width: '820px',
      styleClass: 'dialog-container',
      data: {
        isEdit: true,
        todo,
        todo_id: todo.id,
        user_type_name: todo?.patient?.full_name,
      },
    })
  }

  public openManualHistoryDialog(
    edit: boolean,
    history?: HistoryListModel
  ): void {
    this.dialogService.open(HistoryManualDialogComponent, {
      data: {
        edit,
        history: history || new HistoryListModel(),
        type: 'patients',
        type_id: this.patients[this.selectedPatient]?.id,
        patients: this.patients,
      },
      header: history ? 'Eintrag bearbeiten' : 'Neuer Eintrag',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '520px',
    })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.PhoneCallListReload:
          case GlobalEvent.HistoryListReload:
          case GlobalEvent.TodoChanged:
            this.loadHistory(false)
        }
      }
    )
  }

  /**
   * Öffnet das Modal zum Bearbeiten eines Phonecalls.
   */
  public openPhonecallEditModal(phonecall: PhoneCallsListModel): void {
    this.dialogService.open(PhoneCallEditDialogComponent, {
      header: 'Telefonat bearbeiten',
      width: '540px',
      styleClass: 'dialog-container',
      dismissableMask: false,
      data: phonecall,
    })
  }

  public loadHistory(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    if (this.route.parent) {
      // this.route.parent.paramMap.subscribe((params: ParamMap) => {
      this.patientId = this.route.parent.snapshot.params.id

      this.userMultiplierService
        .history(this.patientId)
        .subscribe((data: PatientDetailHistoryModel) => {
          this.patients = data

          if (withLoading) {
            this.loading = false
          }
        })
    }
  }
}
