<div class="content-actions">
  <i class="tio icon-large">settings_outlined</i>

  <h2>
    <i class="tio">settings_outlined</i>
    Verwaltung
  </h2>
</div>

<header class="content-header-tabs">
  <a
    *ngIf="authService.can('Verwaltung.Systembenutzer verwalten')"
    routerLink="/administration/users"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Benutzer
  </a>

  <a
    *ngIf="authService.isSuperAdmin()"
    routerLink="/administration/roles"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Rollen
  </a>
</header>

<router-outlet></router-outlet>
