import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { Router } from '@angular/router'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { Subject, Subscription } from 'rxjs'
import { TodoService } from '../../../services/todo.service'
import { TodoModel } from '../../../models/todo/todo.model'
import { TodoFormDialogComponent } from '../../../components/dialogs/todo-form-dialog/todo-form-dialog.component'
import { ShowTodoDialogComponent } from '../../../components/dialogs/show-todo-dialog/show-todo-dialog.component'
import { switchMap } from 'rxjs/operators'
import { NotificationModel } from '../../../models/notification/notification.model'
import { NotificationService } from '../../../services/notification.service'

@Component({
  selector: 'app-todo-notifications',
  templateUrl: './todo-notifications.component.html',
})
export class TodoNotificationsComponent implements OnInit, OnDestroy {
  @ViewChild('dtOpen') table: ElementRef | any = null

  public globalFilterValue = ''
  private eventBusSubscription: Subscription = new Subscription()

  public loading = false
  public notifications: NotificationModel[] = []

  private loadTrigger$ = new Subject<void>()
  private subscription: Subscription = new Subscription()

  constructor(
    public notificationService: NotificationService,
    private dialogService: DialogService,
    private router: Router,
    private eventbus: EventBusService
  ) {}

  ngOnInit(): void {
    this.activateDataLoading()

    this.loadData()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.subscription.unsubscribe()
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.TodoChanged:
            this.loadData(false)
        }
      }
    )
  }

  public loadData(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.loadTrigger$.next()
  }

  private activateDataLoading(): void {
    this.subscription.add(
      this.loadTrigger$
        .pipe(switchMap(() => this.notificationService.loadAllRead()))
        .subscribe((data: NotificationModel[]) => {
          this.notifications = data

          this.loading = false
        })
    )
  }

  public openShowTodoDialog(todo: TodoModel | null = null): void {
    this.dialogService.open(ShowTodoDialogComponent, {
      header: `Todo ansehen #${todo?.id}`,
      width: '820px',
      styleClass: 'dialog-container',
      data: {
        todo,
        todo_id: todo?.id,
        user_type_name: todo?.caregiver?.full_name || todo?.patient?.full_name,
      },
    })
  }
}
