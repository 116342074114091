import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { ConfirmationService } from 'primeng/api'
import { HelperService } from '../../../services/helper.service'
import { AuthService } from '../../../services/auth.service'
import { DocumentService } from '../../../services/document.service'
import { CarCostModel } from '../../../models/car/car-cost.model'
import { CarCostService } from '../../../services/car-cost.service'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { TodoService } from '../../../services/todo.service'

@Component({
  selector: 'app-add-todo-reminder-dialog',
  templateUrl: './add-todo-reminder-dialog.component.html',
})
export class AddTodoReminderDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  public data: any = {}
  public values = {
    date: '',
    time: '',
  }

  public isEdit = false
  public submitted = false
  public submittedDelete = false

  constructor(
    private ref: DynamicDialogRef,
    private authService: AuthService,
    private dialogService: DialogService,
    private config: DynamicDialogConfig,
    private helperService: HelperService,
    private todoService: TodoService,
    private eventbus: EventBusService,
    private toastService: ToastService,
    public documentService: DocumentService,
    private confirmationService: ConfirmationService
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  ngOnInit(): void {
    this.data = this.config.data

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.loadReminder()
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public setDate(days: number): void {
    this.values.date = dayjs().add(days, 'days').format('DD.MM.YYYY')
    this.values.time = '08:00'
  }

  public checkForTimes(event: any): void {
    const value = event.target.value

    if (value.length === 1) {
      this.values.time = `0${value}:00`
    } else if (value.length === 2) {
      this.values.time = `${value}:00`
    } else if (value.length === 4) {
      this.values.time = `${value[0]}${value[1]}:${value[2]}${value[3]}`
    }
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.todoService.storeReminder(this.data.todo.id, this.values).subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.TodoChanged)
        this.ref.close(true)
        this.toastService.success(
          'Erinnerung gespeichert',
          'Die Erinnerung wurde erfolgreich gespeichert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public loadReminder(): void {
    this.todoService
      .loadReminder(this.data.todo.id)
      .subscribe((reminder: any) => {
        if (reminder) {
          this.values.date = reminder.date
          this.values.time = reminder.time
        }
      })
  }

  public remove(event: any): void {
    // this.confirmationService.confirm({
    //   target: event.target ?? undefined,
    //   message: 'Faktor wirklich löschen?',
    //   accept: () => {
    //     this.submittedDelete = true
    //
    //     this.carCostService.delete(this.data.cost_id).subscribe(
    //       () => {
    //         this.submittedDelete = false
    //         this.eventbus.emit(GlobalEvent.CarCostChanged)
    //         this.ref.close()
    //         this.toastService.success(
    //           'Faktor gelöscht',
    //           'Der Faktor wurde erfolgreich gelöscht'
    //         )
    //       },
    //       () => {
    //         this.submittedDelete = false
    //         this.toastService.error(
    //           'Löschen fehlgeschlagen',
    //           'Der Eintrag konnte nicht gelöscht werden'
    //         )
    //       }
    //     )
    //   },
    //   reject: () => {},
    // })
  }
}
