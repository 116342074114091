import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'

import { OfferService } from '../../services/offer.service'
import { OfferDependenciesModel } from '../../models/offer/offer-dependencies.model'
import { TitleService } from 'src/app/services/title.service'
import { SearchService } from '../../services/search.service'
import { BudgetService } from '../../services/budget.service'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'
import { ToastService } from '../../services/toast.service'
import { Subscription } from 'rxjs'
import { NgForm } from '@angular/forms'
import { DirtyComponent } from '../../guards/dirty-check.guard'
import { OfferFormModel } from '../../models/offer/offer-form.model'
import { PatientModel } from '../../models/customer-patient/patient.model'
import { MultiplierFormModel } from '../../models/multiplier/multiplier-form.model'
import { DialogService } from 'primeng/dynamicdialog'
import { AuthModel } from '../../models/user/auth.model'
import { AuthService } from '../../services/auth.service'
import { ZipcodeSearchResultModel } from '../../models/search/zipcode-search-result.model'
import { HelperService } from '../../services/helper.service'
import { CaregiverTimeErrorsModel } from '../../models/caregiver/caregiver-time-errors.model'
import { SelectItem } from 'primeng/api'
import { ThcDateDialogComponent } from '../../components/dialogs/thc-date-dialog/thc-date-dialog.component'
import { QmModel } from '../../models/customer-patient/qm.model'
import { SearchResultModel } from '../../models/search/search-result.model'
import { ConsultingDialogComponent } from '../../components/dialogs/consulting-dialog/consulting-dialog.component'

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
})
export class OfferComponent implements OnInit, DirtyComponent {
  @ViewChild('form', { static: true }) form!: NgForm

  public alreadyRecommended = false
  public isEditMode = false
  public submitted = false
  public loading = true
  public cityLoading = false
  public customerId: string | null = null
  public formOfAddress: any = []
  public attentionFrom = []
  public homeVisitFrom: any[] = []
  public times: SelectItem[] = []

  private formSubscription: Subscription | null | undefined = null
  public isDirty = false
  public user: AuthModel | null = new AuthModel()
  public pflegekassen: MultiplierFormModel[] = []
  public kostentraeger: MultiplierFormModel[] = []

  public budgetsOpen = {
    first: true,
    second: true,
  }

  public caregivers: SearchResultModel[] = []
  public multipliers: SearchResultModel[] = []

  public data: OfferFormModel = new OfferFormModel()
  public dependencies = new OfferDependenciesModel()
  public errors: any = new CaregiverTimeErrorsModel()

  public previewConsulting: any

  constructor(
    private router: Router,
    private searchService: SearchService,
    private route: ActivatedRoute,
    public authService: AuthService,
    private helperService: HelperService,
    private toastService: ToastService,
    private dialogService: DialogService,
    private budgetService: BudgetService,
    private service: OfferService,
    private titleService: TitleService
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  ngOnInit(): void {
    this.user = this.authService.getUser()

    this.loadDependencies()

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      // sonst wird isDirty durch das initiale rendern der form gesetzt
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.helperService.dependencies$.subscribe((data: any) => {
      for (const user of data.thc_users) {
        this.homeVisitFrom.push({
          full_name: `${user.user_system.first_name} ${user.user_system.last_name}`,
        })
      }

      this.formOfAddress = data.form_of_address
      this.attentionFrom = data.attention_from
      this.times = data.patient_times
    })
  }

  canDeactivate(): boolean {
    return this.isDirty
  }

  public searchCaregivers(event: any): void {
    this.searchService
      .findCaregivers(event.query.trim())
      .subscribe((results: SearchResultModel[]) => {
        this.caregivers = results
      })
  }

  public resetRecommendation(): void {
    this.data.recommendation_id = null
  }

  public searchMultipliers(event: any): void {
    this.searchService
      .findMultipliers(event.query.trim())
      .subscribe((results: SearchResultModel[]) => {
        this.multipliers = results
      })
  }

  public takeoverServicesFromFirstPerson(): void {
    this.data.second_patient.service_ids = this.data.first_patient.service_ids
    this.data.second_patient.services_text = this.data.first_patient.services_text
    this.data.second_patient.service_for = this.data.first_patient.service_for
    this.data.second_patient.driving_license_needed = this.data.first_patient.driving_license_needed
    this.data.second_patient.smoker_allowed = this.data.first_patient.smoker_allowed
    this.data.second_patient.should_be_vaccinated = this.data.first_patient.should_be_vaccinated
    this.data.second_patient.transfer_needed_general = this.data.first_patient.transfer_needed_general
    this.data.second_patient.transfer_needed_general_info = this.data.first_patient.transfer_needed_general_info
    this.data.second_patient.appointment_interval = this.data.first_patient.appointment_interval
    this.data.second_patient.flexible_times = this.data.first_patient.flexible_times

    const days = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ]

    for (const day of days) {
      this.data.second_patient.times.days_with_key[
        day
      ].checked = this.data.first_patient.times.days_with_key[day].checked

      this.data.second_patient.times.days_with_key[
        day
      ].start_first = this.data.first_patient.times.days_with_key[
        day
      ].start_first

      this.data.second_patient.times.days_with_key[
        day
      ].end_first = this.data.first_patient.times.days_with_key[day].end_first
    }
  }

  public toggleBudgetOpenFirst(): void {
    this.budgetsOpen.first = !this.budgetsOpen.first
  }

  public toggleBudgetOpenSecond(): void {
    this.budgetsOpen.second = !this.budgetsOpen.second
  }

  public selectPersonType(type: string): void {
    this.data.patient_type = type

    // Wenn beim bearbeiten B oder C angehakt wird, muss
    // der Default für den zweiten Patienten gefüllt werden.
    // Denn dieser ist NULL wenn er aus der Datenbank
    // kommt und A vorher ausgewählt war.
    if (type !== 'A' && !this.data.second_patient) {
      this.data.second_patient = new PatientModel()
    }
  }

  public selectOfferType(type: string): void {
    this.data.offer_type = type
  }

  /**
   * Prüft ob GKV oder PKV ausgewählt ist.
   */
  public isGkvOrPkvSelected(type: 'first_patient' | 'second_patient'): boolean {
    return (
      this.data[type].insured_via.includes('GKV') ||
      this.data[type].insured_via.includes('PKV')
    )
  }

  public openConsultingDialog(): void {
    const ref = this.dialogService.open(ConsultingDialogComponent, {
      header: 'Neuer Beratungseinsatz',
      width: '650px',
      styleClass: 'dialog-container',
      data: {
        isPreview: true,
        previewData: this.previewConsulting,
        isNew: true,
      },
    })

    ref.onClose.subscribe((values: any) => {
      if (values) {
        this.previewConsulting = values
      }
    })
  }

  public openThcDateDialog(): void {
    const ref = this.dialogService.open(ThcDateDialogComponent, {
      header: 'THC Eintrag',
      width: '650px',
      styleClass: 'dialog-container',
      data: {
        title: this.data.first_patient.full_name,
        id: this.data.qm.id,
        patient_id: this.data.first_patient.id,
        type: 'patient',
      },
    })

    ref.onClose.subscribe((qm: QmModel) => {
      if (qm) {
        this.data.qm.home_visit_date_string = qm.home_visit_date_string
        this.data.qm.home_visit_from = qm.home_visit_from
        this.data.qm.comment_thc = qm.comment_thc
      }
    })
  }

  /**
   * Prüfung ob nur "Selbstzahler" angehakt wurde.
   */
  public onlySelbstzahlerSelected(
    type: 'first_patient' | 'second_patient'
  ): boolean {
    const selected = this.data[type].insured_via

    if (!selected.length || selected.length > 1) {
      return false
    }

    return selected[0] === 'Selbstzahler'
  }

  public loadCity(type: 'first_patient' | 'contact_person'): void {
    this.cityLoading = true

    this.searchService.findCity(this.data[type].zipcode).subscribe(
      (result: ZipcodeSearchResultModel) => {
        if (result) {
          this.data[type].city = result.city
          this.data[type].county = result.county
          this.data[type].state = result.state
        }

        this.cityLoading = false
      },
      () => {
        this.data[type].city = ''
        this.cityLoading = false
      }
    )
  }

  // Wir setzen den Wert bei "Führerschein" auf "Ja", wenn gewisse Optionen ausgewählt werden.
  public serviceChanged(type: 'first_patient' | 'second_patient'): void {
    const services: number[] = [
      4, // Einkaufen
      6, // Arzttermine
      13, // Einkäufe
    ]

    for (const service of services) {
      if (this.data[type].service_ids.includes(service)) {
        this.data[type].driving_license_needed = 'Ja'
      }
    }
  }

  public searchPflegekassen(event: any): void {
    this.searchService
      .findCareInsurance(event.query.trim())
      .subscribe((results: MultiplierFormModel[]) => {
        this.pflegekassen = results
      })
  }

  public searchKostentraeger(event: any): void {
    this.searchService
      .findCostUnit(event.query.trim())
      .subscribe((results: MultiplierFormModel[]) => {
        this.kostentraeger = results
      })
  }

  // Falls eine Inkontinenz ausgewählt wurde, soll das Häkchen für "Es besteht keine Inkontinenz" entfernt werden.
  public incontinenceChoosed(type: 'first_patient' | 'second_patient'): void {
    const incontinence_urine = this.data[type].incontinence_urine
    const incontinence_stool = this.data[type].incontinence_stool

    if (
      (incontinence_urine && incontinence_urine !== 'Nicht inkontinent') ||
      (incontinence_stool && incontinence_stool !== 'Nicht inkontinent')
    ) {
      this.data[type].no_incontinence = false
    }
  }

  // Falls das Häkchen "Es besteht keine Inkontinenz" ausgewählt wird, sollen die anderen
  // Inkontinenzwerte mit "Nicht inkontinent" ausgewählt werden.
  public noIncontinenceChoosed(type: 'first_patient' | 'second_patient'): void {
    if (this.data[type].no_incontinence) {
      this.data[type].incontinence_urine = 'Nicht inkontinent'
      this.data[type].incontinence_stool = 'Nicht inkontinent'
    }
  }

  public calculateBudget(type: 'first_patient' | 'second_patient'): void {
    // Wenn "Beihilfe" angehakt ist, soll auch "Selbstzahler" angehakt werden.
    if (
      this.data[type].insured_via.includes('Beihilfe') &&
      !this.data[type].insured_via.includes('Selbstzahler')
    ) {
      this.data[type].insured_via.push('Selbstzahler')
    }

    // Wenn PKV ausgewählt wird, soll GKV abgewählt werden, und umgekehrt.
    if (this.data[type].insured_via.includes('PKV')) {
      this.data[type].insured_via = this.data[type].insured_via.filter(
        (via: any) => {
          return via !== 'GKV'
        }
      )
    } else if (this.data[type].insured_via.includes('GKV')) {
      this.data[type].insured_via = this.data[type].insured_via.filter(
        (via: any) => {
          return via !== 'PKV'
        }
      )
    }

    // Wenn Pflegegrad I ausgewählt wird, müssen bestimmte Auswahlen entfernt werden.
    if (
      this.data[type].degree_of_care === 'I' &&
      (!this.data[type].has_degree_of_care_old ||
        this.data[type].degree_of_care_old === 'I')
    ) {
      this.data[type].request_45a = ''
      this.data[type].request_42_kzpf = ''
      this.data[type].request_39_vpf = ''
      this.data[type].request_37 = ''
    }

    // Falls GKV oder PKV nicht mehr ausgewählt sind,
    // muss Pflegeperson Auswahl entfernen werden.
    if (
      !this.data[type].insured_via.includes('PKV') &&
      !this.data[type].insured_via.includes('GKV')
    ) {
      this.data[type].nursing_person = ''
      this.data[type].request_45a = ''
      this.data[type].request_42_kzpf = ''
      this.data[type].request_39_vpf = ''
      this.data[type].request_37 = ''
    }

    // Wenn §39 auf Nein gesetzt wird, soll auch die Auswahl für §42 entfernt werden.
    if (this.data[type].request_39_vpf !== 'Ja') {
      this.data[type].request_42_kzpf = ''
    }

    // Wenn Pflegeperson abgewählt wird, muss §39 ebenfalls entfernt werden.
    if (this.data[type].nursing_person !== 'Ja') {
      this.data[type].request_39_vpf = ''
    }

    this.data[
      type
    ].budget_calculations.budget_45b_old = this.budgetService.get45bOld(
      this.data[type].request_45b,
      this.data[type].degree_of_care_since_string,
      this.data[type].degree_of_care,
      this.dependencies
    )

    this.data[
      type
    ].budget_calculations.budget_45b_current = this.budgetService.get45bCurrent(
      this.data[type].request_45b,
      this.data[type].degree_of_care_since_string,
      this.data[type].degree_of_care,
      this.dependencies
    )

    this.data[type].budget_calculations.budget_45a = this.budgetService.get45a(
      this.data[type].degree_of_care,
      this.data[type].request_45a,
      this.data[type].request_37,
      this.dependencies,
      this.isGkvOrPkvSelected('first_patient')
    )

    this.data[type].budget_calculations.budget_37 = this.budgetService.get37(
      this.data[type].degree_of_care,
      this.data[type].nursing_person,
      this.data[type].request_37,
      this.data[type].request_45a,
      this.dependencies
    )

    this.data[type].budget_calculations.budget_39 = this.budgetService.get39(
      this.data[type].degree_of_care,
      this.data[type].degree_of_care_since_string,
      this.data[type].nursing_person,
      this.data[type].request_39_vpf,
      this.dependencies
    )

    this.data[type].budget_calculations.budget_42 = this.budgetService.get42(
      this.data[type].degree_of_care,
      this.data[type].degree_of_care_since_string,
      this.data[type].nursing_person,
      this.data[type].request_42_kzpf,
      this.data[type].request_39_vpf,
      this.dependencies
    )

    // Prüfung für §39, §42 und §45b ob alter Pflegegrad angehakt ist.
    // Der alte PG überschreibt im Prinzip den aktuellen.
    if (
      this.data[type].has_degree_of_care_old &&
      this.data[type].degree_of_care_old &&
      this.data[type].degree_of_care_old_since_string
    ) {
      this.data[
        type
      ].budget_calculations.budget_45b_old = this.budgetService.get45bOld(
        this.data[type].request_45b,
        this.data[type].degree_of_care_old_since_string,
        this.data[type].degree_of_care_old,
        this.dependencies
      )

      this.data[
        type
      ].budget_calculations.budget_45b_current = this.budgetService.get45bCurrent(
        this.data[type].request_45b,
        this.data[type].degree_of_care_old_since_string,
        this.data[type].degree_of_care_old,
        this.dependencies
      )

      // Für §39 und §42 muss geprüft werden ob dieser mindestens PG II ist.
      if (this.data[type].degree_of_care_old !== 'I') {
        this.data[
          type
        ].budget_calculations.budget_39 = this.budgetService.get39(
          this.data[type].degree_of_care_old,
          this.data[type].degree_of_care_old_since_string,
          this.data[type].nursing_person,
          this.data[type].request_39_vpf,
          this.dependencies
        )

        this.data[
          type
        ].budget_calculations.budget_42 = this.budgetService.get42(
          this.data[type].degree_of_care_old,
          this.data[type].degree_of_care_old_since_string,
          this.data[type].nursing_person,
          this.data[type].request_42_kzpf,
          this.data[type].request_39_vpf,
          this.dependencies
        )
      }
    }
  }

  /**
   * Lädt die Daten falls wir im bearbeiten Modus sind.
   */
  private loadDataForEditMode(): void {
    this.loading = true

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.customerId = params.get('id')
      this.isEditMode = params.has('id')

      this.titleService.setTitle(
        this.isEditMode ? 'Angebot bearbeiten' : 'Neues Angebot'
      )

      if (this.isEditMode) {
        this.loadCustomer()
      } else {
        // QM Eintrag vorausfüllen
        this.data.qm.initial_consultation_date_string = dayjs().format(
          'DD.MM.YYYY'
        )
        const userSystem = this.user?.user_system
        this.data.qm.initial_consultation_from = `${userSystem?.form_of_address} ${userSystem?.first_name} ${userSystem?.last_name}`

        this.loading = false
      }
    })
  }

  private loadCustomer(): void {
    this.service
      .loadForEditMode(this.customerId)
      .subscribe((data: OfferFormModel) => {
        this.data = data

        // Falls eine Empfehlung vorhanden ist, müssen
        // die Frontenddaten korrekt gesetzt werden.
        if (data.recommendation) {
          this.alreadyRecommended = true

          this.data.recommendation_id = data.recommendation

          if (data.recommendation_type?.includes('Multiplier')) {
            this.data.recommendation_type = 'MULTIPLIER'
          }

          if (data.recommendation_type?.includes('Caregiver')) {
            this.data.recommendation_type = 'CAREGIVER'
          }
        }

        this.adminClicked()

        this.loading = false
      })
  }

  /**
   * Gibt den Tooltip für den disabled Status zurück.
   *
   * TODO: diese methoden in eigene service klasse auslagern
   */
  public get39DisableTooltip(
    type: 'first_patient' | 'second_patient'
  ): {
    disabled: boolean
    tooltip: string
  } {
    if (this.data[type].nursing_person !== 'Ja') {
      return {
        disabled: true,
        tooltip: 'Pflegeperson muss vorhanden sein',
      }
    }

    // Check nach altem Pflegegrad.
    // Stand 29.11.2021: Der 6-Monate-Check soll rausgenommen werden.
    // if (
    //   this.data[type].degree_of_care_old !== 'I' &&
    //   this.data[type].has_degree_of_care_old
    // ) {
    //   // Den ersten Tag des Monats nehmen, an dem der Pflegegrad erteilt wurde.
    //   // Pflegegrad bezieht immer die Gültigkeit des 1. des Monats, egal wann erteilt wurde.
    //   const degreeOfCareOldSinceAsDate = dayjs
    //     .utc(this.data[type].degree_of_care_old_since_string, 'DD.MM.YYYY')
    //     .startOf('month')
    //   const degreeOfCareOldWith6MonthsTimespan = degreeOfCareOldSinceAsDate.add(
    //     this.dependencies['39_degree_of_care_since_months'],
    //     'month'
    //   )
    //
    //   // Prüfung ob der Pflegegrad länger als 6 Monate vorhanden ist.
    //   if (dayjs().isAfter(degreeOfCareOldWith6MonthsTimespan)) {
    //     return {
    //       tooltip: '',
    //       disabled: false,
    //     }
    //   }
    // }

    if (!this.data[type].degree_of_care) {
      return {
        disabled: true,
        tooltip: 'Pflegegrad muss ausgewählt sein',
      }
    }

    if (
      this.data[type].degree_of_care === 'I' &&
      (!this.data[type].has_degree_of_care_old ||
        this.data[type].degree_of_care_old === 'I')
    ) {
      return {
        disabled: true,
        tooltip: 'Pflegegrad darf nicht I sein',
      }
    }

    // Wenn kein Datum für Pflegegrad ausgewählt ist.
    if (!this.data[type].degree_of_care_since_string) {
      return {
        tooltip: 'Kein Datum für Pflegegrad ausgewählt',
        disabled: true,
      }
    }

    // Den ersten Tag des Monats nehmen, an dem der Pflegegrad erteilt wurde.
    // Pflegegrad bezieht immer die Gültigkeit des 1. des Monats, egal wann erteilt wurde.
    // Stand 29.11.2021: Der 6-Monate-Check soll rausgenommen werden.
    // const degreeOfCareSinceAsDate = dayjs
    //   .utc(this.data[type].degree_of_care_since_string, 'DD.MM.YYYY')
    //   .startOf('month')
    // const degreeOfCareWith6MonthsTimespan = degreeOfCareSinceAsDate.add(
    //   this.dependencies['39_degree_of_care_since_months'],
    //   'month'
    // )
    //
    // // Prüfung ob der Pflegegrad länger als 6 Monate vorhanden ist.
    // if (dayjs().isBefore(degreeOfCareWith6MonthsTimespan)) {
    //   return {
    //     tooltip: 'Pflegegrad nicht älter als 6 Monate',
    //     disabled: true,
    //   }
    // }

    return {
      disabled: false,
      tooltip: '',
    }
  }

  /**
   * Gibt den Tooltip für den disabled Status zurück.
   */
  public get45aDisableTooltip(
    type: 'first_patient' | 'second_patient'
  ): {
    disabled: boolean
    tooltip: string
  } {
    if (!this.data[type].degree_of_care) {
      return {
        disabled: true,
        tooltip: 'Pflegegrad muss ausgewählt sein',
      }
    }

    if (this.data[type].degree_of_care === 'I') {
      return {
        disabled: true,
        tooltip: 'Pflegegrad darf nicht I sein',
      }
    }

    if (!this.isGkvOrPkvSelected(type)) {
      return {
        disabled: true,
        tooltip: 'GKV oder PKV muss ausgewählt werden',
      }
    }

    return {
      disabled: false,
      tooltip: '',
    }
  }

  /**
   * Gibt den Tooltip für den disabled Status zurück.
   */
  public get37DisableTooltip(
    type: 'first_patient' | 'second_patient'
  ): {
    disabled: boolean
    tooltip: string
  } {
    if (!this.data[type].degree_of_care) {
      return {
        disabled: true,
        tooltip: 'Pflegegrad muss ausgewählt sein',
      }
    }

    if (this.data[type].degree_of_care === 'I') {
      return {
        disabled: true,
        tooltip: 'Pflegegrad darf nicht I sein',
      }
    }

    if (this.data[type].nursing_person !== 'Ja') {
      return {
        disabled: true,
        tooltip: 'Pflegeperson muss vorhanden sein',
      }
    }

    return {
      disabled: false,
      tooltip: '',
    }
  }

  /**
   * Gibt den Tooltip für den disabled Status zurück.
   */
  public get42DisableTooltip(
    type: 'first_patient' | 'second_patient'
  ): {
    disabled: boolean
    tooltip: string
  } {
    if (!this.data[type].degree_of_care) {
      return {
        disabled: true,
        tooltip: 'Pflegegrad muss ausgewählt sein',
      }
    }

    if (
      this.data[type].degree_of_care === 'I' &&
      !this.data[type].has_degree_of_care_old
    ) {
      return {
        disabled: true,
        tooltip: 'Pflegegrad darf nicht I sein',
      }
    }

    // if (
    //   this.data[type].degree_of_care_old === 'I' &&
    //   this.data[type].has_degree_of_care_old
    // ) {
    //   return {
    //     disabled: true,
    //     tooltip: 'Alter Pflegegrad darf nicht I sein',
    //   }
    // }

    if (this.data[type].request_39_vpf !== 'Ja') {
      return {
        disabled: true,
        tooltip: '§39 VPF muss eingeplant sein',
      }
    }

    return {
      disabled: false,
      tooltip: '',
    }
  }

  /**
   * Lädt alle Abhängigkeiten (Krankheiten, Leistungen, etc) aus der Datenbank.
   */
  public loadDependencies(): void {
    this.service
      .dependencies()
      .subscribe((dependencies: OfferDependenciesModel) => {
        this.dependencies = dependencies

        this.loadDataForEditMode()
      })
  }

  /**
   * Sobald der Admin diese Seite aufgerufen hat (Edit Modus), wird ein Wert
   * in der Tabelle auf true gesetzt. Dadurch ist es dem Multiplikator Benutzer
   * nicht mehr möglich, den Kunden zu bearbeiten.
   */
  public adminClicked(): void {
    this.service.adminClicked(this.data).subscribe()
  }

  /**
   * Prüft bei jeder Änderung im Uhrzeitfeld ob die Werte im richtigen
   * Format sind und ob die Uhrzeiten Sinn machen.
   *
   * So soll der "von" Wert kleiner als der zugehörige "bis" Wert sein.
   * Außerdem muss der "von" Wert vom zweichen Block (falls vorhanden)
   * größer sein, als der "bis" Wert vom ersten Block.
   */
  public checkForTimes(
    event: any,
    day:
      | 'monday'
      | 'tuesday'
      | 'wednesday'
      | 'thursday'
      | 'friday'
      | 'saturday',
    type: 'first_patient' | 'second_patient'
  ): void {
    // Wir setzen alle Fehler nochmal zurück,
    // damit diese einfacher neu berechnet werden können.
    this.resetErrors(type, day)

    const data = this.data[type].times.days_with_key[day]

    // Die Uhrzeiten werden als integer umgewandelt, dadurch
    // lässt es sich leicht nach größer/kleiner überprüfen.
    const integerStartFirst = data.start_first
      ? +data.start_first.replace(':', '')
      : 0
    const integerEndFirst = data.end_first
      ? +data.end_first.replace(':', '')
      : 0
    const integerStartSecond = data.start_second
      ? +data.start_second.replace(':', '')
      : 0
    const integerEndSecond = data.end_second
      ? +data.end_second.replace(':', '')
      : 0

    // Prüfen das die Uhrzeit für "von" kleiner ist als von "bis" für den ersten Block.
    // Nur wenn "bis" Datum eingetragen wurde und es noch keinen Fehler gibt.
    if (
      integerEndFirst &&
      integerStartFirst > integerEndFirst &&
      !this.errors[`${type}_${day}_start_first`]
    ) {
      this.errors[`${type}_${day}_start_first`] = true
    }

    // Prüfen das die Uhrzeit für "von" kleiner ist als von "bis" für den zweiten Block.
    // Nur wenn "bis" Datum eingetragen wurde und es noch keinen Fehler gibt.
    if (
      data.has_second_block &&
      integerEndSecond &&
      integerStartSecond > integerEndSecond &&
      !this.errors[`${type}_${day}_start_second`]
    ) {
      this.errors[`${type}_${day}_start_second`] = true
    }

    // Prüfen das die Uhrzeit für "von" im zweiten Block größer als
    // die "bis" Uhrzeit vom ersten Block ist und es noch keinen Fehler gibt.
    if (
      data.has_second_block &&
      integerEndFirst &&
      integerStartSecond &&
      integerEndFirst > integerStartSecond &&
      !this.errors[`${type}_${day}_start_second`]
    ) {
      this.errors[`${type}_${day}_start_second`] = true
    }
  }

  public timeChecked(
    event: any,
    day:
      | 'monday'
      | 'tuesday'
      | 'wednesday'
      | 'thursday'
      | 'friday'
      | 'saturday',
    type: 'first_patient' | 'second_patient'
  ): void {
    // Bei abwählen des Tages soll der Error Check gelöscht werden.
    if (!this.data[type].times.days_with_key[day].checked) {
      this.removeSecondBlock(day, type)
      this.data[type].times.days_with_key[day].start_first = ''
      this.data[type].times.days_with_key[day].end_first = ''

      this.resetErrors(type, day)
    }
  }

  public setSecondBlock(
    day:
      | 'monday'
      | 'tuesday'
      | 'wednesday'
      | 'thursday'
      | 'friday'
      | 'saturday',
    type: 'first_patient' | 'second_patient'
  ): void {
    this.data[type].times.days_with_key[day].has_second_block = true
  }

  public removeSecondBlock(
    day:
      | 'monday'
      | 'tuesday'
      | 'wednesday'
      | 'thursday'
      | 'friday'
      | 'saturday',
    type: 'first_patient' | 'second_patient'
  ): void {
    this.data[type].times.days_with_key[day].has_second_block = false
    this.data[type].times.days_with_key[day].start_second = ''
    this.data[type].times.days_with_key[day].end_second = ''

    this.checkForTimes(null, day, type)
  }

  private resetErrors(
    type: 'first_patient' | 'second_patient',
    day: string
  ): void {
    this.errors[`${type}_${day}_start_first`] = false
    this.errors[`${type}_${day}_end_first`] = false
    this.errors[`${type}_${day}_start_second`] = false
    this.errors[`${type}_${day}_end_second`] = false
  }

  // Wenn Flexible Zeiten angehakt wird, sollen die Tage
  // alle angehakt werden und mit Zeiten befüllt werden.
  // Falls bereits Zeiten eingetragen sind, sollen
  // diese nicht überschrieben werden.
  public setFlexibleTimes(
    event: any,
    type: 'first_patient' | 'second_patient'
  ): void {
    if (event.checked) {
      const days = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
      ]

      for (const day of days) {
        this.data[type].times.days_with_key[day].checked = true

        this.data[type].times.days_with_key[day].start_first =
          this.data[type].times.days_with_key[day].start_first || '08:00'

        this.data[type].times.days_with_key[day].end_first =
          this.data[type].times.days_with_key[day].end_first || '18:00'
      }
    }
  }

  /**
   * Erstellt bzw. updated die Daten.
   */
  public save(): void {
    if (Object.values(this.errors).includes(true)) {
      alert('Bitte prüfen Sie die Uhrzeiten!')
      return
    }

    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')

      // Durch das timeout scrollt er auch bei div.p-invalid hoch.
      setTimeout(() => {
        const firstElementWithError = document.querySelector(
          'textarea.ng-invalid, input.ng-invalid, select.ng-invalid, div.p-invalid, p-dropdown.ng-invalid'
        )
        if (firstElementWithError) {
          firstElementWithError.scrollIntoView({ behavior: 'smooth' })
        }
      })

      return
    }

    // Falls ein "/" beim Budget für §37 vorhanden ist, soll eine Meldung erscheinen.
    // Der Wert mit "/" kann ansonsten für die Budgetierung nicht geparst werden...
    if (
      this.data.first_patient.budget_calculations.budget_37.value.includes(
        '/'
      ) ||
      (this.data.second_patient &&
        this.data.second_patient.budget_calculations.budget_37.value.includes(
          '/'
        ))
    ) {
      // Nur bei normalem Angebot.
      if (this.data.offer_type === 'OFFER') {
        alert('Bitte nachfragen ob §45a eingeplant werden soll')

        return
      }
    }

    this.submitted = true

    const subscription = this.isEditMode
      ? this.service.update(this.data)
      : this.service.store(this.data, this.previewConsulting)

    subscription.subscribe(
      (firstPatient: PatientModel) => {
        this.isDirty = false
        this.router.navigate(['/patients', firstPatient.id])
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }

        this.submitted = false
      }
    )
  }
}
