<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <div style="display: flex; gap: 10px">
          <button
            pButton
            (click)="setDate(1)"
            label="Morgen"
            type="button"
            class="p-button p-button-outlined p-button-small"
          ></button>

          <button
            pButton
            (click)="setDate(3)"
            label="In 3 Tagen"
            type="button"
            class="p-button p-button-outlined p-button-small"
          ></button>

          <button
            pButton
            (click)="setDate(7)"
            label="In 7 Tagen"
            type="button"
            class="p-button p-button-outlined p-button-small"
          ></button>
        </div>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-7">
        <label for="date">Datum</label>

        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="date"
          name="date"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.date"
          dataType="string"
          [showIcon]="true"
          [showButtonBar]="true"
          [required]="true"
        ></p-calendar>
      </div>

      <div class="p-field p-col-5">
        <label for="time">Uhrzeit</label>
        <p-calendar
          (onBlur)="checkForTimes($event)"
          [stepMinute]="15"
          inputId="time"
          name="time"
          [timeOnly]="true"
          appendTo="body"
          [(ngModel)]="values.time"
          dataType="string"
          icon="pi pi-clock"
          [showIcon]="true"
          [required]="true"
          [showButtonBar]="false"
        ></p-calendar>
      </div>
    </div>
  </div>

  <div class="dialog-form-footer">
    <i *ngIf="!data.isEdit"></i>
    <i
      class="pi pi-trash"
      *ngIf="data.isEdit"
      [class.pi-trash]="!submittedDelete"
      [class.pi-spin]="submittedDelete"
      [class.pi-spinner]="submittedDelete"
      tooltipPosition="left"
      tooltipZIndex="20000"
      [pTooltip]="submittedDelete ? 'Wird gelöscht' : 'Faktor löschen'"
      (click)="remove($event)"
    ></i>

    <button
      (click)="save()"
      pButton
      [label]="data.isEdit ? 'Speichern' : 'Erstellen'"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-bell'"
    ></button>
  </div>
</form>
