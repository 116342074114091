<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="detail-information-banner" *ngIf="hasOpenEmployment">
      <span>Bitte ein Enddatum in der vorherigen Anstellung eintragen!</span>
    </div>

    <!--    <div class="p-field">-->
    <!--      <div class="p-formgroup-inline">-->
    <!--        <div class="p-field-checkbox">-->
    <!--          <p-checkbox-->
    <!--            label="Befristet"-->
    <!--            name="employed_temporary"-->
    <!--            [binary]="true"-->
    <!--            [(ngModel)]="values.employed_temporary"-->
    <!--            (onChange)="employedTemporaryChanged($event)"-->
    <!--          ></p-checkbox>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-6">
        <label for="employed_since_date">seit *</label>
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="employed_since_date"
          name="employed_since_date"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.employed_since_date"
          dataType="string"
          [showIcon]="true"
          [showButtonBar]="true"
          [required]="true"
          #employed_since_date="ngModel"
          [class.p-invalid]="
            employed_since_date.invalid &&
            (employed_since_date.dirty || employed_since_date.touched)
          "
        ></p-calendar>
      </div>

      <div class="p-field p-col-6">
        <label for="employed_until_date"
          >bis {{ values.employed_temporary ? "*" : "" }}</label
        >
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="employed_until_date"
          name="employed_until_date"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [showIcon]="true"
          [showButtonBar]="true"
          [(ngModel)]="values.employed_until_date"
          (onSelect)="untilDateChanged($event)"
          (onBlur)="untilDateChanged($event)"
          [required]="values.employed_temporary"
          dataType="string"
          #employed_until_date="ngModel"
          [class.p-invalid]="
            employed_until_date.invalid &&
            (employed_until_date.dirty || employed_until_date.touched)
          "
        ></p-calendar>
      </div>
    </div>

    <div class="p-field">
      <label for="comment">Bemerkung</label>
      <textarea
        id="comment"
        name="comment"
        [(ngModel)]="values.comment"
        [rows]="3"
        pInputTextarea
      ></textarea>
    </div>

    <div class="p-field" *ngIf="!data.isEdit">
      <div class="p-field-checkbox">
        <p-checkbox
          label="Mit Anlage zum Arbeitsvertrag"
          name="document_appendix"
          [binary]="true"
          [(ngModel)]="values.document_appendix"
        ></p-checkbox>

        <div
          style="display: flex; margin-left: auto"
          *ngIf="values.document_appendix"
        >
          <p-calendar
            [firstDayOfWeek]="1"
            [showWeek]="true"
            [showIcon]="true"
            inputId="date_contract"
            name="date_contract"
            placeholder="Datum eintragen"
            dateFormat="dd.mm.yy"
            appendTo="body"
            [(ngModel)]="values.date_contract"
            dataType="string"
            [showButtonBar]="true"
            [required]="true"
            #date_contract="ngModel"
            [class.p-invalid]="
              date_contract.invalid &&
              (date_contract.dirty || date_contract.touched)
            "
          ></p-calendar>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer" *ngIf="!hasOpenEmployment">
    <i *ngIf="!data.isEdit"></i>
    <i *ngIf="data.isEdit && !authService.isSuperAdmin()"></i>
    <i
      class="pi pi-trash"
      *ngIf="data.isEdit && authService.isSuperAdmin()"
      [class.pi-trash]="!submittedDelete"
      [class.pi-spin]="submittedDelete"
      [class.pi-spinner]="submittedDelete"
      tooltipPosition="left"
      tooltipZIndex="20000"
      [pTooltip]="submittedDelete ? 'Wird gelöscht' : 'Anstellung löschen'"
      (click)="remove($event)"
    ></i>

    <button
      (click)="store()"
      pButton
      [label]="data.isEdit ? 'Speichern' : 'Hinzufügen'"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
