<div class="content-actions">
  <i class="tio icon-large">calendar_month</i>

  <h2>
    <i class="tio">calendar_month</i>
    Kalender
  </h2>
</div>

<header class="content-header-tabs">
  <a
    routerLink="./thc"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    THC
  </a>
  <a
    routerLink="./consulting"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Beratungseinsätze
  </a>
</header>

<router-outlet></router-outlet>
