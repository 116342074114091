<app-loader [loading]="loading"></app-loader>

<div class="content-actions limit-width" *ngIf="!loading">
  <i class="tio icon-large">settings_outlined</i>

  <h2>
    <i class="tio">settings_outlined</i>
    Einstellungen
  </h2>
</div>

<div
  class="content limit-width content-light-version"
  *ngIf="!loading && data.is_caregiver"
>
  <div class="content-body">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-md-4 p-col-12">
        <label>
          Kennung <i class="pi pi-info-circle" pTooltip="Für den Login"></i
        ></label>
        <strong>{{ data.name }}</strong>
      </div>
      <div class="p-field p-md-4 p-col-12 p-mt-md-0 p-mt-5">
        <label>E-Mail </label>
        <strong>{{ data.email }}</strong>
      </div>
    </div>
  </div>
</div>

<div
  class="content limit-width content-light-version"
  *ngIf="!loading && data.is_user_system"
>
  <div class="content-body">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-md-4 p-col-12">
        <label> Benutzername</label>
        <strong>{{ data.name }}</strong>
      </div>
      <div class="p-field p-md-4 p-col-12 p-mt-md-0 p-mt-5">
        <label
          >E-Mail <i class="pi pi-info-circle" pTooltip="Für den Login"></i
        ></label>
        <strong>{{ data.email }}</strong>
      </div>
      <div class="p-field p-md-4 p-col-12 p-mt-md-0 p-mt-5">
        <label>User ID / System User ID</label>
        <strong
          >{{ data.user_system.user_id }} / {{ data.user_system.id }}</strong
        >
      </div>
    </div>
  </div>
</div>

<div
  class="content limit-width content-light-version"
  *ngIf="!loading && data.is_multiplier"
>
  <div class="content-body">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-md-4 p-col-12">
        <label> Name</label>
        <strong>{{ data.name }}</strong>
      </div>
      <div class="p-field p-md-4 p-col-12 p-mt-md-0 p-mt-5">
        <label
          >E-Mail <i class="pi pi-info-circle" pTooltip="Für den Login"></i
        ></label>
        <strong>{{ data.email }}</strong>
      </div>
    </div>
  </div>
</div>

<form #form="ngForm">
  <div class="content limit-width" *ngIf="!loading && data.is_user_system">
    <header class="content-header">
      <span>Daten</span>
    </header>

    <div class="content-body">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-md-4 p-col-12">
          <label for="form_of_address">Anrede *</label>
          <p-dropdown
            [(ngModel)]="data.user_system.form_of_address"
            [options]="formOfAddress"
            inputId="form_of_address"
            #form_of_address="ngModel"
            name="form-of-address"
            placeholder="Bitte auswählen"
            [required]="true"
          ></p-dropdown>
          <div
            *ngIf="
              form_of_address.invalid &&
              (form_of_address.dirty || form_of_address.touched)
            "
            class="p-invalid"
          >
            <div *ngIf="form_of_address.errors?.required">
              <small>Bitte wählen Sie eine Anrede</small>
            </div>
          </div>
        </div>

        <div class="p-field p-md-4 p-col-12 p-mt-md-0 p-mt-3">
          <label for="first-name">Vorname *</label>
          <input
            id="first-name"
            name="first-name"
            [(ngModel)]="data.user_system.first_name"
            type="text"
            pInputText
            #firstname="ngModel"
            required
            [class.p-invalid]="
              firstname.invalid && (firstname.dirty || firstname.touched)
            "
          />
          <div
            *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)"
            class="p-invalid"
          >
            <div *ngIf="firstname.errors?.required">
              <small>Bitte geben Sie einen Vornamen ein</small>
            </div>
          </div>
        </div>

        <div class="p-field p-md-4 p-col-12 p-mt-md-0 p-mt-3">
          <label for="last-name">Nachname *</label>
          <input
            id="last-name"
            name="last-name"
            [(ngModel)]="data.user_system.last_name"
            type="text"
            pInputText
            #lastname="ngModel"
            required
            [class.p-invalid]="
              lastname.invalid && (lastname.dirty || lastname.touched)
            "
          />
          <div
            *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)"
            class="p-invalid"
          >
            <div *ngIf="lastname.errors?.required">
              <small>Bitte geben Sie einen Nachnamen ein</small>
            </div>
          </div>
        </div>
      </div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-md-4 p-col-12">
          <label for="bday">Geburtsdatum</label>
          <p-calendar
            [firstDayOfWeek]="1"
            inputId="bday"
            [showButtonBar]="true"
            name="bday"
            [(ngModel)]="data.user_system.birthday_date_string"
            dateFormat="dd.mm.yy"
            [showIcon]="true"
            dataType="string"
          ></p-calendar>
        </div>

        <div class="p-field p-md-4 p-col-12 p-mt-md-0 p-mt-5">
          <label for="phone-1">Telefon 1</label>
          <input
            id="phone-1"
            name="phone-1"
            [(ngModel)]="data.user_system.phone_1"
            type="text"
            pInputText
          />
        </div>
        <div class="p-field p-md-4 p-col-12 p-mt-md-0 p-mt-5">
          <label for="phone-2">Telefon 2</label>
          <input
            id="phone-2"
            name="phone-2"
            [(ngModel)]="data.user_system.phone_2"
            type="text"
            pInputText
          />
        </div>
      </div>
    </div>
  </div>

  <div
    class="content-footer limit-width"
    *ngIf="!loading && data.is_user_system"
  >
    <span></span>

    <button
      (click)="save()"
      pButton
      label="Daten speichern"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>

<form #formPassword="ngForm">
  <div class="content limit-width" *ngIf="!loading">
    <header class="content-header">
      <span>Passwort</span>
    </header>

    <div class="content-body">
      <div class="p-fluid p-formgrid p-grid" *ngIf="errorMessage">
        <div class="p-field p-col-12">
          <small class="color-alert auth-message">{{ errorMessage }}</small>
        </div>
      </div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-md-4 p-col-12">
          <label for="old_password">Aktuelles Passwort *</label>
          <p-password
            name="old_password"
            [(ngModel)]="data.old_password"
            [toggleMask]="true"
            [feedback]="false"
            [required]="true"
          ></p-password>
        </div>

        <div class="p-field p-md-4 p-col-12 p-mt-md-0 p-mt-2">
          <label for="password">Neues Passwort *</label>
          <p-password
            name="password"
            [(ngModel)]="data.password"
            [toggleMask]="true"
            [feedback]="false"
            [required]="true"
          ></p-password>
        </div>

        <div class="p-field p-md-4 p-col-12 p-mt-md-0 p-mt-2">
          <label for="password_confirmation">Neues Passwort bestätigen *</label>
          <p-password
            name="password_confirmation"
            [(ngModel)]="data.password_confirmation"
            [toggleMask]="true"
            [feedback]="false"
            [required]="true"
          ></p-password>
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
          <small class="color-gray"
            >Das Passwort muss mindestens <b>8 Zeichen</b> lang sein. <br />
            Es muss <b>Groß- und Kleinbuchstaben</b> sowie
            <b>Sonderzeichen</b> enthalten.</small
          >
        </div>
      </div>
    </div>
  </div>

  <div class="content-footer limit-width" *ngIf="!loading">
    <span></span>

    <button
      (click)="savePassword()"
      pButton
      label="Passwort ändern"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
