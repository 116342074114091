<app-loader [loading]="loading"></app-loader>

<div
  class="content new-content-container"
  style="margin-bottom: 0"
  *ngIf="!loading"
>
  <header class="content-header" *ngIf="data.length > 0">
    <span>Offene Rückläufer</span>

    <div
      class="table-search-container"
      [class.searchfield-active]="globalFilterValue != ''"
      *ngIf="data.length > 0"
    >
      <div class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pInputText
          (input)="filterTableGlobal($event)"
          placeholder="Suche..."
          [(ngModel)]="globalFilterValue"
        />
      </div>
    </div>
  </header>

  <div class="content-body" *ngIf="data.length == 0">
    <span class="content-no-message"
      >Keine erstellten Rückläufer vorhanden</span
    >
  </div>

  <div
    class="content-body content-body-full-height-with-subtabs without-padding"
    *ngIf="data.length > 0"
  >
    <p-table
      #dt
      [value]="data"
      [globalFilterFields]="[
        'patient_id',
        'patient.first_name',
        'patient.last_name',
        'patient.full_name',
        'case_number',
        'invoice_number'
      ]"
      scrollHeight="100%"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Rückläufer"
      [scrollable]="true"
      [rowHover]="true"
      [paginator]="true"
      [rows]="50"
      [(first)]="firstPage"
      stateStorage="local"
      stateKey="accounting-returns-created"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th
            pTooltip="Storno"
            style="max-width: 60px"
            pSortableColumn="patient.status"
          >
            <i class="pi pi-exclamation-triangle"></i>
            <p-sortIcon field="patient.status"></p-sortIcon>
          </th>
          <th style="min-width: 200px" pSortableColumn="patient_id">
            Patient <p-sortIcon field="patient_id"></p-sortIcon>
          </th>
          <th
            style="max-width: 150px"
            pSortableColumn="patient.care_insurance.name"
          >
            Pflegekasse
            <p-sortIcon field="patient.care_insurance.name"></p-sortIcon>
          </th>
          <th pSortableColumn="case_number">
            Fallnummer <p-sortIcon field="case_number"></p-sortIcon>
          </th>
          <th style="max-width: 90px" pSortableColumn="correction_reason">
            Grund <p-sortIcon field="correction_reason"></p-sortIcon>
          </th>
          <th pSortableColumn="performance_date_sortable">
            Leistungsmonat
            <p-sortIcon field="performance_date_sortable"></p-sortIcon>
          </th>
          <th pSortableColumn="invoice_number">
            Re-Nummer <p-sortIcon field="invoice_number"></p-sortIcon>
          </th>
          <th pSortableColumn="invoice_date">
            Re-Datum <p-sortIcon field="invoice_date"></p-sortIcon>
          </th>
          <th pSortableColumn="total_value_as_float">
            Gesamtwert <p-sortIcon field="total_value_as_float"></p-sortIcon>
          </th>
          <th style="max-width: 100px" pSortableColumn="patient.first_sepa.id">
            SEPA <p-sortIcon field="patient.first_sepa.id"></p-sortIcon>
          </th>
          <th style="width: 180px" pSortableColumn="created_at">
            Erstellt am <p-sortIcon field="created_at"></p-sortIcon>
          </th>
          <th style="max-width: 100px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr class="clickable">
          <td style="max-width: 60px">
            <i
              *ngIf="item.patient.status === 'STORNO'"
              class="pi pi-exclamation-triangle color-alert"
              pTooltip="Storno"
            ></i>
          </td>
          <td style="min-width: 200px">
            <a routerLink="/patients/{{ item.patient_id }}"
              >{{ item.patient.first_name }} {{ item.patient.last_name }}</a
            >
          </td>
          <td
            style="max-width: 150px"
            (click)="openAddAccountingReturnDialog(item)"
            [pTooltip]="item.patient.care_insurance.name"
          >
            <div class="text-nowrap">
              {{ item.patient.care_insurance.name }}
            </div>
          </td>
          <td (click)="openAddAccountingReturnDialog(item)">
            {{ item.case_number }}
          </td>
          <td
            style="max-width: 90px"
            tooltipStyleClass="tooltip-wider"
            [escape]="false"
            [pTooltip]="correctionReasons[item.correction_reason]"
            (click)="openAddAccountingReturnDialog(item)"
          >
            {{ item.correction_reason }}
          </td>
          <td (click)="openAddAccountingReturnDialog(item)">
            {{ item.performance_month }} {{ item.performance_year }}
          </td>
          <td (click)="openAddAccountingReturnDialog(item)">
            {{ item.invoice_number }}
          </td>
          <td (click)="openAddAccountingReturnDialog(item)">
            {{ item.invoice_date_string }}
          </td>
          <td (click)="openAddAccountingReturnDialog(item)">
            {{ item.total_value_formatted }}
          </td>
          <td style="max-width: 100px">
            <a
              *ngIf="item.patient.first_sepa"
              target="_blank"
              [href]="
                patientService.getDocumentDownloadLink(
                  item.patient.first_sepa.uuid
                )
              "
              >SEPA
              <i
                class="pi pi-arrow-up-right color-gray"
                style="font-size: 12px"
              ></i
            ></a>
          </td>
          <td (click)="openAddAccountingReturnDialog(item)">
            {{ item.created_at | date: "dd.MM.y, H:mm" }}
          </td>
          <td class="table-action" style="max-width: 100px">
            <i
              (click)="openAddAccountingReturnDialog(item)"
              class="pi pi-pencil with-margin-right"
              tooltipPosition="left"
              pTooltip="Bearbeiten"
            ></i>
            <a
              *ngIf="item.media[0]"
              [href]="
                documentService.getDocumentDownloadLink(item.media[0].uuid)
              "
              target="_blank"
            >
              <i
                tooltipPosition="left"
                pTooltip="Herunterladen"
                class="pi pi-download"
              ></i>
            </a>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
